<template>
  <CustomModal
    :title="props.title"
    show-modal
    :max-width="980"
    @close-modal="closeModal"
  > 
    <template #title>
      <CustomButton
        :label="t('Back')"
        icon-name="chevron-left"
        icon-color="white"
        small
        @click="handleBackButton"
      />
    </template>
    
    <div class="alert-builder-wizard flow">
      <router-view
        :date-params="dateParams"
        :date-filter="dateFilter"
        :from="props.from"
        :is-edit-mode="isEditMode"
        :is-first-sales-section="isFirstSalesSection"
        :is-first-crm-section="isFirstCrmSection"
        :is-first-product-section="isFirstProductSection"      
        :action-text="actionText"
        @valid="valid = $event"
        @set-date="setDateParams"
        @update-alert-name="alertName = $event"
        @update-promo-material-link="promoMaterialLink = $event"
      />
    </div>

    <template #footer>
      <ButtonGroup
        v-if="!isEditMode && vroute.name !== NEW_ALERT_ROUTE"
        class="btn-group-bg"
      >
        <CustomButton
          v-if="vroute.path.includes('add-data')"
          purpose="action"
          @click="finishAlert"
        >
          {{ t('No thanks, I\'m done') }}
        </CustomButton>

        <CustomButton
          v-else-if="allDataEntered && !vroute.path.includes('name-alert')"
          :disabled="!valid"
          purpose="action"
          @click="goToNameAlert"
        >
          {{ t('I\'m done, save alert') }}
        </CustomButton>

        <CustomButton
          v-else-if="vroute.path.includes('name-alert')"
          :disabled="!valid"
          purpose="action"
          @click="saveAlert"
        >
          {{ t('Done') }}
        </CustomButton>

        <CustomButton
          v-else
          :disabled="!valid"
          purpose="action"
          @click="goToAddData"
        >
          {{ vroute.path.includes('product-data') ? t('Add these products') : t('Continue') }}
        </CustomButton>
      </ButtonGroup>

      <ButtonGroup
        v-if="isEditMode"
      >
        <CustomButton
          v-if="vroute.path.includes('add-data')"
          purpose="reversed"
          @click="goToNameAlert"
        >
          {{ t('Save as new alert') }}
        </CustomButton>
        <CustomButton
          v-if="vroute.path.includes('add-data')"
          purpose="action"
          @click="finishAlert"
        >
          {{ t('Save') }}
        </CustomButton>

        <CustomButton
          v-else-if="vroute.path.includes('name-alert')"
          :disabled="!valid"
          purpose="action"
          @click="saveAlert"
        >
          {{ t('Done') }}
        </CustomButton>
        <CustomButton
          v-else
          purpose="action"
          :disabled="!valid"
          @click="goToAddData"
        >
          {{ t('Save edits') }}
        </CustomButton>
      </ButtonGroup>
    </template>
  </CustomModal>
</template>

<script setup>
import { ref, computed, onMounted, } from 'vue';
import { useRoute, } from 'vue-router';
import { useStore } from 'vuex';
import { CustomModal, CustomButton } from '@sales-i/dsv3';
import ButtonGroup from '@/shared/components/ButtonGroup.vue';
import { DATE_FILTER_OPTIONS_V2 } from '@/intelligence/store/data/dateFilters';
import { alertBuilderScope, alertsScope } from '@/intelligence/router/urlBits';
import { dates, t } from '@sales-i/utils';
import {
  SET_ITEMS, 
  POST_BY_ENTITY,
  PATCH_BY_ENTITY,
} from '@/shared/store/actionType';
import { NEW_ALERT_ROUTE } from '@/intelligence/router/routeNames';
import { QUERY_BUILDER } from '@/intelligence/store/data/reportTypes';
import { navigateToUrl } from 'single-spa';
import { useAlertBuilder } from '@/intelligence/composables/useAlertBuilder';
import { CLEAR_ENQUIRY_FILTERS, RESET_ALL_DATA_CLAUSES } from '@/intelligence/store/actionType';
import { CLEAR_PRODUCTS } from '@/admin/store/actionType';

let showConfirmation = ref(true);
const store = useStore();
const vroute = useRoute();
const { 
  isEditMode, alertBuilderBaseUrl, alertBuilderModalBaseUrl, 
  crmFilters, salesFilters, allProducts,
  clearAllFilters, getSavedAlertById, addQueryArgsToStore, getAllSavedAlerts
} = useAlertBuilder({ store, vroute });

const props = defineProps({
  title: {
    type: String,
    default:''
  },
  reportType: {
    type: String,
    default:''
  },
});
const emit = defineEmits(['closeModal', 'savedAlertSuccess']);

onMounted(async () => {
  await clearAll();
  if (isEditMode.value) {
    await getSavedAlertById(vroute.params.queryId);
    // Clear the existing filters and wait for these to complete before before adding the new query args to the store
    addQueryArgsToStore();
  }
});

const isFirstSalesSection = computed(() => salesFilters.value.length > 0);
const isFirstCrmSection = computed(() => selectedCrmFilters.value.length > 0 && !isFirstSalesSection.value);
const isFirstProductSection = computed(() => allProducts.value.length > 0 && !isFirstCrmSection.value);

// Get the filters
const selectedCrmFilters = computed(() => store.getters['intelligence/queryBuilder/getCrmFilters']);

const dateParams = ref({
  'date_from': 'today-3m',
  'date_to': 'today',
  'date_from2': 'today-3m-3m',
  'date_to2': 'today-3m',
});

// Match DATE_FILTER_OPTIONS_V2 text to the chosen date in dateParams
const dateFilter = computed(() => {
  const { date_from, date_to } = dateParams.value;
  let filterOption = DATE_FILTER_OPTIONS_V2.find((option) => option.value === date_from);
  if (filterOption) return filterOption?.text || '';
  return `${dates.format(date_from)} - ${dates.format(date_to)}`;
});

const actionText = computed(() => isEditMode.value ? t('Edit') : t('Add'));

const allDataEntered = computed(() => {
  // If selectedCrmFilters, salesFilters, and allProducts all have some data empty, then
  return selectedCrmFilters.value.length > 0 && salesFilters.value.length > 0 && allProducts.value.length > 0;
});

const valid = ref(false);
const alertName = ref('');
const promoMaterialLink = ref('');
const savedQueryErrors = computed(() => store.state.intelligence.savedQuery.errors);
const isUsingVarianceOutput = computed(() => store.state.intelligence.queryBuilder.varianceOutput);

const getRequestParameters = (params) => store.getters['intelligence/shared/getRequestParameters'](params);
const getResponseParameters = (params) => store.getters['intelligence/shared/getResponseParameters'](params);
const postSavedQueries = (params) => store.dispatch(`intelligence/savedQuery/${POST_BY_ENTITY}`, params);

function finishAlert() {
  if (isEditMode.value) {
    updateAlert();
  } else {
    goToNameAlert();
  }
}

const clearEnquiryFilters = () => store.dispatch(`intelligence/enquiry/${CLEAR_ENQUIRY_FILTERS}`);
const clearProducts = () => store.dispatch(`intelligence/queryBuilder/${CLEAR_PRODUCTS}`);
const resetAllDataClauses = () => store.dispatch(`intelligence/enquiry/${RESET_ALL_DATA_CLAUSES}`);

async function clearAll() {
  clearAllFilters();
  clearEnquiryFilters();
  clearProducts();
  resetAllDataClauses();
}
const patchByEntity = (params) => store.dispatch(`intelligence/savedQuery/${PATCH_BY_ENTITY}`, params );

const savedQuery = computed(() => store.state.intelligence.savedQuery.data);

const filters = computed(() => {
  return {
    salesFilters: salesFilters.value,
    crmFilters: crmFilters.value,
    productFilters: allProducts.value,
    isUsingVarianceOutput: isUsingVarianceOutput.value,
  };
});

// Get the data clauses from the store in readines to pass along with the saveAlert
const getSalesDataClause = computed(() => store.state.intelligence.enquiry.salesDataClause);
const getCrmDataClause = computed(() => store.state.intelligence.enquiry.crmDataClause);
const getProductDataClause = computed(() => store.state.intelligence.enquiry.productDataClause);
const fetchedData = computed(() => store.state.intelligence.shared.fetchedData);
const reportId = computed(() => Object.keys(fetchedData.value)[Object.keys(fetchedData.value).length - 1]);

const dataClauses = computed(() => {
  return {
    salesDataClause: getSalesDataClause.value,
    crmDataClause: getCrmDataClause.value,
    productDataClause: getProductDataClause.value,
  };
});

const queryParams = computed(() => getRequestParameters([QUERY_BUILDER]));
const getUniqueCustomers = computed(() => {
  const uniqueCustomers = [];

  const reportData = fetchedData.value[reportId.value]?.data;

  if (reportData) {
    const { rows } = reportData;
    let n = 0;
    if (rows !== undefined) {
      for (n; n < rows.length; n++) {
        const entityId = rows[n].dimensions[0].entity_id;
        if (uniqueCustomers.indexOf(entityId) === -1) {
          uniqueCustomers.push(entityId);
        }
      }
    }
  }
  return uniqueCustomers;
});

function showSuccessAlert() {
  store.dispatch(`alerts/${SET_ITEMS}`, {
    type: 'success',
    message: t('Successfully saved alert'),
    selfDismiss: true,
  });
}

async function saveAlert() {
  if (alertName.value === '') {
    return;
  }

  const uniqueCustomers = getUniqueCustomers.value;

  await postSavedQueries({
    entity_type: 'community',
    entity_id: 1,
    query_name: `${alertName.value}|~~|${promoMaterialLink.value}`,
    results: [
      {
        query_arguments: `querybuilder (${queryParams.value})||||${JSON.stringify(filters.value)}~~~~${JSON.stringify(dataClauses.value)}`,
        query_fields: getResponseParameters(QUERY_BUILDER.replace('-', '')),
        results: JSON.stringify(uniqueCustomers),
      },
    ],
  });

  if (savedQueryErrors.value.length === 0) {
    alertName.value = '';
    emit('savedAlertSuccess');
    showSuccessAlert();
  }

  // Navigate to main page to show the latest alert
  // TODO: receive the alert id from the response and navigate to the alert page
  await getAllSavedAlerts();
  navigateToUrl(`${alertBuilderBaseUrl.value}/${alertsScope}`);
}

async function updateAlert() {
  const uniqueCustomers = getUniqueCustomers.value;
  await patchByEntity([
    savedQuery.value.id,
    {
      id: savedQuery.value.results[0].id,
      query_arguments: `querybuilder (${queryParams.value})||||${JSON.stringify(filters.value)}~~~~${JSON.stringify(dataClauses.value)}`,
      results: JSON.stringify(uniqueCustomers),
    }
  ]);

  if (savedQueryErrors.value.length === 0) {
    alertName.value = '';
    emit('savedAlertSuccess');
    showSuccessAlert();
  }

  // Set showConfirmation to false
  showConfirmation.value = false;
  closeModal();
}

const setDateParams = ($event) => {
  dateParams.value = {
    ...dateParams.value,
    ...$event,
  };
};

function handleBackButton() {
  return history.back();
}

function goToAddData() {
  // Determine the source page and add the corresponding query parameter
  let sourcePage = '';
  if (vroute.path.includes('sales-data')) {
    sourcePage = t('sales');
  } else if (vroute.path.includes('crm-data')) {
    sourcePage = t('CRM');
  } else if (vroute.path.includes('product-data')) {
    sourcePage = t('Product');
  }
  
  // Go to the /sales-data route and include an optional parameter for queryId. If there's no id then don't render that portion of the URL
  let path = `${alertBuilderModalBaseUrl.value}/add-data`;
  let query = new URLSearchParams({ sourcePage });
  navigateToUrl(`${path}?${query.toString()}`);
}

function goToNameAlert() {
  goToAddData();
  navigateToUrl(`${alertBuilderBaseUrl.value}/${alertsScope}/${alertBuilderScope}/name-alert`);
}

function closeModal() {
  emit('closeModal', showConfirmation.value);
}
</script>

<style lang="scss">
// Styles that are intentionally un-scoped so the children can inherit the styles (wrapperd in a unique class for safety)
.alert-builder-wizard {

  .intro-wrapper {
    display: grid;
    justify-items: center;
    margin-inline: auto;
    margin-bottom: var(--spacing-5);
    max-width: 52rem;
    padding-inline: var(--spacing-6);
    text-align: center;

    p {
      margin-block-end: 0;
    }
  }

  .form-field-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: var(--spacing-4);

    .form-group {
      margin-bottom: 0;
    }

    .button {
      padding: 0;
    }
  }
  
  .form-field-flex {
    display: flex;
    gap: var(--spacing-3);
    align-items: center;
  }

  .delete-icon {
    display: flex;
    justify-content: flex-end;
    margin-bottom: var(--spacing-6);
  }

  .add-button {
    background-color: var(--colour-utility-white);
    display: grid;
    grid-template-areas: "main";
    margin-bottom: var(--spacing-10);

    .menu-dropdown {
      margin: var(--spacing-2) 0 var(--spacing-2) 0;
      grid-area: main;
      place-self: end;

      .list {
        background-color: var(--colour-utility-black);

        .list-item__title {
          color: var(--colour-utility-white);
        }
      }
    }

    .icon-button {
      font-size: var(--font-size-4);
      font-weight: var(--font-weight-medium);
      
      span {
        margin-left: var(--spacing-2);
      }
    }

    &::before {
      content: '';
      width: 100%;
      border: 1px solid var(--colour-panel-g-8);
      grid-area: main;
      place-self: center;
    }
  }

  .list .list-item:hover {
    background-color: var(--colour-panel-g-72);
    min-width: var(--spacing-12);
  }
}
</style>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .alert-builder-wizard {
    padding: var(--spacing-2);
  }
}

:deep(.top) {
  margin-top: calc(var(--spacing-3) * -1);
}

:deep(.header .button.small) {
  padding: var(--spacing-1) var(--spacing-2) var(--spacing-1) var(--spacing-1);
  margin: 0 0 var(--spacing-2) 0;
}

.btn-group-bg {
  padding-top: 0;
  padding-bottom: 0;
}
</style>
