<template>
  <CustomModal
    show-modal
    full-bleed
    :title="title"
    @close-modal="close"
    @modal-opened="onModalOpened"
  >
    <div class="target-form">
      <div class="header">
        <Stage
          :items="stages"
          :selected-index="stageIndex"
          @stage="changeStage"
        />
      </div>
      <KeepAlive>
        <component
          :is="activeComponent"
          :is-modal-opened="isModalOpened"
          :target-data="target"
          :is-edit="isEdit"
          @complete-stage="completeStage"
        />
      </KeepAlive>
    </div>
  </CustomModal>
</template>

<script setup>
import { computed, onMounted, ref, onUnmounted } from 'vue';
import { t } from '@sales-i/utils';
import { CustomModal } from '@sales-i/dsv3';
import Stage from '@/shared/components/Stage/Stage.vue';
import AddEditTargetFields from '@/intelligence/components/Targets/AddEditTarget/AddEditTargetFields.vue';
import AddEditTargetDimensions from '@/intelligence/components/Targets/AddEditTarget/AddEditTargetDimensions.vue';
import AddEditTargetValues from '@/intelligence/components/Targets/AddEditTarget/AddEditTargetValues.vue';
import AddEditTargetAnalysis from '@/intelligence/components/Targets/AddEditTarget/AddEditTargetAnalysis.vue';
import { TARGET_INTERVALS } from '@/intelligence/store/data/dateFilters';
import useTargets from '@/intelligence/composables/useTargets';

const emit = defineEmits(['close', 'reload']);

const props = defineProps({
  targetId: {
    type: String,
    default: '',
  },
  year: {
    type: String,
    default: '',
  },
  isEdit: {
    type: Boolean,
    default: false,
  },
});

const { queryTargetData, getTarget, clearCurrentTarget } = useTargets();

// data
const stageIndex = ref(0);
const target = ref({
  name: '',
  description: '',
  interval: '',
  dimensions: [],
  measurementId: '',
  values: [],
  targetId: '',
  year: props.year,
});
const stages = computed(() => [t('Compulsory fields'), t('Select Dimensions'), t('Select Analysis Fields'), t('Target values')]);
const isModalOpened = ref(false);

// store
const intervalTitle = computed(() => {
  const intervalObject = TARGET_INTERVALS.find(interval => interval.value === target.value.interval);
  return intervalObject ? intervalObject.text : '';
});
const title = computed(() => {
  if (props.targetId) return t('Edit Target');

  return stageIndex.value > 0
    ? t(`New ${intervalTitle.value} Target`, 'new_interval_target', {
      interpolations: {
        interval: intervalTitle.value,
      },
    })
    : t('New Target');
});
const activeComponent = computed(() => [AddEditTargetFields, AddEditTargetDimensions, AddEditTargetAnalysis, AddEditTargetValues][stageIndex.value]);

onMounted(async () => {
  if (props.targetId) {
    const targetData = await getTarget(props.targetId);
    const { name, description, dimensions, id, measurement, periodValues, interval } = targetData;
    target.value.name = name;
    target.value.targetId = id;
    target.value.description = description;
    target.value.dimensions = dimensions;
    target.value.measurementId = measurement.id;
    target.value.values = periodValues.map(value => value.value);
    target.value.interval = interval;
  }
});

onUnmounted(() => {
  clearCurrentTarget();
});

function onModalOpened() {
  isModalOpened.value = true;
}

function changeStage(newIndex) {
  stageIndex.value = newIndex;
}

function completeStage(payload) {
  const { targetData, newStage } = payload;
  target.value = { ...target.value, ...targetData };
  if (newStage > 3) {
    addTarget();
    clearCurrentTarget();
    return;
  }
  stageIndex.value = newStage;
}

function close() {
  emit('close');
}

async function addTarget() {
  await queryTargetData({
    type: target.value.targetId ? 'update' : 'add',
    params: target.value
  });
  close();
  emit('reload');
}
</script>

<style lang="scss">
@import '@/shared/assets/scss/_variables';

.target-form {
  display: flex;
  flex-direction: column;
  height: 100%;

  .header {
    padding: clamp(var(--spacing-2), 2.5vw, var(--spacing-4));
    padding-bottom: 0;
    padding-top: 0;
  }
  .stages {
    max-width: 36rem;
    margin-top: var(--spacing-3);
  }
}

.target-component {
  overflow: auto;
  height: 100%;
  max-height: calc(100vh - 162px);
  padding: 0 var(--spacing-10);
}
</style>
