<template>
  <div
    v-if="aiPermissions.read"
    class="opportunities-list"
  >
    <div class="filter-container">
      <div class="primary-filter">
        <MenuFilter
          :items="filterOptions"
          :selected-value="filter"
          menu-position="right"
          class="mb-half"
          @on-change="handleFilterChanged"
        />
        <component
          :is="searchComponent[filter]"
          :id="`${filter}search`"
          class="filtersearch"
          label=""
          :value="searchLabel || search"
          :search-on-load="false"
          @search="handleSearchTextInput"
        />
      </div>
    </div>
    <EntityList
      :filters="aiOpportunitiesSortOptions"
      :selected-filter="sort"
      show-pagination
      :page-limit="limit"
      :page-offset="offset"
      :records-count="itemsCount"
      :loading="aiOpportunitiesLoading && !aiOpportunitiesLoaded"
      @filter-selected="handleSortChanged"
      @page-changed="handlePageChanged"
    >
      <div>
        <CustomSelect
          :label="t('Viewing opportunities at')"
          :value="type_filter"
          :items="type_filters"
          @on-change="changeOpportunityLevel"
        />
      </div>
      <div
        role="list"
        class="list list-card"
      >
        <AiOpportunityCard
          v-for="(item, index) in aiOpportunityItems"
          :id="`opportunity-card-${index + 1}`"
          :key="index"
          :item="item"
          @dismiss="addFeedback"
          @open-item="openItem"
        />
      </div>
    </EntityList>

    <MissedPotentialOpportunityModal
      v-if="selectedOpportunity"
      :selected-opportunity="selectedOpportunity"
      :dismissed-items="dismissedItems"
      :modal-open="modalOpen"
      @close-modal="closeModal"
      @add-feedback="showDismissModal"
      @pursueas-interaction="pursueItem"
    />

    <DismissModal
      :dismiss-modal-open="dismissModalOpen"
      :close-dismiss-modal="closeDismissModal"
      :dismiss-options="dismissOptions"
      object-type="opportunity"
      @post-feedback="handlePostFeedback"
    />
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { t, isEqualObjects } from '@sales-i/utils';
import MissedPotentialOpportunityModal from '@/crm/components/Opportunity/MissedPotentialOpportunityModal.vue';
import AiOpportunityCard from '@/crm/components/Opportunity/AiOpportunityCard.vue';
import { MenuFilter, CustomSelect } from '@sales-i/dsv3';
import EntityList from '@/crm/components/EntityList/EntityList.vue';
import CustomerSearch from '@/shared/components/Search/CustomerSearch.vue';
import ProspectSearch from '@/shared/components/Search/ProspectSearch.vue';
import DismissModal from '@/admin/components/AiFeedback/DismissModal.vue';
import { baseUrl, interactionsArea } from '@/crm/router/urlBits';
import { usePagination } from '@/shared/composables/usePagination';
import { useOpportunity } from '@/crm/composables/useOpportunity';
import { useInteraction } from '@/crm/composables/useInteraction';
import { navigateToUrl } from 'single-spa';
import { useCustomer } from '@/crm/composables/useCustomer';
import useNotification from '@/shared/composables/useNotification';

const ITEM_LEVEL = 'item_level';
const GROUP_LEVEL = 'group_level';
const DEFAULT_LEVEL = GROUP_LEVEL;

const props = defineProps({
  opportunityType: {
    type: String,
    default: 'missed',
  },
  filter: {
    type: String,
    default: 'customer',
  },
  search: {
    type: String,
    default: '',
  },
  searchLabel: {
    type: String,
    default: '',
  },
  sort: {
    type: String,
    default: 'decision_date:desc',
  },
});
const emit = defineEmits(['sort', 'filterChanged', 'add', 'pageChanged', 'pursueItem']);

const store = useStore();
const { limit, offset, handlePageChanged } = usePagination(props, loadData, emit);
const { selectedCustomer, getCustomerById } = useCustomer({ store }); 
const { 
  aiPermissions,
  aiOpportunityItems, aiOpportunitiesLoading, 
  aiOpportunitiesLoaded, aiOpportunitiesSortOptions,
  deleteOpportunity, deleteOpportunityWithProducts,
  getOpportunitiesWithProducts, clearOpportunitiesWithProducts
} = useOpportunity({ store, emit });
const { setEntity, setFurtherDetails, clearCurrentlyEditing, setOpportunity } = useInteraction({ store, emit });
const { sendSuccessAlert, sendErrorAlert } = useNotification({ store });

const queryParams = computed(() => ({
  sort: props.sort,
  limit: limit.value || 50,
  offset: offset.value || 0,
  type: type_filter.value === GROUP_LEVEL ? `${opportunityType.value}grp` : opportunityType.value,
  ...(['customer', 'prospect'].includes(props.filter) && props.search ? { [props.filter]: props.search } : {}),
}));

const type_filter = ref(DEFAULT_LEVEL);
const filterOptions = ref([
  {
    text: t('By customer'),
    value: 'customer',
  },
  {
    text: t('By prospect'),
    value: 'prospect',
  },
]);
const searchComponent = {
  customer: CustomerSearch,
  prospect: ProspectSearch,
};
const modalOpen = ref(false);
const dismissModalOpen = ref(false);
const selectedOpportunity = ref({});
const dismissedItems = ref({});
const dismissOptions = ref({
  dismiss_for: '',
  dismiss_reason: '',
});

const type_filters = [
  {
    text: t('Item Level'),
    value: ITEM_LEVEL,
  },
  {
    text: t('Group Level'),
    value: GROUP_LEVEL,
  },
];

const itemsCount = computed(() => aiOpportunityItems.value.length);
// sometimes it sends incorrect value of prop.opportunityType first from crm tab
const opportunityType = computed(() =>
  ['missed', 'potential'].includes(props.opportunityType) ? props.opportunityType : 'missed'
);

watch(
  () => ({ ...queryParams.value }),
  (newV, oldV) => {
    if (!oldV || isEqualObjects(oldV, newV)) return;
    if (newV?.offset == oldV?.offset) {
      handlePageChanged(0); //reset page if filter has changed
    } else {
      loadData();
    }
  },
  { deep: true }
);

onMounted(() => loadData());

function changeOpportunityLevel(level) {
  type_filter.value = level;
  loadData();
}

async function loadData() {
  await clearOpportunitiesWithProducts();

  if (queryParams.value.limit === undefined) {
    queryParams.value.limit = 50;
  }
  if (queryParams.value.offset === undefined) {
    queryParams.value.offset = 0;
  }

  getOpportunitiesWithProducts(queryParams.value);
}

function handleSortChanged({ value }) {
  emit('sort', value);
}

function handleFilterChanged({ value }) {
  emit('filterChanged', {
    filter: value,
    search: '',
    searchLabel: '',
  });
}

function handleSearchTextInput(value) {
  emit('filterChanged', {
    filter: props.filter,
    search: value || '',
    searchLabel: value || '',
  });
}

function closeDismissModal() {
  selectedOpportunity.value = {};
  dismissModalOpen.value = false;
}

function getUnabridgedProducts(products, type) {
  return products.filter(x => x.prod_category === type) || [];
}

function willBeEmpty(opportunity, products) {
  let consequents;

  switch (opportunity.type) {
  case 'MISSED':
  case 'MISSEDGRP':
    consequents = opportunity.products.filter(p => {
      return p.prod_category.toLowerCase() === 'consequent';
    });
    return consequents.length === products.length;
  default:
    return products.length === opportunity.products.length;
  }
}

function addFeedback(feedbackData) {
  const { products, opportunity } = feedbackData;
  const productCodes = Object.keys(products);
  if (productCodes.length > 0) {
    // Build a list of dismiss options for each product
    const feedbackProducts = productCodes
      .map(prod => {
        if (products[prod]) {
          return {
            object: 'opportunity_product',
            object_id: prod,
            opportunity_id: opportunity.id.toString(),
          };
        }
      })
      .filter(o => o !== undefined);

    if (willBeEmpty(opportunity, feedbackProducts) === false && feedbackProducts.length) {
      dismissOptions.value = feedbackProducts;
    } else {
      dismissOptions.value = {
        object: 'opportunity',
        object_id: opportunity.id.toString(),
      };
    }
  } else {
    dismissOptions.value = {
      object: 'opportunity',
      object_id: opportunity.id.toString(),
    };
  }

  dismissModalOpen.value = true;
}

async function handlePostFeedback() {
  try {
    if (dismissOptions.value.constructor === Array) {
      // Delete the products
      const payload = {
        id: dismissOptions.value[0].opportunity_id,
        products: dismissOptions.value.map(prod => ({
          product_code: prod.object_id,
        })),
      };
      await deleteOpportunityWithProducts(payload);
    } else {
      await deleteOpportunity(dismissOptions.value.object_id);
    }
    sendSuccessAlert(t('Successfully deleted'));
    // Close all modals, and re-load the oppo data
    closeDismissModal();
    loadData();
  } catch (e) {
    sendErrorAlert(t('An error occurred deleting opportunity'));
  }
}

function openItem(item) {
  selectedOpportunity.value = item;
  modalOpen.value = true;
}

function closeModal() {
  selectedOpportunity.value = {};
  modalOpen.value = false;
}

function showDismissModal(dismissedItems) {
  addFeedback({
    opportunity: selectedOpportunity.value,
    options: dismissOptions.value,
    products: dismissedItems,
  });
}

async function pursueItem(item) {
  emit('pursueItem', item);
  // Make sure we are working with a clean slate
  clearCurrentlyEditing();
  const opportunity = { ...aiOpportunityItems.value.find(o => o.id === item.id) };
  await getCustomerById({ id: opportunity.entity_id });

  setEntity({
    entityType: opportunity.entity_type,
    entity: {
      ...selectedCustomer.value.data,
      header: selectedCustomer.value.data.name,
      subHeader: selectedCustomer.value.data.account_number,
    },
  });

  // Generate the interaction note
  let interactionNote = '';
  if (opportunity.type.toLowerCase() !== 'potential') {
    interactionNote += 'Because this client purchased: ';
    const antecedents = getUnabridgedProducts(opportunity.products, 'Antecedent');
    const consequents = getUnabridgedProducts(opportunity.products, 'Consequent');

    antecedents.forEach(ante => {
      interactionNote += `${ante.product_name} (${ante.prod_code}) `;
    });

    interactionNote += '\r\n'; // eslint-disable-line
    interactionNote += 'We recommend they also purchase: ';
    consequents.forEach(cons => {
      interactionNote += `${cons.product_name} (${cons.prod_code}) `;
    });
  } else {
    const potentials = getUnabridgedProducts(opportunity.products, 'POTENTIAL');
    interactionNote += 'We recommend they purchase: ';
    potentials.forEach(cons => {
      interactionNote += `${cons.product_name} (${cons.prod_code}) `;
    });
  }

  setFurtherDetails({ interactionNote });
  setOpportunity({ opportunity });

  // Push them to the interactions
  navigateToUrl(`${baseUrl}/${interactionsArea}/add?clean=false`);
}
</script>

<style lang="scss" scoped>
.menu-filter {
  min-width: 124px;
}
.opportunities-list {
  padding-top: var(--spacing-3);
}

.filter-container {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  max-width: 600px;
}
.filtersearch {
  margin-bottom: 0;

  :deep(.input) {
    margin-bottom: 0;
  }
}
.primary-filter {
  display: flex;
  flex-flow: column;
  margin-right: var(--spacing-2);
}
.secondary-filter {
  flex: 1 1 auto;
}

.modal-content {
  padding: var(--spacing-2);

  table {
    width: 100%;
    margin: var(--spacing-2) 0;

    th,
    td {
      padding: var(--spacing-2);
    }
  }

  .association-selection {
    margin-bottom: 0;
  }
}
</style>
