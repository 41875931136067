<template>
  <div class="enquiry-filters-wrapper">
    <IconButton
      class="filters-button"
      :class="{ active: isModalVisible }"
      icon-name="filter"
      :icon-color="modalButtonColor"
      purpose="transparent"
      icon-only
      :icon-size="26"
      @click.stop="toggleModal"
    />
    <div
      v-if="filtersApplied.length"
      class="number-indicator"
    >
      {{ filtersApplied.length }}
    </div>
    <CustomModal
      id="filters-modal"
      class="filters-modal"
      :show-modal="isModalVisible"
      position="top"
      @close-modal="toggleModal"
    >
      <FilterList
        v-if="activeView === 'list'"
        :filters="filtersApplied"
        :input-data="inputData"
        @close-modal="toggleModal"
        @add-filter="addFilter"
        @edit-filter="editFilter"
        @delete-filter="deleteFilterHandler"
      />
      <CreateFilterView
        v-if="activeView === 'create' && isModalVisible"
        :filter-number="currentFilterNumber"
        :filters="filtersApplied"
        :input-data="inputData"
        @apply-filters="applyFilters"
      />
    </CustomModal>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch, onUnmounted } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { IconButton, CustomModal } from '@sales-i/dsv3';
import CreateFilterView from '@/intelligence/components/EnquiryReport/EnquiryFilter/CreateFilterView';
import FilterList from '@/intelligence/components/EnquiryReport/EnquiryFilter/FilterList';
import { REQUEST_ENTITY_INTERACTION_SUMMARY, REQUEST_ENTITY_INTERACTION_SUMMARY_DRILL } from '@/intelligence/store/data/apiInput';
import { isCustomerInsightsReportView, isInteractionOutcomesReport, isReportView } from '@/shared/utils/urlCheckers';
import { INTERACTIONS_FILTER_OPTIONS, INTERACTION_SPEND_SUMMARY_FILTERS } from '@/intelligence/store/data/filters';
import { INTERACTION_OUTCOMES, INTERACTION_SPEND_SUMMARY } from '@/intelligence/store/data/reportTypes';
import { useUsers } from '@/shared/composables/useUsers';
import addFilterToUrlPath from '@/intelligence/utils/addFilterToUrlPath';
import useSystem from '@/shared/composables/useSystem';
import useEnquiries from '@/intelligence/composables/useEnquiries';
import usePerformanceInsight from '@/intelligence/composables/usePerformanceInsight';
import useFields from '@/shared/composables/useFields';
import useShared from '@/intelligence/composables/useShared';
import useLazyLoading from '@/intelligence/composables/useLazyLoading';

const store = useStore();
const vroute = useRoute();
const { iframe } = useSystem({ store });
const props = defineProps({
  reportType: {
    type: String,
    default: '',
    required: true,
  },
});

const emit = defineEmits(['toggleFilterModal', 'applyFilters']);

const { usersOptions } = useUsers(store);
const { 
  bubbleData, filtersApplied: enquiryFilters, 
  applyFiltersToQuery, deleteFilter, fetchBubbleData, setFilters, applyOffsetX, clearEnquiryFilters,
} = useEnquiries({ store });
const { filters: interactionsFilters, applyFilter, removeFilter, setAnalysisField } = usePerformanceInsight({ store });
const { fields } = useFields({ store });
const { getInputData, fetchReportData, refreshReport, applyOffset } = useShared({ store });
const { resetReportParameters } = useLazyLoading({ store });

const isModalVisible = ref(false);
const activeView = ref('create');
const currentFilterNumber = ref(0);

const isComputedReportView = computed(() => iframe.value || isReportView());
const inputData = computed(() => getInputData(props.reportType));
const modalButtonColor = computed(() => isModalVisible.value ? 'var(--colour-utility-white)' : 'var(--colour-utility-action)');
const isUsingBubbles = computed(() => {
  const reportsNotUsingBubbles = [REQUEST_ENTITY_INTERACTION_SUMMARY, REQUEST_ENTITY_INTERACTION_SUMMARY_DRILL];
  return !reportsNotUsingBubbles.includes(inputData.value.requestEntity);
});

const getFilterType = filterKey => {
  const arrayOfFilters = [...INTERACTIONS_FILTER_OPTIONS, ...INTERACTION_SPEND_SUMMARY_FILTERS];
  return arrayOfFilters.find(filter => filter.value === filterKey).text;
};

const customSchemaInteractions = computed(() => [
  ...fields.value.nextAction, ...fields.value.outcome, ...fields.value.type
]);

const filtersApplied = computed(() => {
  if (isInteractionOutcomesReport() || inputData.value.urlCode === INTERACTION_SPEND_SUMMARY) {
    const formattedInteractionsFilters = [];

    Object.keys(interactionsFilters.value).forEach(filterKey => {
      let newFilter = {
        title: getFilterType(filterKey),
        selectedFilterType: getFilterType(filterKey),
        selected_values: []
      };
      const interactionsFiltersByKey = Object.values(interactionsFilters.value[filterKey]);
      if (!interactionsFiltersByKey.length) return;
      interactionsFiltersByKey.forEach(filter => {
        const filterData = customSchemaInteractions.value.find(schema => filter === schema.id);
        const filterName = getInteractionFilterName(filterKey, filterData, filter);

        newFilter.filterKey = filterKey;
        newFilter.selected_values.push({
          id: filter, value: filterName,
        });
      });
      formattedInteractionsFilters.push(newFilter);
    });
    return isInteractionOutcomesReport() ? formattedInteractionsFilters : [...formattedInteractionsFilters, ...enquiryFilters.value];
  } else {
    return enquiryFilters.value;
  }
});


watch(() => filtersApplied.value, (to) => {
  if (!to.length) addFilter();
});

watch(() => vroute.name, () => {
  clearEnquiryFilters();
});

onMounted(async() => {
  if (!bubbleData.value && !isComputedReportView.value && isUsingBubbles.value) await fetchBubbleData();

  const { customer } = vroute.query;
  if (customer) {
    const filterType = 'Customer',
      filterTitle = 'Name';
    setFilters([
      {
        title: 'Filter 1',
        selectedFilterType: filterType,
        selectedFieldTitle: filterTitle,
        description: `${filterType} > ${filterTitle}`,
        id: bubbleData.value.find(bubble => bubble.area === filterType && bubble.title === filterTitle).id,
        selected_values: [
          {
            value: customer,
            id: customer,
          },
        ],
        filter_type: 'include',
      },
      0,
    ]);
  }
});

onUnmounted(() => {
  clearEnquiryFilters();
});

const setActiveView = newView => {
  if (['create', 'list'].indexOf(newView) === -1) return;
  activeView.value = newView;
};

const toggleModal = () => {
  if (((activeView.value === 'create' && isModalVisible.value) || !isModalVisible.value) && filtersApplied.value.length) {
    setActiveView('list');
    isModalVisible.value = true;
  } else {
    isModalVisible.value = !isModalVisible.value;
  }
  emit('toggleFilterModal', isModalVisible.value);
};

const applyFilters = async (filters) => {
  applyOffset(0);
  applyOffsetX(0);
  if (!filters) {
    applyFiltersToQuery();
    if (isComputedReportView.value || isCustomerInsightsReportView()) {
      resetReportParameters();
      refreshReport();
    }
    if (inputData.value.urlCode === INTERACTION_SPEND_SUMMARY) {
      setAnalysisField(enquiryFilters.value);
    }
  } else {
    applyFilter(filters);
    refreshReport();
    if (isInteractionOutcomesReport()) {
      await fetchReportData({ reportType: props.reportType });
    }
    emit('applyFilters');
  }

  if (filtersApplied.value.length) setActiveView('list');
};

const addFilter = () => {
  currentFilterNumber.value = filtersApplied.value.length;
  setActiveView('create');
};

const editFilter = (filter) => {
  currentFilterNumber.value = filtersApplied.value.findIndex(el => el.title === filter.title);
  setActiveView('create');
};

const deleteFilterHandler = (filter) => {
  const isInteractionFilter = [INTERACTION_OUTCOMES, INTERACTION_SPEND_SUMMARY].includes(props.reportType);
  let filterPath = addFilterToUrlPath(filter, isInteractionFilter);
  const newPath = vroute.fullPath.replace(`&${filterPath}`, '');
  applyOffset(0);
  applyOffsetX(0);
  resetReportParameters();
  isInteractionOutcomesReport() || inputData.value.urlCode === INTERACTION_SPEND_SUMMARY
    ? handleInteractionDeleteFilter(filter)
    : handleDeleteFilter(filter);
  history.replaceState({}, '', newPath);
};

const handleInteractionDeleteFilter = async(filter) => {
  removeFilter(filter);
  
  if (inputData.value.urlCode === INTERACTION_SPEND_SUMMARY) {
    emit('applyFilters');
    return;
  }
  await fetchReportData({ reportType: props.reportType });
  emit('applyFilters');
};

const handleDeleteFilter = (filter) => {
  deleteFilter(filter);
  applyFilters();
};

const getInteractionFilterName = (filterKey, filterData, filter) => {
  switch (filterKey) {
  case 'user':
    return usersOptions.value.find(user => user.value === filter).text;
  case 'interaction_type':
  case 'interaction_outcome':
  case 'next_action':
    return filterData.name;
  default:
    return '';
  }
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

:deep(#filters-modal) {
  top: var(--spacing-1);
  height: initial;
  max-height: calc(100% - var(--spacing-2));

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    width: 360px;
    min-width: initial;
    max-height: none;
    position: absolute;
    top: 0;
    margin-left: var(--spacing-4);
  }

  .content-wrapper {
    padding-bottom: 0;
    position: relative;

    .header {
      position: absolute;
      right: 0;
      z-index: 10;
    }
  }
}
.filters-button {
  box-shadow: var(--shadow-x) var(--shadow-y) var(--shadow-blur) var(--shadow-spread) var(--shadow-colour);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--colour-utility-white);

  &.active {
    background: var(--colour-utility-black);
  }
}

.number-indicator {
  position: absolute;
  top: -2px;
  right: -2px;
  width: 16px;
  height: 16px;
  font-size: var(--font-size-small);
  background: var(--colour-utility-error);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--colour-utility-white);
}

.filters-modal {
  position: relative;
}

.enquiry-filters-wrapper {
  position: relative;
}
</style>
