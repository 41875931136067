<template>
  <div class="container flow">
    <div class="intro-wrapper flow">
      <h2>
        {{
          t(
            `${actionText} product data`,
            'action_product_data',
            {
              interpolations: {
                action: actionText
              }
            }
          )
        }}
      </h2>
      <p>{{ dateFilter }}</p>
    </div>
  </div>

  <FilterSummary />

  <div class="container flow">
    <div class="flex">
      <TabSwitch
        v-if="!isFirstProductSection"
        class="tab-switch"
        :items="clauseOptions"
        :selected-value="productDataClause"
        @switch-selected="handleSwitch($event)"
      />
      <h3>{{ t('Products these customers are buying / not buying') }}</h3>
    </div>

    <template
      v-for="(filter, index) in selectedValues"
      :key="`${filter.id}_${index}`"
    >
      <div class="flex">
        <TabSwitch
          v-if="index !== 0"
          :key="`${filter.id}_${index}`"
          class="tab-switch"
          :items="clauseOptions"
          :selected-value="filter.operator.toUpperCase()"
          @switch-selected="handleProductSwitch($event, filter)"
        />
      </div>
      <div class="form-field-flex">
        <Field
          :id="`${filter.id}_product_type`"
          twoway
          type="select"
          :value="filter.level"
          :options="productTypeSelection"
          @input="($event) => setProductType($event, filter)"
        />

        <Field
          :id="`${filter.id}_buying_not_buying`"
          twoway
          type="select"
          :value="filter.status"
          :options="buyingNotBuyingSelection"
          @input="($event) => setBuyingNotBuying($event, filter)"
        />
      </div>
      
      <ProductDataItemSelection
        v-if="filter.level === 'item_level'"
        :filter="filter"
        :selected-value="filter.level"
        @add-product="(product) => addProduct(product, filter)"
        @delete-product="(product) => removeProductFromQuery(product, filter)"
      />

      <ProductDataGroupSelection
        v-if="filter.level === 'product_group_level'"
        :filter="filter"
        :selected-value="filter.level"
        @add-product="(product) => addProduct(product, filter)"
        @set-product-group-level="(level) => setProductGroupLevel(level, filter)"
        @delete-product="(product) => removeProductFromQuery(product, filter)"
      />

      <div class="delete-icon">
        <IconButton
          v-if="selectedValues.length > 1"
          icon-name="trash"
          purpose="transparent"
          icon-color="var(--colour-utility-action)"
          variant="round"
          :icon-size="28"
          @on-click="() => removeProductFilter(filter)"
        />
      </div>
    </template>

    <div
      class="add-button"
    >
      <CustomDropdown
        v-bind="propsForMenu"
        close-on-selection
        :items="clauseOptions"
        @on-click="event => handleAddButtonClick(event.value)"
      />
    </div>

    <CustomerCount />
  </div>
</template>

<script setup>
// Constants
import { ref, onMounted, watch, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { TabSwitch, CustomDropdown, IconButton } from '@sales-i/dsv3';
import Field from '@/shared/components/Form/Field.vue';
import ProductDataItemSelection from '@/intelligence/components/AlertBuilder/AlertWizard/ProductDataItemSelection.vue';
import ProductDataGroupSelection from '@/intelligence/components/AlertBuilder/AlertWizard/ProductDataGroupSelection.vue';
import {
  EDIT_PRODUCT_IN_FILTER,
  PRODUCT_DATA_CLAUSE,
  REPLACE_PRODUCTS_IN_QUERY,
  UPDATE_PRODUCT_FILTER_IN_QUERY
} from '@/intelligence/store/actionType';
import CustomerCount from './CustomerCount.vue';
import FilterSummary from '@/intelligence/components/AlertBuilder/AlertWizard/FilterSummary.vue';
import { AND_OR_OPTIONS } from '@/intelligence/store/data/filters';

// Components
import { t } from '@sales-i/utils';

const currFilterId = ref(1);
const store = useStore();
const vroute = useRoute();
const valid = ref(false);
const showProductSelection = ref(false);
const selectedValues = ref([
  {
    id: currFilterId.value,
    operator: 'and',
    status: 'buying',
    items: [],
    level: 'item_level',
  },
]);
const props = defineProps({
  actionText: {
    type: String,
    default: '',
  },
  dateFilter: {
    type: String,
    default: '',
  },
  dateParams: {
    type: Object,
    default: () => ({}),
  },
  isEditMode: {
    type: Boolean,
    default: false,
  },
  isFirstProductSection: {
    type: Boolean,
    default: false,
  },
});


const propsForMenu = ref({
  iconWidth: 32,
  iconHeight: 32,
  iconColor: 'var(--colour-utility-white)',
  toggleIcons: ['plus', 'close-line']
});

// Update the main clause
const clauseOptions = AND_OR_OPTIONS;

const productTypeSelection = [
  {
    text: t('Item Level'),
    value: 'item_level'
  },
  {
    text: t('Product group level'),
    value: 'product_group_level',
  },
];

const buyingNotBuyingSelection = [
  {
    text: t('Buying'),
    value: 'buying'
  },
  {
    text: t('Not Buying'),
    value: 'not_buying',
  },
];

const breakVueLink = (item) => {
  return JSON.parse(JSON.stringify(item));
};

const setProductGroupLevel = (level, filter) =>{ 
  // Find the given sales filter
  const idx = selectedValues.value.findIndex(o => o.id === filter.id);
  selectedValues.value[idx].product_group_level = level;
  replaceProductsInQuery(breakVueLink(selectedValues.value));
};

// Add product to the query
const addProduct = (product, filter) => {
  const idx = selectedValues.value.findIndex(o => o.id === filter.id);

  selectedValues.value[idx].items.push(product);
  editProductInFilter(breakVueLink(selectedValues.value));
};

const removeProductFromQuery = (product, filter) => {
  const idx = selectedValues.value.findIndex(o => o.id === filter.id);
  const items = [];
  let x = 0;

  for (x; x < selectedValues.value[idx].items.length; x++) {
    const _ref = selectedValues.value[idx].items[x];
    if (_ref.id !== product.id) {
      items.push(_ref);
    }
  }

  // Find the given sales filter
  if (!props.isEditMode) {
    selectedValues.value[idx].items = items;
    editProductInFilter(breakVueLink(selectedValues.value));
  } else {
    selectedValues.value[idx].items = items;
    replaceProductsInQuery ({
      id: filter.id,
      items: items
    });
  }
};

const removeProductFilter = (filter) => {
  let newFilters = [];
  let x = 0;
  for (x; x < selectedValues.value.length; x++) {
    const _ref = selectedValues.value[x];
    if (_ref.id !== filter.id) {
      newFilters.push(_ref);
    }
  }
  
  selectedValues.value = newFilters;

  replaceProductsInQuery(breakVueLink(selectedValues.value));
};

const handleProductSwitch = (event, filter) => {
  updateProductFilterQuery({
    operator: event.value.toLowerCase(),
    filter
  });
};

function handleSwitch(event) {
  updateProductDataClause(event.value);
}

const selectedProducts = computed(() => store.getters['intelligence/queryBuilder/getAllProducts']);
const productDataClause = computed(() => store.state.intelligence.enquiry.productDataClause);
const updateProductDataClause = (params) => store.dispatch(`intelligence/enquiry/${PRODUCT_DATA_CLAUSE}`, params);
const editProductInFilter = (params) => store.dispatch(`intelligence/queryBuilder/${EDIT_PRODUCT_IN_FILTER}`, params);
const replaceProductsInQuery = (params) => store.dispatch(`intelligence/queryBuilder/${REPLACE_PRODUCTS_IN_QUERY}`, params);
const updateProductFilterQuery = (params) => store.dispatch(`intelligence/queryBuilder/${UPDATE_PRODUCT_FILTER_IN_QUERY}`, params);


const emit = defineEmits(['valid']);

function setProductType(value, filter) {
  // If it's a new filter, add it to the selected values
  if (!props.isEditMode) {
    const idx = selectedValues.value.findIndex(o => o.id === filter.id);
    selectedValues.value[idx].level = value;
    selectedValues.value[idx].items = [];
  }
  
  replaceProductsInQuery({
    id: filter.id,
    level: value,
    items: []
  });
}

function setBuyingNotBuying(value, filter) {
  const idx = selectedValues.value.findIndex(o => o.id === filter.id);
  selectedValues.value[idx].status = value;
  replaceProductsInQuery(breakVueLink(selectedValues.value));
}

function handleAddButtonClick(type) {
  currFilterId.value = currFilterId.value + 1;
  selectedValues.value.push({
    level: 'item_level',
    status: 'buying',
    operator: type.toLowerCase(),
    id: currFilterId.value,
    items: [],
  });
}

watch(
  selectedValues, () => {
    let isValid = true;
    selectedValues.value.forEach(o => {
      if (o.items.length === 0) {
        isValid = false;
      }
    });
    valid.value = isValid;
    emit('valid', valid.value);
  }
);

onMounted(() => {
  const { query: { prod, nprod } } = vroute;
  let products = [];
  let notProducts = [];
  
  if (prod !== undefined) {
    if (typeof prod === 'string') {
      products.push(prod);
    } else {
      products = [...prod];
    }
  }

  if (nprod !== undefined) {
    if (typeof nprod === 'string') {
      notProducts.push(nprod);
    } else {
      notProducts = [...nprod];
    }
  }

  if (products.length > 0 || notProducts.length > 0) {
    showProductSelection.value = true;
  }

  if (selectedProducts.value.length > 0) {
    selectedValues.value = breakVueLink(selectedProducts.value);
  } 
});
</script>

<style lang="scss" scoped>
.intro-wrapper {
  margin-inline: auto;
  max-width: 28rem;
  text-align: center;
  margin-bottom: var(--spacing-5);
}

h4 {
  margin: var(--spacing-2) 0;
}

.add-button {
  background-color: var(--colour-utility-white);
  top: 170px;
  position: sticky;
  display: flex;
  align-items: center;
  z-index: 10;

  :deep(.icon-button) {
    font-size: var(--font-size-4);
    font-weight: var(--font-weight-medium);
     
    span {
      margin-left: var(--spacing-2);
    }
  }
}
</style>