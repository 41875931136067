<template>
  <div
    class="data-container"
    :class="{ 'drill-active': isDrillActive }"
  >
    <div
      class="toolbar"
      :class="{ active: searchRowsQuery.length }"
    >
      <CustomCheckbox
        v-if="!isSnapshot && !isCustomerModeEnabled"
        id="variance-checkbox"
        :value="isVariancePlus"
        class="variance-checkbox"
        :label="t('Plus')"
        @input="toggleVariancePlusHandler"
      />
      <Field
        v-model="searchRowsQuery"
        class="search-container"
        type="text"
        :icon="{
          name: 'search',
          size: 24,
        }"
      />
    </div>
    <div class="variance-table-wrapper">
      <TableWrapper
        v-if="valueRows.length"
        table-height="510px"
        :offset-y="offset"
        container="div"
        enable-lazy-load
        disable-shades
        @set-offset="loadMoreData"
      >
        <div
          class="table-wrapper"
          :style="cssVars"
        >
          <div class="headings-dimensions">
            <div
              v-for="(dimensionHeading, index) in headingsDimensions"
              :key="index"
              :class="[`heading-dimension-${index}`, { 'sorting-header': sortHeader === dimensionHeading.value }]"
              role="button"
              tabindex="0"
              @click="applySort(dimensionHeading.value)"
              @keypress.space="applySort(dimensionHeading.value)"
            >
              {{ dimensionHeading.title }}
              <IconBase
                v-if="sortHeader === dimensionHeading.value && !isSnapshot"
                class="sort-icon"
                :icon-name="sortDescending ? 'arrow-down' : 'arrow-up'"
                :height="24"
                :width="24"
              />
            </div>
          </div>

          <div class="headings-values">
            <div
              v-for="(heading, index) in activeHeadings"
              :key="index"
              :style="getHeadingValueGridStyle(index)"
              class="heading-value"
            >
              <div class="heading-value-top">
                <div
                  class="heading-value-title"
                  :class="{ 'sorting-header': sortHeader === heading.value }"
                  role="button"
                  tabindex="0"
                  @click="applySort(heading.value)"
                  @keypress.space="applySort(heading.value)"
                >
                  <TableHeadContent
                    v-if="sortHeader === heading.value && !isSnapshot"
                  >
                    {{ heading.title }}
                    <template #icon>
                      <IconBase
                        class="sort-icon"
                        :icon-name="sortDescending ? 'arrow-down' : 'arrow-up'"
                        :height="16"
                        :width="16"
                      />
                    </template>                
                  </TableHeadContent> 
                  
                  <div
                    v-else-if="!isSnapshot"
                    class="flex align-centre"
                  >
                    {{ heading.title }}
                    <IconBase
                      icon-name="carets"
                      :height="24"
                      :width="24"
                      :icon-color="getIconColor(heading.value)"
                    />
                  </div>

                  <div
                    v-else-if="isSnapshot"
                    class="flex align-centre"
                  >
                    {{ heading.title }}
                  </div>

                  <IconButton
                    v-if="showCloseColumnButton"
                    class="close-button"
                    icon-name="close-line"
                    purpose="transparent"
                    icon-only
                    :icon-size="16"
                    @click.stop="removeColumn(heading.value)"
                  />
                </div>
                <div
                  class="heading-value-icons"
                  :class="{ 'sorting-icons': !isSnapshot }"
                >
                  <div
                    v-for="(key, i) in heading.keys"
                    :key="key"
                    :class="{ 'active-period': sortHeader === key }"
                    role="button"
                    tabindex="0"
                    @click="applySort(key, i + 1)"
                    @keypress.space="applySort(key, i + 1)"
                  >
                    {{ i + 1 }}:
                    <IconBase
                      icon-name="calendar"
                      :height="24"
                      :width="24"
                      :icon-color="getIconColor(key)"
                    />
                    <IconBase
                      v-if="sortHeader === key"
                      :icon-name="sortDescending ? 'arrow-down' : 'arrow-up'"
                      :height="24"
                      :width="24"
                      :icon-color="getIconColor(key)"
                    />
                    <IconBase
                      v-else-if="!isSnapshot"
                      icon-name="carets"
                      :height="24"
                      :width="24"
                      :icon-color="getIconColor(key)"
                    />
                  </div>
                </div>
              </div>
              <ValueSet
                class="heading-value-numbers"
                :value-object="summaryValues[heading.value]"
                :kpi-name="heading.value"
                :is-percent-value-active="isPercentValueActive"
              />
            </div>
          </div>
          <div class="rows">
            <div
              v-for="(row, index) in valueRows"
              v-show="showRow(row)"
              :key="index"
              class="value-row"
            >
              <div
                v-if="isDrillActive && !isSnapshot"
                class="drill"
              >
                <CustomCheckbox
                  class="checkbox"
                  :value="row.checked"
                  @input="handleEnquiryDrill(row, $event, index)"
                />
              </div>
              <div
                v-for="(dimension, i) in row.dimensions"
                :key="i"
                :class="[{ 'grid-container': i === 0 }, `row-dimension-${i}`]"
                :title="dimension.dimensions[0]"
              >
                <span
                  v-if="i === 0"
                  class="order-number"
                  :class="{ 'order-number-link': dimension.entity_id }"
                >#{{ index + 1 }}&nbsp;</span>
                <div 
                  v-if="dimension.entity_id && !iframe"
                >
                  <a
                    class="bubble-title link"
                    :href="getCustomerLink(dimension.entity_id)"
                    target="_blank"
                  >
                    {{ dimension.dimensions[0] }}
                  </a>
                  <p class="bubble-subtitle">
                    {{ dimension.dimensions[1] }}
                  </p>
                </div>
                <div 
                  v-else
                >
                  <p class="bubble-title">
                    {{ dimension.dimensions[0] }}
                  </p>
                  <p class="bubble-subtitle">
                    {{ dimension.dimensions[1] }}
                  </p>
                </div>
              </div>
              <ValueSet
                v-for="(heading, i) in activeHeadings"
                :key="heading.value"
                class="row-value-set"
                :style="getRowGridStyle(i)"
                :value-object="row[heading.value]"
                :mobile-values="row.dimensions"
                small
                :kpi-name="heading.value"
                :order-number="index"
                :is-percent-value-active="isPercentValueActive"
              />
            </div>
          </div>
        </div>
      </TableWrapper>
      <div
        v-if="showCustomDropDown"
        class="add-section"
      >
        <div class="tooltip-container">
          <CustomDropdown
            class="add-column-button"
            :items="unusedHeadings"
            menu-position="left"
            :toggle-icons="['plus', 'close-line']"
            :icon-height="18"
            :icon-width="18"
            icon-color="var(--colour-utility-white)"
            close-on-selection
            btn-style="width: 24px; height: 24px;"
            @click="addColumn"
          />
          <ToolTip :content="t('Add other measures to see the variance of other key performance indicators')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CustomCheckbox, IconButton, CustomDropdown, IconBase, ToolTip } from '@sales-i/dsv3';
import breakpoints from '@/shared/utils/breakpoints';
import { mapState, mapGetters, mapActions } from 'vuex';
import ValueSet from '@/intelligence/components/Variance/ValueSet';
import { APPLY_OFFSET, TOGGLE_DRILL, SET_ACTIVE_COLUMNS, TOGGLE_VARIANCE_PLUS } from '@/intelligence/store/actionType';
import Field from '@/shared/components/Form/Field';
import { baseUrl as crmBaseUrl, customersArea } from '@/crm/router/urlBits';
import { VARIANCE, VARIANCE_ENQUIRY_BY_QUANTITY } from '@/intelligence/store/data/reportTypes';
import { REPORTS_KPIS_FOR_CUSTOMER_MODE, REPORTS_KPI_QUANTITY, REPORTS_KPI_SALES_VALUE } from '@/intelligence/store/data/kpis';
import TableWrapper from '@/shared/components/Tables/TableWrapper';
import TableHeadContent from '@/shared/components/Tables/TableHeadContent';
import { t } from '@sales-i/utils';
import { REPORTS_SORT_ASCENDING_KEY, REPORTS_SORT_DESCENDING_KEY } from '@/intelligence/store/data/apiInput';
import { VARIANCE_COLUMNS } from '@/intelligence/store/data/kpis';

export default {
  components: {
    IconBase,
    IconButton,
    ValueSet,
    CustomCheckbox,
    CustomDropdown,
    Field,
    TableHeadContent,
    TableWrapper,
    ToolTip,
  },
  inject: ['mq'],
  props: {
    isSnapshot: {
      type: Boolean,
      default: false,
    },
    tableParameters: {
      type: Object,
      default: () => ({}),
    },
    reportType: {
      type: String,
      default: '',
    },
    enquiryData: {
      type: Object,
      default: () => ({}),
    },
    activeColumns: {
      type: Array,
      default: () => (['sales_value'])
    },
    isPercentValueActive: {
      type: Boolean,
      default: false,
    },
    isAlertResults: {
      type: Boolean,
      default: false,
    },
    isInitialLoad: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['setEnquiryData', 'loadTable', 'handleEnquiryDrill', 'loadMoreData', 'update:columns', 'addAlertColumn', 'removeAlertColumn'],
  data() {
    return {
      sortDescending: false,
      sortHeader: '',
      valueRows: [],
      searchRowsQuery: '',
      offset: 0,
      isPeriodUsed: false,
      period: '',
    };
  },
  computed: {
    ...mapState({
      isDrillActive: state => state.intelligence.shared.isDrillActive,
      limit: state => state.intelligence.shared.requestParameters.limit,
      drillActiveRows: state => state.intelligence.shared.drillActiveRows,
      isCustomerModeEnabledInState: state => state.admin.intelligence.isCustomerModeEnabled,
      iframe: state => state.system.iframe,
      isVariancePlus: state => state.intelligence.shared.isVariancePlus,
    }),
    ...mapGetters({
      getDrillsLevel: 'intelligence/shared/getDrillsLevel',
      getDrillingDimensionId: 'intelligence/shared/getDrillingDimensionId',
      getReportData: 'intelligence/shared/getReportData',
      getInputData: 'intelligence/shared/getInputData',
    }),
    isCustomerModeEnabled() {
      // CustomerMode is can be enabled manually, or for some tenants. To distinguish those tenants only option is to check if there are empty headings (Profit, Cost, Gross Profit are removed)
      return this.isCustomerModeEnabledInState || this.shouldHideFragileData;
    },
    shouldHideFragileData() {
      // Filter to remove empty headings if there are any (see. isCustomerModeEnabled)
      return this.enquiryData?.headings?.values?.some(heading => heading === '') || false;
    },
    showCustomDropDown() {
      const varianceReport = this.activeHeadings.length < 4 && !this.isSnapshot;
      const varianceAlert = this.isAlertResults && this.unusedColumns.length > 0;

      return varianceReport || varianceAlert;
    },
    showCloseColumnButton() {
      const varianceReport = this.activeColumns.length !== 1 && !this.isSnapshot;
      const varianceAlert = this.activeColumns.length > 1 && this.isAlertResults;

      return varianceReport || varianceAlert;
    },
    inputData() {
      return this.getInputData(VARIANCE);
    },
    allEnquiryHeadings() {
      if (!this.enquiryData.headings.values) return this.allHeadings || [];
      return this.enquiryData.headings.values
        .map((title, index) => {
          const value = Object.keys(this.summaryValues)[index];
          return { title, value, keys: [`${value}_${1}`, `${value}_${2}`] };
        })
        .filter(heading => this.isValidColumn(heading.value));
    },
    allHeadings() {
      return VARIANCE_COLUMNS.map(col => ({
        title: col.text,
        value: col.value,
        keys: [`${col.value}_1`, `${col.value}_2`]
      }));
    },
    activeHeadings() {
      let getHeadings = this.allEnquiryHeadings.filter(heading => this.activeColumns.includes(heading.value));

      // Ensures that any newly added column appears at the end of the array
      getHeadings = getHeadings.sort((a, b) => this.activeColumns.indexOf(a.value) - this.activeColumns.indexOf(b.value));

      return getHeadings;
    },
    unusedHeadings() {
      const headings = (this.allColumns.length <= 1) ? this.allHeadings : this.allEnquiryHeadings;
      return headings.filter(heading => !this.activeColumns.includes(heading.value));
    },
    allColumns() {
      const headingsArray = this.allEnquiryHeadings.map(column => column.value);
      const defaultSortHeader = `${REPORTS_KPI_SALES_VALUE}_variance`;  // Use defaultSortHeader if tableParameters.sortHeader is undefined or null
      const sortHeader = this.sortHeader || this.tableParameters.sortHeader || defaultSortHeader;
  
      headingsArray.unshift(
        this.reportType === VARIANCE_ENQUIRY_BY_QUANTITY ? 
          REPORTS_KPI_QUANTITY : 
          sortHeader.replace(/(_1|_2|_variance)$/, '')
      );
      return [...new Set(headingsArray)].filter(n => n);
    },
    unusedColumns() {
      return this.allColumns.filter(c => !this.activeColumns.includes(c));
    },
    summaryValues() {
      return this.enquiryData.summary;
    },
    headingsDimensions() {
      return this.enquiryData.headings.dimensions.map((title, index) => {
        return { title, ...(index && { value: 'dim2' }) };
      });
    },
    cssVars() {
      return {
        '--table-columns-grid': `repeat(2, 120px) repeat(${this.activeHeadings.length * 2}, 1fr)`,
        '--rows-grid-area': '2/1/3/25',
        '--headings-grid-area-sm': `1/1/2/${1 + this.activeHeadings.length * 2}`,
        '--headings-grid-area': `1/3/2/${3 + this.activeHeadings.length * 2}`,
        '--heading-columns-grid': `repeat(${this.activeHeadings.length * 2}, 1fr)`,
        '--dimension-columns-grid': `repeat(${this.headingsDimensions.length}, 1fr)`,
        '--dimension-column-width': `${(240 - (this.isDrillActive ? 30 : 0)) / this.headingsDimensions.length}px`,
      };
    },
    isSmallScreen() {
      return breakpoints.smAndDown.includes(this.mq.current);
    },
  },
  watch: {
    activeColumns(columns) {
      this.isVariancePlus =
        !this.isCustomerModeEnabled && columns.length === 4 && this.allColumns.slice(0, 4).every(element => columns.includes(element));
    },
    enquiryData(newData) {
      if (newData) {
        this.valueRows = this.processRows(newData.rows);
        this.checkDrillActiveRows();
      }
    },
    drillActiveRows(newRows, oldRows) {
      if (!newRows.length) {
        oldRows.forEach(oldRow => {
          this.handleEnquiryDrill(this.findRowByDrillValue(oldRow), false);
        });
      }
    },
  },
  mounted() {
    this.valueRows = this.processRows(this.enquiryData.rows);
    this.checkDrillActiveRows();

    if (!this.activeColumns.length) {
      this.setDefaultActiveColumns();
    }

    if (this.isSnapshot) {
      this.setVariancePlus(false);
    } else if (Object.keys(this.tableParameters).length) {
      const { sortHeader, sortOption, activeColumns } = this.tableParameters;
      this.sortHeader = sortHeader.replace('_variance', '');
      this.sortDescending = sortOption === 'desc';
      if (activeColumns.length) this.setColumns(activeColumns);
    }
    if (!this.isSmallScreen) this.toggleDrill(true);
  },
  methods: {
    t,
    ...mapActions({
      toggleDrill: `intelligence/shared/${TOGGLE_DRILL}`,
      applyOffset: `intelligence/shared/${APPLY_OFFSET}`,
      toggleVariancePlus: `intelligence/shared/${TOGGLE_VARIANCE_PLUS}`,
      setActiveColumns: `intelligence/shared/${SET_ACTIVE_COLUMNS}`,
    }),
    processRows(rows) {
      return rows.map(row => {
        const rowDims = row.dimensions;
        let id = `${rowDims[0].dimensions[0]},${rowDims[0].dimensions[1]}`;
        if (this.headingsDimensions.length > 1) id += `:${rowDims[1].dimensions[0]},${rowDims[1].dimensions[1]}`;
        return { ...row, checked: false, id };
      });
    },
    checkDrillActiveRows() {
      this.drillActiveRows.forEach(activeRow => {
        const row = this.findRowByDrillValue(activeRow);
        if (row) row.checked = true;
      });
    },
    findRowByDrillValue(drillObject) {
      return this.valueRows.find(row => row.id === drillObject.id);
    },
    applySort(key, period) {
      if (this.isSnapshot || !key) return;
      if (this.sortHeader === key) {
        this.sortDescending = !this.sortDescending;
      } else {
        this.sortDescending = this.inputData.defaultSortOption === 'desc';
        this.sortHeader = key;
        this.period = period;
      }
      this.isPeriodUsed = !!period;
      this.applyOffset(0);
      this.$emit(
        'loadTable',
        {
          sortHeader: this.isPeriodUsed || key === 'dim2' ? key : `${key}_variance`,
          sortOption: this.sortDescending ? 'desc' : 'asc',
          activeColumns: this.activeColumns,
        },
        false
      );
    },
    isValidColumn(headingValue) {
      return !this.isCustomerModeEnabled || REPORTS_KPIS_FOR_CUSTOMER_MODE.includes(headingValue);
    },
    removeColumn(colName){
      if (this.isAlertResults) {
        this.setColumns(
          this.activeColumns.filter(
            column => column !== colName
          ));
        this.$emit('removeAlertColumn', colName);

      } else {
        this.setColumns(this.activeColumns.filter(column => column !== colName));
      }
    },
    addColumn({ value }) {
      if (this.isAlertResults) {
        this.setColumns([
          // Add the new column to the end of the activeColumns array
          ...this.activeColumns,
          value,
        ]);
        this.$emit('addAlertColumn', value);
      } else {
        if (this.enquiryData.headings.values.length <= 1) {
          this.$emit('loadTable');
        } 
        this.setColumns([...this.activeColumns.filter(col => this.isValidColumn(col)), value]);
      }
    },
    setDefaultActiveColumns() {
      this.setColumns(this.isVariancePlus ? this.allColumns.slice(0, 4) : this.allColumns.slice(0, 1));
    },
    toggleVariancePlusHandler(isVariancePlus) {
      this.toggleVariancePlus(isVariancePlus);
      if (this.enquiryData.headings.values.length <= 1) {
      // Variance initially loads only sales_value column. For variance plus, rest of the data needs to be loaded.
        this.$emit(
          'loadTable', ({
            sortHeader: this.tableParameters.sortHeader,
            sortOption: this.sortDescending ? REPORTS_SORT_DESCENDING_KEY : REPORTS_SORT_ASCENDING_KEY,
            activeColumns: isVariancePlus ? ['sales_value','cost','profit','gross_profit'] : ['sales_value'],
          }));
      }
      this.setDefaultActiveColumns();
    },
    setColumns(newColumns) {
      this.setActiveColumns(newColumns);
      this.$emit('update:columns', newColumns);
    },
    setVariancePlus(isVariancePlus) {
      this.toggleVariancePlusHandler(isVariancePlus);
    },
    handleEnquiryDrill(dataRow, checked, index) {
      if (!dataRow) return;
      if (index === undefined) {
        this.valueRows = this.valueRows.map(row => {
          return {
            ...row,
            checked,
          };
        });
      } else {
        this.valueRows[index].checked = checked;
      }

      let id, item;
      const dim1Id = this.getDrillingDimensionId(this.getDrillsLevel(), 0);
      const dim2Id = this.getDrillingDimensionId(this.getDrillsLevel(), 1);
      const newDimensions = dataRow.dimensions;

      switch (newDimensions.length) {
      case 1:
        id = `${newDimensions[0].dimensions[0]},${newDimensions[0].dimensions[1]}`;
        item = {
          [dim1Id]: {
            value: newDimensions[0].dimensions[0],
            pair: newDimensions[0].dimensions[1],
          },
        };
        break;
      case 2:
      default:
        id = `${newDimensions[0].dimensions[0]},${newDimensions[0].dimensions[1]}:${newDimensions[1].dimensions[0]},${newDimensions[1].dimensions[1]}`;
        item = {
          [dim1Id]: {
            value: newDimensions[0].dimensions[0],
            pair: newDimensions[0].dimensions[1],
          },
          [dim2Id]: {
            value: newDimensions[1].dimensions[0],
            pair: newDimensions[1].dimensions[1],
          },
        };
        break;
      }

      this.$emit('handleEnquiryDrill', {
        checked,
        id,
        item,
      });
    },
    getCustomerLink(id) {
      return `${crmBaseUrl}/${customersArea}/${id}`;
    },
    loadMoreData() {
      const limit = this.isSnapshot ? 20 : this.limit;
      if (limit + this.offset > this.valueRows.length) return;
      this.offset += limit;
      this.applyOffset(this.offset);
      this.$emit('loadMoreData');
    },
    showRow(row) {
      const query = this.searchRowsQuery.trim().toLowerCase();
      return query.length < 1 || (typeof row.id === 'string' && row.id.toLowerCase().includes(query));
    },
    getIconColor(headingValue) {
      return this.sortHeader !== headingValue && !this.isSnapshot ? 'var(--colour-utility-action)' : 'var( --colour-utility-black)';
    },
    getRowGridStyle(i) {
      return { 'grid-area': `1/${3 + 2 * i}/2/${5 + 2 * i}` };
    },
    getHeadingValueGridStyle(i) {
      return { 'grid-area': `1/${1 + 2 * i}/3/${3 + 2 * i}` };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
.variance-table-wrapper {
  display: grid;
  grid-template-columns: 1fr auto;
}

.variance-checkbox {
  position: relative;
  padding: var(--spacing-2);
}

.table-wrapper {
  display: grid;
  grid-template-columns: var(--table-columns-grid);
  text-align: center;
  font-size: var(--font-size-small);
  grid-template-rows: 150px;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    grid-template-columns: var(--heading-columns-grid);
    position: relative;
  }
  
  .headings-values {
    grid-area: var(--headings-grid-area);
    display: grid;
    grid-template-columns: var(--heading-columns-grid);
    grid-template-rows: repeat(2, 1fr);
    z-index: 10;
    position: sticky;
    top: 0;
    background: var(--colour-utility-white);

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      grid-area: var(--headings-grid-area-sm);
      z-index: 4;
      position: sticky;
      top: 0;
    }

    .heading-value {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, 1fr);
      border-left: 1px solid var(--colour-panel-g-16);
      border-bottom: 1px solid var(--colour-panel-g-16);
      height: 150px;
      min-width: 160px;

      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        min-width: calc(100vw - 90px);
        background: var(--colour-utility-white);
      }

      .heading-value-top {
        background: var(--colour-panel-action);
        grid-area: 1 / 1 / 2 / 2;
        height: 81px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, 1fr);

        .heading-value-title {
          grid-area: 1 / 1 / 2 / 2;
          color: var(--colour-utility-action);
          text-decoration: underline;
          font-weight: var(--font-weight-semibold);
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;

          &.sorting-header {
            background: var(--colour-panel-base);
            color: var(--colour-utility-black);
            font-weight: var(--font-weight-medium);
            text-decoration: none;
          }

        .sort-icon {
          position: absolute;
          top: 0;
          right: 0; 
        }

          .close-button {
            display: inline-block;
            position: absolute;
            top: 0px;
            left: -3px;
          }
        }

        .heading-value-icons {
          grid-area: 2 / 1 / 3 / 2;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: 1fr;
          border-bottom: 1px solid var(--colour-panel-g-16);
          height: 41px;
          background: var(--colour-utility-white);

          &.sorting-icons {
            color: var(--colour-utility-action);
            font-weight: var(--font-weight-semibold);
            cursor: pointer;
          }

          & > div {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          & > div:first-child {
            grid-area: 1 / 1 / 2 / 2;
          }
          & > div:last-child {
            grid-area: 1 / 2 / 2 / 3;
            border-left: 1px solid var(--colour-panel-g-16);
          }

          .active-period {
            color: var(--colour-utility-black);
          }
        }
      }

      .heading-value-numbers {
        grid-area: 2 / 1 / 3 / 2;
        height: 69px;
        background: var(--colour-utility-white);
        border-bottom: 1px solid var(--colour-panel-g-16);
      }
    }
  }
  .headings-dimensions {
    grid-area: 1/1/2/3;
    display: grid;
    grid-template-columns: var(--dimension-columns-grid);
    grid-template-rows: 1fr;
    background: var(--colour-utility-white);
    color: var(--colour-utility-action);
    text-decoration: underline;
    font-weight: var(--font-weight-semibold);
    cursor: pointer;
    width: 240px;
    border-bottom: 1px solid var(--colour-panel-g-16);
    z-index: 4;
    position: sticky;
    top: 0;
    height: 150px;

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      display: none;
    }

    .heading-dimension-0,
    .heading-dimension-1 {
      background: var(--colour-panel-action);
      height: 70px;
      align-self: end;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 var(--spacing-1);

      &.sorting-header {
        background: var(--colour-panel-base);
        color: var(--colour-utility-black);
        font-weight: var(--font-weight-medium);
        text-decoration: none;
      }

      .sort-icon {
        display: inline-block;
        top: 5px;
        right: 5px;
      }
    }

    .heading-dimension-0 {
      grid-area: 1 / 1 / 2 / 2;
    }
    .heading-dimension-1 {
      grid-area: 1 / 2 / 2 / 3;
    }
  }
  .rows {
    grid-area: var(--rows-grid-area);
    overflow: initial;
    position: relative;

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      overflow: initial;
    }

    .value-row {
      display: grid;
      grid-template-columns: var(--table-columns-grid);
      grid-template-rows: 1fr;
      min-height: 60px;
      position: relative;

      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        height: 100px;
        grid-template-columns: repeat(2, 0);
      }

      .row-value-set {
        border-left: 1px solid var(--colour-panel-g-16);
        border-bottom: 1px solid var(--colour-panel-g-16);
        height: 100%;
        min-width: 160px;

        @media #{map-get($display-breakpoints, 'sm-and-down')} {
          min-width: calc(100vw - 90px);
        }
      }

      .row-dimension-0,
      .row-dimension-1 {
        width: var(--dimension-column-width);
        overflow: hidden;
        height: 100%;
        display: flex;
        justify-content: center;
        border-bottom: 1px solid var(--colour-panel-g-16);
        padding: 0 var(--spacing-1);
        cursor: help;

        @media #{map-get($display-breakpoints, 'sm-and-down')} {
          display: none;
        }

        & > div > p:nth-of-type(2) {
          font-weight: var(--font-weight-regular);
          color: var(--colour-utility-base);
        }
      }
      .row-dimension-0 {
        grid-area: 1 / 1 / 2 / 2;
        padding-top: var(--spacing-2);
        justify-content: initial;

        .bubble-subtitle {
          text-align: left;
        }
      }
      .row-dimension-1 {
        grid-area: 1 / 2 / 2 / 3;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.add-section {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
  background-color: var(--colour-panel-g-2);
  padding: var(--spacing-2);

  .add-column-button {
    display: flex;
    justify-content: center;
    margin: auto;
  }
}

.drill-active {
  .row-dimension-0 {
    position: relative;
    left: 30px;
  }
  .row-dimension-1 {
    position: relative;
    left: 15px;
  }
}

.drill {
  background: var(--colour-panel-base);
  padding: var(--spacing-2) var(--spacing-half);
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--colour-panel-g-16);
  width: 30px;
  z-index: 3;

  .checkbox {
    border-radius: var(--border-radius-half);
    margin-top: var(--spacing-half);
    margin-bottom: 0;
  }
}

.pagination-button {
  max-width: 350px;
  border-radius: 0;
  height: auto;
  z-index: 4;
  position: fixed;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    position: absolute;
  }

  &.bottom {
    bottom: -250px;
    left: 0;
    right: 0;
    margin: 0 auto;
    transition: bottom 0.5s;
    position: absolute;

    &.show {
      bottom: var(--spacing-3);
    }
  }

  &.top {
    top: -250px;
    left: 0;
    right: 0;
    margin: 0 auto;
    transition: top 0.5s;
    position: absolute;

    &.show {
      top: var(--spacing-3);
    }
  }
}

.toolbar {
  display: flex;
  height: 68px;
  padding: var(--spacing-half) var(--spacing-1);
  width: 240px;
  transition: width 0.5s;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding: var(--spacing-1);
    position: absolute;
    z-index: 16;
    top: 0;
    left: 0;
  }

  &.active {
    width: 370px;
  }
}

.search-container {
  position: relative;
  display: block;
  margin: var(--spacing-1) 0 0;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    margin-left: var(--spacing-1);
  }

  .field {
    position: relative;
  }

  :deep(.svg-container.icon-search) {
    top: 50% !important;
    transform: translateY(-50%);
  }

  :deep(input) {
    border-radius: var(--spacing-4);
    font-weight: var(--font-weight-regular);
    padding: var(--spacing-1) var(--spacing-2) var(--spacing-1) var(--spacing-4);
    width: 80%;
    transition: width 0.3s;
  }
}

.variance-checkbox {
  + .search-container {
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      margin-left: var(--spacing-half);
    }

    :deep(input) {
      min-width: 100px;
      width: 45%;

      &:focus {
        width: 80%;
      }
    }
  }
}

.bubble-title,
.bubble-subtitle {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80px;
}

.bubble-title.link {
  font-weight: var(--font-weight-semibold);
}

.bubble-subtitle {
  color: var(--colour-utility-base);
}

p:not(.bubble-subtitle) {
  font-weight: var(--font-weight-semibold);
}

.tooltip-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-1);
}

.grid-container {
  display: flex;
  align-items: flex-start;

  .order-number {
    white-space: nowrap;
    font-weight: var(--font-weight-semibold);
    &-link {
      color: var(--colour-utility-base);
    }
  }

  .bubble-title,
  .bubble-subtitle {
    flex: 1;
    text-align: left;
  }

  .bubble-subtitle {
    margin-left: 0;
  }
}
</style>
