<template>
  <TableWrapper
    v-if="!dataLoading && dataRows.length"
    :class="{ 'is-snapshot': isSnapshot }"
    table-height="510px"
    :more-results-available="moreResultsAvailable"
    :offset-x="offsetX"
    :offset-y="offsetY"
    :limit="requestParameters.limit"
    :limit-x="requestParameters.limitX"
    :enable-pagination="!isSnapshot"
    :x-headings="xHeadings"
    :y-headings="yHeadings"
    @set-offset="loadData"
  >
    <thead :style="cssVars">
      <th
        v-for="(dimHeading, index) in yDims"
        :key="index"
        :class="[{ 'search-header': !index, 'only-dim' : yDims.length === 1 }, 'dim-header ydim-header', ]"
      >
        <div class="ydim-header-inner">
          <div
            :key="index"
            role="button"
            tabindex="0"
            :class="['heading', `${userSort.column !== index && 'span-2'}`]"
            @click="() => applySort(index)"
            @keypress.space="applySort(index)"
          >
            {{ dimHeading.entity }}
          </div>
          <div
            v-if="userSort.column === index && !isSnapshot"
            class="sort-icon"
            tabindex="0"
            role="button"
            @click="applySort(index)"
            @keypress.space="applySort(index)"
          >
            <IconBase
              :icon-name="
                userSort.direction === REPORTS_SORT_DESCENDING_KEY ? 'arrow-down' : 'arrow-up'
              "
              :height="20"
              :width="20"
            />
          </div>
          <SearchInput
            v-if="index === 0"
            :value="searchRowsQuery"
            class="search-container"
            :aria-label="t('Search')"
            :icon-size="24"
            @search="handleSearchInput"
          />
        </div>
      </th>
      <th
        v-for="(heading, index) in xHeadings"
        :key="`${heading}-${index}`"
        :title="heading"
        class="dim-header xdim-header"
      >
        <div class="xdim-header-inner">
          <div class="heading">
            {{ heading }}
          </div>
          <IconBase
            v-if="userSort.column === `p${index}` && !isSnapshot"
            class="sort-icon"
            :icon-name="userSort.direction === REPORTS_SORT_DESCENDING_KEY ? 'arrow-down' : 'arrow-up'"
            :height="20"
            :width="20"
          />
          <div
            class="chip"
            :title="getChipValue(index)"
          >
            {{ isPriceTypeData
              ? cPrice(getChipValue(index))
              : cNumber(getChipValue(index)) }}
          </div>
        </div>
      </th>
    </thead>
    <tbody>
      <tr
        v-for="(row, ind) in filteredRows"
        :key="row.id"
      >
        <th
          v-for="(dim, idx) in row.ydims"
          :key="idx"
          :class="[
            {
              'chip-hidden': !rowsChipsVisible,
              'only-dim' : yDims.length === 1
            },
            `dim-row`
          ]"
          :title="dim.values[0]"
        >
          <div
            v-if="(isDrillActive && !isSnapshot) && idx === 0"
            class="drill"
          >
            <CustomCheckbox
              :id="ind"
              class="checkbox"
              :value="row.checked"
              @input="handleEnquiryDrill(row, $event)"
            />
          </div>
          <div class="dim-row-inner">
            <span
              v-if="idx === 0"
              class="row-total"
              :class="[row.total < 0 ? 'negative' : 'positive']"
            >
              {{
                isPriceTypeData
                  ? cPrice(row.total || row.row_total)
                  : cNumber(row.total || row.row_total)
              }}
            </span>
            <span
              v-if="idx === 0"
              :class="['order-number', row.customer ? 'order-number-link' : '']"
            >#{{ Number(offsetY) + ind + 1 }}&nbsp;</span>
            <div
              v-if="dim.id && !iframe"
              class="bubble-wrap"
            >
              <a
                class="bubble-title link"
                :href="getCustomerLink(dim.id)"
                target="_blank"
              >
                {{ dim.values[0] }}
              </a>
              <p class="bubble-subtitle">
                {{ dim.values[1] }}
              </p>
            </div>
            <div
              v-else
              class="bubble-wrap"
            >
              <p class="bubble-title">
                {{ dim.values[0] }}
              </p>
              <p class="bubble-subtitle">
                {{ dim.values[1] }}
              </p>
            </div>
            <IconButton
              v-if="isSnapshot && isSmallScreen"
              class="chip-toggler"
              :icon-size="32"
              icon-name="caret-right"
              icon-color="var(--colour-utility-action)"
              purpose="transparent"
              icon-only
              @click="toggleChips"
            />
            <div
              v-if="isSnapshot"
              v-show="rowsChipsVisible"
              class="chip"
              :title="row.total"
            >
              {{
                isPriceTypeData
                  ? cPrice(row.total)
                  : cNumber(row.total)
              }}
            </div>
          </div>
        </th>
        <td
          v-for="(cell, i) in row.values"
          :key="`cell-${row.id}-${i}`"
          :title="parseInt(cell, 10).toLocaleString('en-US')"
          :class="{
            zero: parseInt(cell, 10) === 0,
            negative: parseInt(cell, 10) < 0,
            'dim-value': true
          }"
        >
          <div>
            {{ cell
              ? isPriceTypeData
                ? cPrice(cell)
                : cNumber(cell)
              : '-' }}
          </div>
        </td>
      </tr>
    </tbody>
  </TableWrapper>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import Fuse from 'fuse.js';
import { useMq } from 'vue3-mq';
import { t } from '@sales-i/utils';
import { CustomCheckbox, IconBase, IconButton } from '@sales-i/dsv3';
import { baseUrl as crmBaseUrl, customersArea } from '@/crm/router/urlBits';
import {
  REPORTS_KPI_COST,
  REPORTS_KPI_PROFIT,
  REPORTS_KPI_SALES_VALUE,
  REPORTS_KPI_VALUE
} from '@/intelligence/store/data/kpis';
import TableWrapper from '@/shared/components/Tables/TableWrapper';
import breakpoints from '@/shared/utils/breakpoints';
import useCurrency from '@/shared/composables/useCurrency';
import useShared from '@/intelligence/composables/useShared';
import useSystem from '@/shared/composables/useSystem';
import useEnquiries from '@/intelligence/composables/useEnquiries';
import SearchInput from '@/dsv3/Forms/SearchInput.vue';
import { REPORTS_SORT_ASCENDING_KEY, REPORTS_SORT_DESCENDING_KEY } from '@/intelligence/store/data/apiInput';

const mq = useMq();
const store = useStore();

const props = defineProps({
  isSnapshot: {
    type: Boolean,
    default: false
  },
  reportId: {
    type: [String, Number],
    default: null
  },
  isQuantityType: {
    type: Boolean,
    default: false
  },
});

const emit = defineEmits(['sortOnYAxis', 'handleEnquiryDrill', 'setEnquiryData']);

const { cPrice, cNumber } = useCurrency({ store });
const { 
  dataLoading, requestParameters, isDrillActive, drillActiveRows,
  getReportData, applyOffset: applyOffsetY, toggleDrillOption } = useShared({ store });
const { iframe } = useSystem({ store });
const { applyOffsetX } = useEnquiries({ store });

const searchRowsQuery = ref('');
const searchIndex = ref(0);
const search = ref(null);
const moreResultsAvailable = ref(true); 
const offsetX = ref(0);
const offsetY = ref(0);
const rowsChipsVisible = ref(true);

const userSort = ref({
  column: '',
  direction: REPORTS_SORT_DESCENDING_KEY
});

const defaultSort = {
  column: 'total',
  direction: REPORTS_SORT_DESCENDING_KEY
};
    
const cssVars = computed(() => ({
  '--rows-grid-area': '2/1/3/25',
  '--dimension-columns-grid': `repeat(${yDims.value.length}, 1fr)`,
  '--dimension-column-width': `${
    (240 - (isDrillActive.value ? 30 : 0)) / yDims.value.length
  }px`
}));

const enquiryData = computed(() => getReportData(props.reportId));
const isSmallScreen = computed(() => breakpoints.smAndDown.includes(mq.current));
const xHeadings = computed(() => enquiryData.value?.axis?.x?.headings || []);
const yHeadings = computed(() => enquiryData.value?.axis?.y?.[0]?.headings || []);
const yDims = computed(() => enquiryData.value?.axis?.y || []);
const summary = computed(() => enquiryData.value.summary || []);
const isPriceTypeData = computed(() => [
  REPORTS_KPI_COST,
  REPORTS_KPI_PROFIT,
  REPORTS_KPI_SALES_VALUE,
  REPORTS_KPI_VALUE
].includes(requestParameters.value.measure) && !props.isQuantityType);

const dataRows = computed(() => {
  if (!enquiryData.value?.rows) return [];
  let customer = null;

  let result = enquiryData.value?.rows?.map((row) => {
    const ydims = row.dimension_values;
    const rowf = { ...row };
    const id = row?.dimension_values[0].values.join(',');
    let checked = false;
    for (let i = 0; i < row?.dimension_values.length; i++) {
      if (row?.dimension_values[i].id !== null) {
        customer = i;
        break;
      }
    }
    drillActiveRows.value.forEach((activeRow) => {
      if (activeRow.id === id) {
        rowf.checked = true;
        checked = true;
      }
    });
    const rowValue = {
      values: rowf.values,
      total: rowf.total,
      row_total: rowf.total,
      customer,
      checked,
      ydims,
      id
    };
    return rowValue;
  });
  return result;
});

const filteredRows = computed(() => searchRowsQuery.value
  ? search.value.search(searchRowsQuery.value).map((row) => row.item)
  : dataRows.value);

function handleSearchInput(value) {
  searchRowsQuery.value = value;
}
  
watch(() => enquiryData, () => {
  searchRowsQuery.value = '';
});

watch(
  () => drillActiveRows, 
  (newRows, oldRows) => {
    if (!newRows.length) {
      oldRows.forEach((oldRow) => {
        handleEnquiryDrill(findRowByDrillValue(oldRow), false);
      });
    }
  }
);

watch(() => isSmallScreen, (newValue) => {
  rowsChipsVisible.value = !newValue;
});

onMounted(() => {
  search.value = new Fuse(dataRows.value, {
    threshold: 0.1,
    keys: [
      {
        name: 'ydims',
        getFn: (items) => items.ydims[searchIndex.value].values
      }
    ]
  });
  if (!isSmallScreen.value) toggleDrillOption(true);
});
  
function applySort(key) {
  if (props.isSnapshot || key === null) return;

  let sortCol = defaultSort.column;
  let sortDir = defaultSort.direction;

  if (userSort.value.column === key) {
    sortDir = userSort.value.direction === REPORTS_SORT_ASCENDING_KEY ? 'default' : REPORTS_SORT_ASCENDING_KEY;

    if (sortDir === 'default') {
      userSort.value.column = null;
      userSort.value.direction = REPORTS_SORT_DESCENDING_KEY;

      sortCol = defaultSort.column;
      sortDir = defaultSort.direction;
    } else {
      userSort.value.column = key;
      userSort.value.direction = sortDir;

      sortCol = `DIM${userSort.value.column + 2}`;
    }
  } else {
    sortCol = `DIM${key + 2}`;
    sortDir = REPORTS_SORT_DESCENDING_KEY;
    userSort.value.column = key;
    userSort.value.direction = sortDir;
  }
  applyOffsetY(0);
  emit('sortOnYAxis', sortDir, sortCol);
}

function findRowByDrillValue(drillObject) {
  return dataRows.value.find((row) => row.id === drillObject.id);
}

function getChipValue(index) {
  return summary.value[index];
}

function handleEnquiryDrill(dataRow, checked) {
  if (!dataRow) return;
  dataRow.checked = checked;

  const [head1, head2] = dataRow?.ydims[0].values;

  emit('handleEnquiryDrill', {
    checked,
    id: `${head1},${head2}`,
    item: { value: head1, pair: head2 }
  });
}

function toggleChips() {
  rowsChipsVisible.value = !rowsChipsVisible.value;
}

function loadData(paginationDirection) {
  switch (paginationDirection) {
  case 'right':
    offsetX.value += requestParameters.value.limitX;
    break;
  case 'bottom':
    offsetY.value += requestParameters.value.limit;
    break;
  case 'left':
    offsetX.value -= requestParameters.value.limitX;
    break;
  case 'top':
    offsetY.value -= requestParameters.value.limit;
    break;
  default:
    break;
  }
  applyOffsetX(Math.max(0, offsetX.value));
  applyOffsetY(Math.max(0, offsetY.value));
  emit('setEnquiryData');
}

function getCustomerLink(id) {
  return `${crmBaseUrl}/${customersArea}/${id}`;
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

table {
  position: relative;

  thead th, 
  tbody th {
    text-align: left;
    padding: 0;
    left: 0;
    z-index: 3;
    height: initial;
    vertical-align: middle;
  }

  tbody td {
    vertical-align: middle;
  }

  thead {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 15;

    th {
      font-weight: var(--font-weight-semibold);
      vertical-align: middle;
      position: relative;
    }
  }

  td.zero {
    color: var(--colour-utility-error);
  }

  td,
  th {
    font-size: var(--font-size-body);
    color: var(--colour-utility-black);
    border: 0;
    min-width: initial;
    height: auto;
  }

  .dim-header {
    border-bottom: 1px solid var(--colour-panel-g-16);
    padding: 0 var(--spacing-1);
    top: 0;
    z-index: 4;

    &.search-header {
      min-width: 120px;
      .ydim-header-inner {
        grid-template-rows: 1fr 20px;
      }
    }
  }

  .ydim-header {
    background: var(--colour-panel-action);
    z-index: 4;
    text-align: center;
    width: 10%;

    &.only-dim {
      width: initial;
    }

    &:nth-of-type(2) {
      z-index: 8;
      box-shadow: 0 0 var(--border-radius-half) var(--shadow-spread) var(--shadow-colour);
    }

    .ydim-header-inner {
      text-decoration: underline;
      color: var(--colour-utility-action);
      text-align: center;
      display: inline-flex;  
      align-items: baseline;

      .heading {
        padding: var(--spacing-2) var(--spacing-1);
        &.span-2 {
          grid-column: 1 / 3;
        }
      }

      .search-container {
        grid-row: 2;
        grid-column: 1 / 3;
        z-index: 50;
        margin: 0;
        width: calc(100% - 20px);
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 300px;

        :deep(.form-group) {
          margin: 0;
        }

        .field {
          position: relative;
        }

        :deep(.svg-container.icon-search) {
          top: 50% !important;
          transform: translateY(-50%);
        }

        :deep(input.icon-left) {
          max-height: 40px;
          border-radius: var(--spacing-4);
          padding: var(--spacing-1) var(--spacing-2) var(--spacing-1)
            var(--spacing-5);
        }
      }
    }

    &:nth-of-type(1) {
      z-index: 5;
    }
  }

  .xdim-header {
    text-align: center;
    z-index: 3;
    min-width: 150px;

    .xdim-header-inner {
      display: grid;
      justify-content: center;
      align-items: center;
      justify-items: stretch;
      height: 100%;
      grid-template-rows: 1fr 20px;

      .heading {
        padding: var(--spacing-2) var(--spacing-1);
        font-weight: var(--font-weight-regular);
      }

      .chip {
        width: 100px;
        color: var(--colour-utility-black);
        background: var(--colour-panel-g-0);
        box-shadow: 0 0 var(--border-radius-half) var(--shadow-spread)
          var(--shadow-colour);
        font-size: var(--font-size-5);
        font-weight: var(--font-weight-medium);
        letter-spacing: 0;
        line-height: var(--spacing-3);
        padding: var(--spacing-1);
        border-radius: var(--spacing-6);
        align-self: flex-start;
        text-decoration: none;

        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, -50%);

        @media #{map-get($display-breakpoints, 'sm-and-up')} {
          width: 120px;
        }
      }
    }
  }

  .dim-row {
    padding: var(--spacing-3) var(--spacing-2) var(--spacing-1) var(--spacing-2);
    cursor: help;
    background: var(--colour-panel-g-2);
    position: relative;
    min-width: 150px;

    &:nth-of-type(1) {
      max-width: 250px;
    }

    &.only-dim {
      padding-right: var(--spacing-2);
    }

    &:nth-of-type(2) {
      .dim-row-inner {
        .bubble-wrap {
          grid-column: 1 / 3;
          padding-top: calc(var(--font-size-small) + 4px);
        }
      }
    }

    .dim-row-inner {
      display: grid;
      grid-template-columns: auto 1fr;
      box-sizing: content-box;

      @media #{map-get($display-breakpoints, 'md-and-up')} {
        max-width: 225px;
      }
    }

    .bubble-wrap {
      grid-column: 2;
      grid-row: 2;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      max-width: 200px;

      .bubble-title {
        font-size: var(--font-size-5);
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.link {
          font-size: var(--font-size-5);
          span {
            display: inline-block;
          }
        }
      }
    }

    .row-total {
      font-size: var(--font-size-small);
      font-weight: var(--font-weight-semibold);
      grid-column: 2 / 3;
      grid-row: 1;
      text-align: start;
    }

    .order-number {
      grid-column: 1;
      grid-row: 2 / 3;
      color: var(--colour-utility-base);
      text-align: right;
      font-size: var(--font-size-5);
      font-weight: var(--font-weight-regular);
      display: block;
    }

    .bubble-subtitle {
      grid-column: 2;
      align-self: start;
      margin-left: 0;
      color: var(--colour-utility-base);
      font-size: var(--font-size-small);
      line-height: var(--font-size-small);
      font-weight: var(--font-weight-regular);
    }

    p:not(.bubble-subtitle) {
      font-weight: var(--font-weight-semibold);
    }
  }

  .dim-value {
    text-align: center;
    &.zero {
      color: var(--colour-utility-error);
    }
  }

  .is-snapshot table {
    tr:first-of-type th {
      .chip-toggler {
        top: calc(50% + 12px);
      }
      .chip {
        margin-top: 12px;
      }
    }
    tr th {
      padding-right: 120px;
      padding-left: var(--spacing-2);

      &.chip-hidden {
        padding-right: var(--spacing-2);
      }

      .chip {
        left: unset;
        right: 0;
        top: 50%;
        transform: translate(-8px, -50%);
        text-align: center;
        margin-top: 0;
      }
    }
    .search-cell {
      padding: var(--spacing-2);
    }

    .chip-toggler {
      position: absolute;
      left: 132px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .positive {
    color: var(--colour-data-de-york-label);
  }

  .negative {
    color: var(--colour-data-mandy-label);
  }
}

.drill {
  background: var(--colour-panel-base);
  padding: var(--spacing-2) var(--spacing-1);
  position: absolute;
  left: 0;
  top: var(--spacing-2);
  bottom: 0;
  display: flex;
  align-items: flex-start;
  padding-top: var(--spacing-3);
  
  ~ .dim-row-inner {
    padding-left: var(--spacing-4);
  }
}
</style>
