<template>
  <div>
    <div
      :id="uniqueId"
      ref="containerRef"
      class="line-bar-chart-container"
    >
      <svg class="chart" />
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import * as d3 from 'd3';
import useCharts from '@/intelligence/composables/useCharts';

const props = defineProps({
// TODO: convert to array of elements data
// example:
//   return tableData.value.map(row => ({
//     line: row.average_customer_spend,
//     bar: row.purchase_share,
//     average: row.average_industry_spend,
//     name: row.description,
//   }));
  chartData: {
    type: Array,
    required: true,
  },
  elementsData: {
    type: Array,
    required: true,
  },
});

const containerRef = ref(null);
const { uniqueId, getChartVersion, handleChartResize } = useCharts({ containerRef, generateChart });

watch(() => props.chartData, () => {
  handleChartResize();
});

const getMaximumValue = () => {
  return Math.max(...props.chartData.map(el => el.line), ...props.chartData.map(el => el.bar), ...props.chartData.map(el => el.average));
};

const processChartParams = (type) => {
  return type === 'mobile'
    ? {
      svgWidth: containerRef.value?.clientWidth,
      svgHeight: props.elementsData?.length === 3 ? 500 : 450,
      margin: {
        top: 10,
        right: 0,
        bottom: props.elementsData?.length === 3 ? 290 : 240,
        left: 35,
      },
      bottomOffsetMonths: props.elementsData?.length === 3 ? 120 : 110,
    }
    : {
      svgWidth: containerRef.value?.clientWidth,
      svgHeight: props.elementsData?.length === 3 ? 550 : 510,
      margin: {
        top: 10,
        right: 0,
        bottom: props.elementsData?.length === 3 ? 260 : 220,
        left: 150,
      },
      bottomOffsetMonths: 95,
    };
};

function generateChart() {
  const currentParams = processChartParams(getChartVersion());
  const svgSelector = `#${uniqueId} svg`;
  // set parameters to container svg
  const svg = d3
    .select(svgSelector)
    .attr('width', currentParams.svgWidth)
    .attr('height', currentParams.svgHeight)
    .append('g')
    .attr('transform', `translate(${currentParams.margin.left},${currentParams.margin.top})`);

  //
  // DATA
  //
  const isMobile = getChartVersion() === 'mobile';
  const isDesktop = getChartVersion() === 'desktop';
  // standardOffset is basically a break between Y axis and first value
  const standardOffset = 16;
  // xValuePosition - it's half the length between ticks in X axis, minus standard offset
  const xValuePosition = Math.abs(currentParams.svgWidth / props.chartData.length / 2 - standardOffset);
  const bgLinesColor = '--colour-panel-g-8';

  //
  // UTILS
  //
  function setChipWidthPosition() {
    const textLength = d3.select(this.parentNode).select('text').node().getComputedTextLength() + 24;
    d3.select(this)
      .attr('width', textLength)
      .attr('transform', `translate(-${(textLength - standardOffset) / 2},1)`);
  }
  function adjustLeftMargin() {
    if (this.getComputedTextLength() + 40 > currentParams.margin.left) {
      currentParams.margin.left = this.getComputedTextLength() + 40;
      d3.select(`${svgSelector} g`).attr(
        'transform',
        `translate(${currentParams.margin.left},${currentParams.margin.top})`
      );
    }
  }

  //
  //  DRAWING
  //

  // X axis (with product group names)
  const x = d3
    .scaleBand()
    .domain(props.chartData.map((d, i) => i))
    .range([0, currentParams.svgWidth - currentParams.margin.left - currentParams.margin.right]);
  svg
    .append('g')
    .attr('class', 'chart-axis chart-axis-x')
    .attr('transform', `translate(${standardOffset / 2}, ${currentParams.svgHeight - currentParams.margin.top - currentParams.margin.bottom})`)
    .call(d3.axisBottom(x))
    .selectAll('text')
    .data(props.chartData)
    .text(d => (d.name.length <= 10 ? d.name : `${d.name.substring(0, 10)}...`))
    .attr(
      'transform',
      `translate(-${xValuePosition + standardOffset},${currentParams.margin.bottom - currentParams.bottomOffsetMonths - standardOffset})rotate(-90)`
    )
    .attr('dominant-baseline', 'middle')
    .attr('class', 'chart-label-x')
    .style('text-anchor', 'end');

  // Y axis
  const y = d3
    .scaleLinear()
    .domain([0, 1.1 * getMaximumValue()])
    .range([currentParams.svgHeight - currentParams.margin.top - currentParams.margin.bottom, 0]);
  const axisY = d3.axisLeft(y);
  svg.append('g').attr('class', 'chart-axis chart-axis-y').call(axisY).selectAll('text').attr('class', 'chart-label-y');

  // horizontal lines
  svg
    .selectAll('g.chart-axis-y g.tick line')
    .attr('x1', x(0))
    .attr('x2', x(props.chartData.length - 1) + 2 * standardOffset)
    .style('stroke-width', 1)
    .style('stroke', `var(${bgLinesColor})`)
    .style('fill', 'none');

  // vertical lines
  svg
    .selectAll('.chart-axis-x g.tick')
    .append('line')
    .attr('x1', -xValuePosition)
    .attr('y1', 0)
    .attr('x2', -xValuePosition)
    .attr('y2', currentParams.margin.bottom - currentParams.bottomOffsetMonths - 25)
    .attr('class', 'vertical-line')
    .style('stroke-width', 1)
    .style('stroke', `var(${bgLinesColor})`)
    .style('fill', 'none');

  props.elementsData.forEach(element => {
    let axisTranslate = `translate(-${xValuePosition}, ${currentParams.svgHeight - currentParams.margin.top - currentParams.margin.bottom + element.axisPosition})`,
      axisTextTranslate = `translate(${standardOffset / 2},0)`,
      axisTextAnchor = 'middle',
      axisTextDominant = '';

    if (isMobile) {
      axisTranslate = `translate(-${xValuePosition + standardOffset}, ${currentParams.svgHeight - currentParams.margin.top - currentParams.margin.bottom + element.axisPosition + 30})`;
      axisTextTranslate = 'translate(0,0)rotate(-90)';
      axisTextAnchor = 'start';
      axisTextDominant = 'middle';
    }

    // legend
    if (isDesktop) {
      // line between chips
      svg
        .append('line')
        .attr('x1', -20)
        .attr('y1', currentParams.svgHeight - currentParams.margin.top - currentParams.margin.bottom + element.legendPosition)
        .attr('x2', x(props.chartData.length - 1) + 24)
        .attr('y2', currentParams.svgHeight - currentParams.margin.top - currentParams.margin.bottom + element.legendPosition)
        .attr('stroke', `var(${element.color})`)
        .attr('stroke-width', 1)
        .style('fill', 'none');
      // legend text
      svg
        .append('text')
        .attr('x', -32)
        .attr('y', currentParams.svgHeight - currentParams.margin.top - currentParams.margin.bottom + element.legendPosition)
        .attr('class', 'legend-text')
        .text(element.title)
        .attr('alignment-baseline', 'middle')
        .attr('text-anchor', 'end')
        .each(adjustLeftMargin);

      // dot
      svg
        .append('circle')
        .attr('cx', -20)
        .attr('cy', currentParams.svgHeight - currentParams.margin.top - currentParams.margin.bottom + element.legendPosition)
        .attr('r', 5)
        .attr('stroke', `var(${element.color})`)
        .attr('fill', `var(${element.color})`);
    } else if (isMobile) {
      const xLegendPosition = currentParams.svgWidth / 2 + element.legendPositionMobileX,
        xLegendOffset = 35,
        yLegendOffset = 20 + element.legendPositionMobileY;
      // small lines near values
      svg
        .append('line')
        .attr('x1', 0)
        .attr('y1', currentParams.svgHeight - currentParams.margin.top - currentParams.margin.bottom + element.axisPosition + 5)
        .attr('x2', 0)
        .attr('y2', currentParams.svgHeight - currentParams.margin.top - currentParams.margin.bottom + element.axisPosition + 30)
        .attr('stroke', `var(${element.color})`)
        .attr('stroke-width', 4)
        .style('fill', 'none');
      // lines of the legend
      svg
        .append('line')
        .attr('x1', xLegendPosition - xLegendOffset)
        .attr('y1', currentParams.svgHeight - yLegendOffset)
        .attr('x2', xLegendPosition - xLegendOffset + 30)
        .attr('y2', currentParams.svgHeight - yLegendOffset)
        .attr('stroke', `var(${element.color})`)
        .attr('stroke-width', 4)
        .style('fill', 'none');
      // text of the legend
      svg
        .append('text')
        .attr('x', xLegendPosition)
        .attr('y', currentParams.svgHeight - yLegendOffset)
        .attr('class', `legend-text ${element.key === 'average' ? 'average-text' : ''}`)
        .text(element.title)
        .attr('alignment-baseline', 'middle')
        .style('text-anchor', 'start');
    }

    // X axis with value 1 and value 2
    svg
      .append('g')
      .attr('class', `chart-axis chart-axis-${element.key}`)
      .attr('transform', axisTranslate)
      .call(d3.axisBottom(x))
      .selectAll('text')
      .data(props.chartData)
      .text(d => {
        if (typeof d[element.key] === 'number' && d[element.key] != null) {
          if (d[element.key] % 1 === 0) return d[element.key];
          return d[element.key].toFixed(2);
        }
        return d[element.key];
      })
      .attr('transform', axisTextTranslate)
      .attr('class', 'chart-label-values')
      .attr('dominant-baseline', axisTextDominant)
      .style('text-anchor', axisTextAnchor);

    // chips for X values
    if (isDesktop) {
      svg
        .selectAll(`g.chart-axis-${element.key} g.tick`)
        .data(props.chartData)
        .insert('rect', 'text')
        .attr('class', 'chip-white')
        .attr('ry', 12)
        .attr('rx', 12)
        .attr('height', 24)
        .each(setChipWidthPosition);
    }

    // Bars and lines
    if (element.key === 'bar') {
      svg
        .selectAll('mybar')
        .data(props.chartData)
        .join('rect')
        .attr('x', (d, i) => x(i) + (isMobile ? 14 : 12))
        .attr('y', d => y(d[element.key]))
        .attr('rx', '4')
        .attr('ry', '4')
        .attr('width', isMobile ? 4 : 8)
        .attr('height', d => currentParams.svgHeight - currentParams.margin.top - currentParams.margin.bottom - y(d[element.key]))
        .attr('fill', `var(${element.color})`)
        .attr('class', 'bar');
    } else if (element.key === 'line' || element.key === 'average') {
      const linePath = d3
        .line()
        .x((d, i) => x(i) + standardOffset)
        .y(d => y(d[element.key]));
      svg
        .append('path')
        .datum(props.chartData)
        .attr('class', 'line')
        .attr('stroke', `var(${element.color})`)
        .attr('stroke-width', 2)
        .attr('fill', 'transparent')
        .attr('d', linePath);

      svg
        .selectAll('dot')
        .data(props.chartData)
        .enter()
        .append('circle')
        .attr('r', 3)
        .attr('cx', (d, i) => x(i) + standardOffset)
        .attr('cy', d => y(d[element.key]))
        .attr('stroke', `var(${element.color})`)
        .attr('stroke-width', 1.5)
        .attr('fill', `var(${element.color})`);
    }
  });

  // centering the chart
  const getChartOffset = elementWidth => (currentParams.svgWidth - elementWidth) / 2,
    chartYWidth = svg.select('.chart-axis-y')?.node()?.getBBox()?.width,
    chartOffset = Math.max(getChartOffset(chartYWidth), currentParams.margin.left);
  d3.select(`${svgSelector} g`)
    .attr('transform', `translate(${chartOffset},${currentParams.margin.top})`);
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
.line-bar-chart-container {
  width: 100%;
  position: relative;
  overflow-x: hidden;
  display: block;

  .chart {
    display: block;
    margin: auto;
  }
  .chart-label-x,
  .chart-label-values,
  .legend-text {
    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-small);
    font-family: EuclidCircularA;
  }

  .legend-text {
    font-size: var(--font-size-small);
  }

  .chart-axis-x,
  .chart-axis-line,
  .chart-axis-average,
  .chart-axis-bar {
    line {
      display: none;
    }
  }
  .chart-axis-x line.vertical-line {
    display: block;
  }

  .chart-label-values,
  .legend-text {
    font-weight: var(--font-weight-semibold);
  }

  .average-text {
    font-weight: var(--font-weight-medium);
  }

  rect.chip-white {
    fill: var(--colour-panel-g-0);
    stroke: var(--colour-panel-g-16);
    stroke-width: 1;
  }

  path.domain {
    display: none;
  }
}
</style>
