<template>
  <div class="entity-display">
    <div class="entity">
      <slot name="name">
        <a
          v-if="entityId"
          class="name"
          :href="getLink(entityId)"
          target="_blank"
        > 
          {{ entityName }}
        </a>
        <div
          v-else
          class="name"
        >
          {{ entityName }}
        </div>
      </slot>
      <slot name="info">
        <div
          v-if="entityInfo"
          class="info"
        >
          {{ entityInfo }}
        </div>
      </slot>
    </div>
    <div class="cta">
      <slot name="cta">
        <IconButton
          icon-name="dimensions"
          :icon-size="18"
          icon-color="var(--colour-utility-white)"
          variant="round"
          @click="emit('ctaClick', entityId)"
        />
      </slot>
    </div>
  </div>
</template>

<script setup>
import { baseUrl as crmBaseUrl, customersArea, prospectsArea } from '@/crm/router/urlBits';
import { IconButton } from '@sales-i/dsv3';

const emit = defineEmits(['ctaClick']);

const props = defineProps({
  entityId: {
    type: [String, Number],
    default: undefined
  },
  entityName: {
    type: String,
    default: ''
  },
  entityInfo: {
    type: String,
    default: undefined
  },
  entityType: {
    type: String,
    default: 'customer'
  }
});

// methods
function getLink(id) {
  return `${crmBaseUrl}/${props.entityType === 'customer' ? customersArea : prospectsArea}/${id}`;
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.entity-display {
  display: flex;
  flex-flow: row nowrap;

  .cta {
    justify-self: flex-end;
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
  }
  
  .info {
    font-weight: var(--font-weight-semibold);
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    flex-flow: column nowrap;
    .cta {
      flex-grow: initial;
      justify-content: flex-start;
    }
  }
}
</style>