<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    class="wrapper"
    :class="{ salesvsgaps: isSVG, matrix: isMatrix, variance: isVariance }"
  >
    <div class="container">
      <div
        class="enquiry-nav"
        :class="{ overlap: isFilterModalVisible, expanded: isNavVisible }"
      >
        <div
          class="buttons"
          :class="{ iframe, 
                    'raport-available': (!isBubbleSelectionScreen && isDataAvailable),
                    'is-nav-expanded': isNavVisible }"
        >
          <div v-if="!hideEnquiryButtons">
            <CustomButton
              v-if="
                (!hideDrill &&
                  !isBubbleSelectionScreen &&
                  !isDateFilterActive &&
                  !isSelectBubblesButtonActive &&
                  !isPeriodFilterActive &&
                  isDataAvailable) ||
                  (isGoingBackFromBreadcrumbs && !dataLoading)
              "
              class="drill-btn"
              action="submit"
              :label="t('Drill')"
              @click="toggleDrillHandler"
            />
            <CustomButton
              v-if="isDrillActive && isSelectBubblesButtonActive && isDataAvailable"
              class="select-btn"
              action="submit"
              :label="t('Next')"
              @click="toggleBubbleList"
            />
            <div :class="menuContainerClasses">
              <IconButton
                v-if="!hideDateFilter"
                class="button"
                :class="{
                  active: isDateFilterActive,
                  disabled: isDateButtonDisabled,
                }"
                icon-name="calendar"
                :icon-color="
                  (isDateFilterActive || isDateButtonDisabled)
                    ? 'var(--colour-utility-white)'
                    : 'var(--colour-utility-action)'
                "
                purpose="transparent"
                icon-only
                :icon-size="26"
                @click.stop="toggleDateFilter"
              />
              <IconButton
                v-if="isSVG"
                class="button"
                :class="{ active: isPeriodFilterActive }"
                icon-name="clock"
                :icon-color="isPeriodFilterActive ? 'var(--colour-utility-white)' : 'var(--colour-utility-action)'"
                purpose="transparent"
                icon-only
                :icon-size="26"
                @click.stop="togglePeriodFilter"
              />
              <IconButton
                v-if="isVariance"
                class="button"
                :class="{ active: isPercentValueActive }"
                icon-name="percent"
                :icon-color="isPercentValueActive ? 'var(--colour-utility-white)' : 'var(--colour-utility-action)'"
                purpose="transparent"
                :icon-size="26"
                @click.stop="togglePercentValue"
              />
              <EnquiryFilter
                v-if="!hideFilters"
                :report-type="reportType"
                @toggle-filter-modal="toggleFilterModal"
                @apply-filters="applyFilters"
              />
            </div>
            <EnquiryMeasure
              v-if="isMatrix || isSVG"
              :is-bubble-selection-screen="isBubbleSelectionScreen"
            />
            <CustomButton
              v-if="isMobileView"
              class="mobile-menu mobile-menu-toggle"
              :class="{open: isMobileMenuVisible}"
              icon-name="plus"
              icon-color="var(--colour-utility-white)"
              purpose="transparent" 
              icon-only
              round
              @click.stop="toggleMobileMenu"
            />
            <ToggleView v-if="isInteractionSpendSummary" />
            <IconButton
              v-if="isFullPicture && areTargetsEnabled"
              class="button target-button"
              :class="{ active: isTargetsSwitchActive }"
              icon-name="target"
              :icon-color="isTargetsSwitchActive ? 'var(--colour-utility-white)' : 'var(--colour-utility-action)'"
              purpose="transparent"
              icon-only
              :icon-size="36"
              @click.stop="toggleTargetsSwitch"
            />
            <slot name="buttons" />
          </div>
        </div>
        <div class="nav-controls">
          <div
            tabindex
            role="button"
            class="title"
            @click.stop="handleToggleNav"
            @keydown.space="handleToggleNav"
          >
            <h3 ref="pageTitle">
              {{ title }}
            </h3>
            <IconBase
              v-if="!disableNav"
              class="btn"
              :class="isNavVisible ? 'rotate' : ''"
              icon-name="chevron-down"
              icon-color="var(--colour-utility-action)"
              width="32"
              height="32"
            />
          </div>
          <template v-if="!isSnapshot">
            <BufferImage
              v-if="dateLoading"
              color="var(--colour-utility-black)"
              float="center"
              class="loading-spinner"
            />
            <div
              v-else
              class="subtitles"
            >
              <DateRangeDisplay
                v-if="hasTwoDateRanges || isAlertBuilderVariance"
                :subtitles="isAlertBuilderVariance ? alertBuilderDates : subtitles"
              />
              <p
                v-else
                v-html="subtitles[0]"
              />
              <p v-if="isSVG">
                {{ t('Selected period type') }}: <b>{{ period }}</b>
              </p>
            </div>
          </template>
        </div>
        <div class="data-info" />
        <MetaDateStamp v-if="isMetaDateStampActive" />
      </div>
    </div>

    <div
      v-if="isNavVisible"
      class="nav"
      :class="{ expanded: isNavVisible }"
    >
      <div class="container mobile">
        <Accordion
          v-for="section in navigationTabs"
          :key="section.query"
          :title="section.title"
          :is-opened="section.isOpened"
          accordion-size="small"
        >
          <template #content>
            <div class="slider">
              <div
                ref="slider_mobile"
                class="list"
              >
                <template v-for="(item, i) in getInputArea(section.query)">
                  <CardInsights
                    v-if="canShowReport(item.policy) && !item.disabled"
                    :key="i"
                    :class="section.query"
                    :title="item.title"
                    :description="item.description"
                    :link-text="item.linkText"
                    @open="goToReport(item)"
                  >
                    <template #info>
                      <div>
                        <h3>{{ item.title }}</h3>
                        <p>{{ item.description }}</p>
                      </div>
                    </template>
                  </CardInsights>
                </template>
              </div>
            </div>
          </template>
        </Accordion>
      </div>

      <div class="container desktop">
        <Tabs
          twoway
          :class="{ iframe }"
          :items="navigationTabs"
          :selected-index="activeAreaIndex"
          @nav-clicked="navClicked"
        />
        <div
          class="slider"
          :class="`slider--${activeArea}`"
        >
          <div 
            ref="slider_desktop"
            class="list"
          >
            <template 
              v-for="(item, index) in reportsFilteredByArea"
              :key="index"
            >
              <CardInsights
                :class="activeArea"
                :title="item.title"
                :description="item.description"
                :link-text="item.linkText"
                @open="goToReport(item)"
              />
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { BufferImage, IconButton, CustomButton, IconBase } from '@sales-i/dsv3';
import CardInsights from '@/dsv3/Card/CardInsights';
import { dates as datesUtils, t } from '@sales-i/utils';
import { useMq } from 'vue3-mq';
import {
  REPORT_AREA_CUSTOMER,
  REPORT_AREA_PRODUCT,
  REPORT_AREA_PEOPLE,
  REPORT_AREA_PERFORMANCE,
  REPORT_AREA_ENQUIRY,
  REPORT_AREA_ALERTS
} from '@/intelligence/store/data/areas';
import breakpoints from '@/shared/utils/breakpoints';
import { RELOAD_VIEW } from '@/shared/store/actionType';
import EnquiryFilter from '@/intelligence/components/EnquiryReport/EnquiryFilter/EnquiryFilter';
import Tabs from '@/shared/components/Tabs/Tabs';
import Accordion from '@/shared/components/Accordion/Accordion';
import EnquiryMeasure from '@/intelligence/components/EnquiryReport/EnquiryMeasure.vue';
import ToggleView from '@/intelligence/components/EnquiryReport/EnquiryNav/ToggleView/ToggleView.vue';
import MetaDateStamp from '@/intelligence/components/Shared/MetaDateStamp.vue';
import DateRangeDisplay from './DateRangeDisplay.vue';
import {
  TRENDS,
  VARIANCE,
  SALES_VS_GAPS,
  FULL_PICTURE,
  MATRIX,
  INTERACTION_SPEND_SUMMARY,
} from '@/intelligence/store/data/reportTypes';
import usePermissions from '@/shared/composables/usePermissions';
import {
  customers,
  intelligence_enquiries,
  alert_builder
} from '@/shared/store/data/policies';
import useReportPolicies from '@/shared/composables/useReportsPolicies';
import isRolldate from '@/intelligence/store/utils/isRolldate';
import { useHead } from '@unhead/vue';
// DEMO STUFF
import useJwt from '@/shared/composables/useJwt';
import { capitalize } from '@/shared/utils/strings';
import { navigateToUrl } from 'single-spa';
import { useRouter } from 'vue-router';
import useShared from '@/intelligence/composables/useShared';
import useCalendar from '@/intelligence/composables/useCalendar';
import useEnquiries from '@/intelligence/composables/useEnquiries';
import useLazyLoading from '@/intelligence/composables/useLazyLoading';
import useTargets from '@/intelligence/composables/useTargets';
import useSystem from '@/shared/composables/useSystem';

const { isNapa } = useJwt();
const mq = useMq();

const props = defineProps({
  reportType: {
    type: String,
    default: '',
  },
  dates: {
    type: Object,
    default: () => ({}),
  },
  activeAreaProp: {
    type: [String, Boolean],
    default: false,
  },
  isBubbleSelectionScreen: {
    type: Boolean,
    default: false,
  },
  isDateFilterActive: {
    type: Boolean,
    default: true,
  },
  isPeriodFilterActive: {
    type: Boolean,
    default: false,
  },
  isPercentValueActive: {
    type: Boolean,
    default: false,
  },
  hideEnquiryButtons: {
    type: Boolean,
    default: false,
  },
  hideFilters: {
    type: Boolean,
    default: false,
  },
  hideDrill: {
    type: Boolean,
    default: false,
  },
  hideDateFilter: {
    type: Boolean,
    default: false,
  },
  isSnapshot: {
    type: Boolean,
    default: false,
  },
  isMetaDateStampActive: {
    type: Boolean,
    default: true,
  },
  disableNav: {
    type: Boolean,
    default: false,
  },
  navTitle: {
    type: String,
    default: '',
  },
  isGoingBackFromBreadcrumbs: {
    type: Boolean,
    default: false,
  },
  alertBuilderDates: {
    type: Array,
    default: () => [],
  },
  isAlertBuilderVariance: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['toggleDateFilter', 'togglePeriodFilter', 'applyFilters', 'changeReport', 'togglePercentValue']);
  
const isNavVisible = ref(false);
const isFilterModalVisible = ref(false);
const activeArea = ref('');
const fetchedDates = ref('');
const isThisNapa = ref(false);
const isMobileMenuVisible = ref(false);
  
const store = useStore();
const vrouter = useRouter();

//refs
const slider_mobile = ref(null);
const slider_desktop = ref(null);

const { 
  isDrillActive,
  getInputArea, getInputData, getReportData, 
  toggleDrillOption, toggleSelectBubblesPopup, 
  disableSelectDimensionsPopup, isSelectBubblesButtonActive } = useShared({ store });
const { 
  hasAccess, hasAccessToPerformanceInsights, 
  hasAccessToProductInsights, 
  hasAccessToCustomerInsights } = usePermissions({ store });
const reportsPolicies = useReportPolicies();
const { dateLoading, dataLoading, fetchDates } = useCalendar({ store });
const { period } = useEnquiries({ store });
const { rows: loadedRows } = useLazyLoading({ store });
const { isTargetsSwitchActive, areTargetsEnabled, toggleTargetsSwitch } = useTargets({ store });
const { iframe, isDemo } = useSystem({ store });

const isMobileView = computed(() => breakpoints.smAndDown.includes(mq.current));

const permissions = computed(() => store.state.pbac.permissionsGranted);
const canAccessCustomers = computed(() => permissions.value[customers]?.read && hasAccessToCustomerInsights.value);

const title = computed(() => props.navTitle || (inputData.value ? inputData.value.title : ''));

const inputData = computed(() => getInputData(props.reportType));

// TODO: we should refactor this to avoid forcing app reload
const reloadView = (params) => store.dispatch(`reloadView/${RELOAD_VIEW}`, params);

const reportsFilteredByArea = computed(() => {
  // If the current area is 'people', return the performance data
  if (activeArea.value === REPORT_AREA_PEOPLE) {
    return getInputArea(REPORT_AREA_PERFORMANCE).filter(item => isThisNapa.value || !item.isDemo);
  }
  let arr = getInputArea(activeArea.value)
    .filter(item => !item.disabled && (isThisNapa.value || !item.isDemo));
  // TODO: Unclear code with arr[0]
  if (arr[0]?.area === REPORT_AREA_ENQUIRY && hasAccess(intelligence_enquiries)) {
    arr = arr.filter(item => 
      [FULL_PICTURE, SALES_VS_GAPS, MATRIX, SALES_VS_GAPS,VARIANCE].includes(item.urlCode) ||
          (item.urlCode === TRENDS && !iframe.value));
  }
  return arr.filter(item => canShowReport(item.policy) && !item.disabled);
});

const navigationTabs = computed(() => {
  let arr = [];
  if (hasAccessToPerformanceInsights.value && !iframe.value) {
    const query = isDemo.value ? REPORT_AREA_PEOPLE : REPORT_AREA_PERFORMANCE;
    arr.push({
      title: t(capitalize(isDemo.value ? REPORT_AREA_PEOPLE : REPORT_AREA_PERFORMANCE)),
      query,
      isOpened: query === activeArea.value,
    });
  }
  if (hasAccessToProductInsights.value && !iframe.value) {
    arr.push({
      title: t(capitalize(REPORT_AREA_PRODUCT)),
      query: REPORT_AREA_PRODUCT,
      isOpened: REPORT_AREA_PRODUCT === activeArea.value,
    });
  }
  if (canAccessCustomers.value && hasAccessToCustomerInsights.value && !iframe.value) {
    arr.push({
      title: t(capitalize(REPORT_AREA_CUSTOMER)),
      query: REPORT_AREA_CUSTOMER,
      isOpened: REPORT_AREA_CUSTOMER === activeArea.value,
    });
  }
  if (permissions.value[intelligence_enquiries] && permissions.value[intelligence_enquiries].read) {
    arr.push({
      title: t(capitalize(REPORT_AREA_ENQUIRY)),
      query: REPORT_AREA_ENQUIRY,
      isOpened: REPORT_AREA_ENQUIRY === activeArea.value,
    });
  }
  if (permissions.value[alert_builder] && permissions.value[alert_builder].read) {
    arr.push({
      title: t(capitalize(REPORT_AREA_ALERTS)),
      query: REPORT_AREA_ALERTS,
      isOpened: REPORT_AREA_ALERTS === activeArea.value,
    });
  }
  return arr;
});

const activeAreaIndex = computed(() => {
  const tabsAreas = navigationTabs.value.map(tab => tab.query);
  // If the current activeAreaProp is 'performance' and isDemo is true, return the index of the 'people' section
  if (activeArea.value === REPORT_AREA_PERFORMANCE && isDemo.value) {
    return tabsAreas.indexOf(REPORT_AREA_PEOPLE);
  }
  return tabsAreas.indexOf(activeArea.value);
});

const isDateButtonDisabled = computed(() => !subtitles.value.length);
const isSVG = computed(() => props.reportType === SALES_VS_GAPS);
const hasTwoDateRanges = computed(() => inputData.value && inputData.value.dateRangeNumber === 2 || activeArea.value === REPORT_AREA_ALERTS);
const isMatrix = computed(() => props.reportType === MATRIX);
const isFullPicture = computed(() => props.reportType === FULL_PICTURE);
const isVariance = computed(() => props.reportType === VARIANCE);
const isInteractionSpendSummary = computed(() => props.reportType === INTERACTION_SPEND_SUMMARY);

const subtitles = computed(() => {
  if (!fetchedDates.value) return '';
  const { date_from, date_to, date_from2, date_to2 } = isRolldate(props.dates) ? fetchedDates.value : props.dates;
  if (!date_from || !date_to) return '';
  const format = isMobileView.value ? datesUtils.format : datesUtils.formatWithOrdinals;
  const subtitles = [`${format(date_from)} - ${format(date_to)}`];
  if (hasTwoDateRanges.value && date_from2 && date_to2)
    subtitles.push(`${format(date_from2)} - ${format(date_to2)}`);
  return subtitles;
});

const isDataAvailable = computed(() => {
  if (isMatrix.value || isSVG.value || isFullPicture.value || isVariance.value)
    return getReportData()?.rows?.length;
  // TODO: functions canntot be used in computed
  disableSelectDimensionsPopup();
  return loadedRows.value?.length;
});

const menuContainerClasses = computed(() => {
  return {
    'mobile-menu-container': isMobileMenuVisible.value && isMobileView.value,
    'desktop-menu-container': !isMobileView.value,
    'hidden': !isMobileMenuVisible.value && isMobileView.value,
  };
});

watch(() => props.dates,
  async (newDates) => {
    fetchedDates.value = await fetchDates(newDates);
  },
  { immediate: true }
);

watch(() => title.value, () => {
  useHead({
    title: title.value,
  });
});

watch(() => props.isGoingBackFromBreadcrumbs,
  (newVal) => {
    if (newVal) toggleDrillOption();
  }
);
  
onMounted(async () => {
  activeArea.value = props.activeAreaProp;
  useHead({ title: title.value });
  isThisNapa.value = await isNapa();
});

function canShowReport(policy) {
  if (Array.isArray(policy)) {
    let showReport = true;
    policy.forEach(policy => {
      if (!reportsPolicies.permissions.some(p => p[policy])) {
        showReport = false;
      }
    });
    return showReport;
  }
  return reportsPolicies?.permissions.some(p => policy === undefined || p[policy]);
}

function toggleDateFilter() {
  if (isDateButtonDisabled.value) return;
  emit('toggleDateFilter');
}

function togglePeriodFilter() {
  emit('togglePeriodFilter');
}

function handleToggleNav() {
  if (props.disableNav) return;
  isNavVisible.value = !isNavVisible.value;
}

function togglePercentValue() {
  emit('togglePercentValue');
}

function toggleBubbleList() {
  toggleSelectBubblesPopup();
}

function navClicked(data) {
  activeArea.value = data.item.query;
  if (isMobileView.value) 
    slider_mobile.value.scrollTo({ left: 0 });
  else 
    slider_desktop.value.scrollTo({ left: 0 });
}

async function goToReport(item) {
  if (iframe.value) {
    emit('changeReport', item.urlCode);
    isNavVisible.value = false;
    return;
  }
  let { href } = vrouter.resolve({ name: item.urlCode });
  navigateToUrl(href);
  isNavVisible.value = false;
  if (item.urlCode === props.reportType) {
    reloadView();
  }
}

function toggleFilterModal(value) {
  isFilterModalVisible.value = value;
}

function applyFilters() {
  emit('applyFilters');
}

function toggleMobileMenu() {
  isMobileMenuVisible.value = !isMobileMenuVisible.value;
}

function toggleDrillHandler() {
  toggleDrillOption();
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.wrapper {
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    position: sticky;
    top: 80px;
    z-index: $headerZIndex;
  }
  &.salesvsgaps .enquiry-nav .subtitles,
  &.variance .enquiry-nav .subtitles {
    flex-direction: column;
  }
}
h3 {
  font-weight: var(--font-weight-semibold);
}

.enquiry-nav {
  padding: var(--spacing-2);
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  transition: all 0.5s ease-in-out;
  background-color: var(--colour-panel-action);
  border-radius: var(--border-radius-1);
  min-height: 72px;
  margin: var(--spacing-2);
  position: relative;

  &.overlap {
    .buttons {
      z-index: 102;
    }
  }

  &.expanded {
    background-color: var(--colour-utility-white);

    .nav-controls .title h3 {
      color: var(--colour-utility-black);
      font-weight: var(--font-weight-regular);
    }
  }

  .buttons,
  .data-info {
    min-width: 20%;
  }

  .buttons {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    justify-content: flex-end;
    padding: var(--spacing-2);
    display: flex;
    gap: var(--spacing-1);
    background: var(--colour-panel-action);
    z-index: 16;
    height: 72px;

    &.iframe {
      z-index: 100;

      &.raport-available {
        top: 680px;
      }

      &.is-nav-expanded {
        bottom: 0;
        top: initial;
      }
    }

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      position: absolute;
      left: var(--spacing-2);
      right: auto;
      top: 50%;
      bottom: 50%;
      margin-right: auto;
      justify-content: flex-start;
      align-content: center;
      align-items: center;
      background: transparent;
      padding: 0;
      height: auto;

      &.iframe.raport-available {
        top: 50%;
        bottom: 50%;
      }
    }

    & > *,
    .desktop-menu-container {
      display: flex;
      gap: var(--spacing-1);
    }

    & > * {
      width: 100%;
    }
  }

  .data-info {
    display: none;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      display: inline;
      margin-left: auto;
      font-size: var(--font-size-small);
      text-align: right;
    }
  }

  .icon-button.button {
    box-shadow: 0 0 4px 1px var(--colour-panel-g-16);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--colour-utility-white);

    &.active {
      background: var(--colour-utility-black);
    }

    &.disabled {
      background: var(--colour-panel-g-32);
      cursor: not-allowed;
    }
  }

  .nav-controls {
    text-align: center;

    .title {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding-left: var(--spacing-1);
      align-items: center;
      align-content: center;
      cursor: pointer;

      @media #{map-get($display-breakpoints, 'lg-and-up')} {
        max-width: calc(52vw);
      }

      @media #{map-get($display-breakpoints, 'xl-and-up')} {
        max-width: calc(64vw);
      }

      @media #{map-get($display-breakpoints, 'xxl-only')} {
        max-width: calc(68vw);
      }

      h3 {
        color: var(--colour-utility-action);
      }
    }

    .subtitles,
    .subtitles > p {
      font-size: var(--font-size-small);
      line-height: var(--spacing-2);
      flex-basis: 100%;
    }

    .subtitles {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media #{map-get($display-breakpoints, 'sm-and-up')} {
        flex-direction: row;
        align-items: center;
      }
    }

    .btn {
      transition: transform 0.5s;

      &.rotate {
        transform: rotate(180deg);
      }
    }
  }

  &:deep(.button-container) {
    // temp/demo TODO: if no title for IconButton than margin should not be there. Solution: move margin from div to span in component
    margin-left: 0;
  }

  .target-button :deep(.button-container) {
    width: var(--spacing-5);
    height: var(--spacing-5);
  }
}

.nav {
  .container {
    padding: 0;

    &.mobile {
      padding: 0 var(--spacing-2);

      @media #{map-get($display-breakpoints, 'md-and-up')} {
        display: none;
      }
    }

    &.desktop {
      display: none;

      @media #{map-get($display-breakpoints, 'md-and-up')} {
        display: block;
      }
    }
    &:deep(.tabs.iframe) {
      display: none;
    }
  }

  .report-card {
    width: var(--card-width);
    min-height: 115px;
  }

  .slider {
    margin-bottom: var(--spacing-3);
    overflow: auto;
  }

  &.slider--performance .list {
    background-color: var(--colour-panel-performance);
  }

  &.slider--products .list {
    background-color: var(--colour-panel-product);
  }

  &.slider--customer .list {
    background-color: var(--colour-panel-customer);
  }

  &:deep(.slide-group__content) {
    justify-content: center;
  }

  &:deep(.tabs) {
    margin-bottom: 0;
  }

  &.expanded {
    margin-top: -20px;
    background-color: var(--colour-utility-white);
  }
}

.list {
  overflow-x: overlay;
  background: var(--colour-panel-alert-base);
  padding: var(--spacing-2);
  display: grid;
  gap: var(--spacing-2);
  min-width: 100%;
  grid-auto-flow: column;
  justify-content: start;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding: var(--spacing-4);
  }

  &:deep(.crm-card .links button) {
    font-size: var(--font-size-small);

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      font-size: var(--font-size-5);
    }
  }
}

p {
  font-size: var(--font-size-small);
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    font-size: var(--font-size-4);
  }
}

:deep(.c-card) {
  position: relative;
  padding: var(--spacing-2);
  width: 284px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding: var(--spacing-2);
    width: 388px;
  }

  .card-info {
    margin-top: 0;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      margin-top: var(--flow-space);
    }
  }

  .c-card--footer {
    position: absolute;
    bottom: var(--spacing-2);
    margin: 0;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      bottom: var(--spacing-3);
    }
  }
}

.c-card--body {
  margin-bottom: var(--spacing-4);
}

:deep(header) {
  margin-bottom: var(--spacing-1);
}

.buttons .drill-btn,
.select-btn {
  padding: var(--spacing-1) var(--spacing-2);
  bottom: var(--spacing-2);
  max-width: 70px;
  width: auto;
  left: 0;
  right: 0;
  margin: 0 auto;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    display: none;
  }
}

.wrapper.matrix,
.wrapper.salesvsgaps {
  .drill-btn,
  .select-btn {
    padding: var(--spacing-1);
    max-width: 70px;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      padding: var(--spacing-1) var(--spacing-2);
      max-width: 100px;
    }
  }
}
.wrapper.salesvsgaps {
  .drill-btn,
  .select-btn {
    max-width: 50px;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      max-width: 100px;
    }
  }
}

.loading-spinner {
  width: 16px;
  height: 16px;
}

.mobile-menu-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 80px;
  right: var(--spacing-2);
  gap: var(--spacing-2);
}

.mobile-menu.mobile-menu-toggle {
  background-color: var(--colour-utility-action);
  transition: transform 0.5s ease-in-out;
  width: 40px;
  height: 40px;

  &.open {
    transform: rotate(45deg);
    background-color: var(--colour-utility-black);
  }
}
</style>
