<template>
  <div>
    <Tabs
      :items="navigationTabs"
      :selected-index="activeAreaIndex"
      @nav-clicked="navClicked"
    />
    <InsightSignPost
      :section="activeArea"
      :heading="`${capitalize(activeArea)} ${t('Insights')}`"
      :description="t('Analyse sales performance and results.')"
      :icon-name="iconName"
      :insight-links="itemsFilteredByType"
    />
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { t } from '@sales-i/utils';
import InsightSignPost from '@/crm/views/Main/Insights/InsightSignPost.vue';
import Tabs from '@/shared/components/Tabs/Tabs';
import { SET_ACTIVE_AREA } from '@/shared/store/actionType';
import {CLEAR_ENQUIRY_FILTERS } from '@/intelligence/store/actionType';
import {
  REPORT_AREAS,
  REPORT_AREA_PRODUCT,
  REPORT_AREA_CUSTOMER,
  REPORT_AREA_PERFORMANCE,
  REPORT_AREA_PEOPLE
} from '@/intelligence/store/data/areas';
import {
  customers,
} from '@/shared/store/data/policies';
import usePermissions from '@/shared/composables/usePermissions';
import { useHead } from '@unhead/vue';
// DEMO STUFF
import useJwt from '@/shared/composables/useJwt';
import { capitalize } from '@/shared/utils/strings';
import { baseUrl, insightsScope } from '@/intelligence/router/urlBits';
import { navigateToUrl } from 'single-spa';
import useShared from '@/intelligence/composables/useShared';
import useSystem from '@/shared/composables/useSystem';
const { isNapa } = useJwt();

const store = useStore();
const vroute = useRoute();
const isNapaVal = ref(false);

const {
  hasAccessToPerformanceInsights,
  hasAccessToProductInsights,
  hasAccessToCustomerInsights
} = usePermissions();
const { isDemo } = useSystem({ store });

const activeArea = ref(capitalize(REPORT_AREA_PERFORMANCE));
const insight = ref(REPORT_AREA_PERFORMANCE);

const { getInputArea } = useShared({ store });

const { hasAccess } = usePermissions();
const canAccessCustomers = computed(() => hasAccess(customers));

// If the current area is 'people', return the performance data
const itemsFilteredByType = computed(() => 
  getInputArea(activeArea.value === REPORT_AREA_PEOPLE 
    ? REPORT_AREA_PERFORMANCE 
    : activeArea.value)
);

const navigationTabs = computed(() => {
  let arr = [];
  if (hasAccessToPerformanceInsights.value) {
    arr.push({
      title: isDemo.value ? t(capitalize(REPORT_AREA_PEOPLE)) : t(capitalize(REPORT_AREA_PERFORMANCE)),
      query: isDemo.value ? REPORT_AREA_PEOPLE : REPORT_AREA_PERFORMANCE,
    });
  }
  if (hasAccessToProductInsights.value) {
    arr.push({
      title: t(capitalize(REPORT_AREA_PRODUCT)),
      query: REPORT_AREA_PRODUCT
    });
  }
  if (hasAccessToCustomerInsights.value && canAccessCustomers.value) {
    arr.push({
      title: t(capitalize(REPORT_AREA_CUSTOMER)),
      query: REPORT_AREA_CUSTOMER,
    });
  }
  return arr;
});

const reportAreas = computed(() => {
  // If it's the demo account, remove the performance report
  if (isDemo.value) {
    return REPORT_AREAS.filter(item => item !== REPORT_AREA_PERFORMANCE);
  } else {
    return navigationTabs.value.map(item => item.query);
  }
});

const activeAreaIndex = computed(() => reportAreas.value.indexOf(activeArea.value));
const iconName = computed(() => {
  switch(activeArea.value) {
  case REPORT_AREA_PEOPLE:
  case REPORT_AREA_PERFORMANCE:
    return 'dimensions';
  case REPORT_AREA_PRODUCT:
    return 'product';
  case REPORT_AREA_CUSTOMER:
    return 'user-circle';
  default:
    return '';
  }
});

watch(() => vroute.params, (to) => setFilters(to));

onMounted(async() => {
  window.scrollTo(0, 0);
  setActiveArea();
  setFilters(vroute.params);
  useHead({ title: capitalize(activeArea.value) });
  isNapaVal.value = await isNapa();
});

const setArea = () => store.dispatch(`reportsModal/${SET_ACTIVE_AREA}`);
const clearEnquiryFilters = () => store.dispatch(`intelligence/enquiry/${CLEAR_ENQUIRY_FILTERS}`);

function setFilters(params) {
  insight.value = params.insight || REPORT_AREA_PERFORMANCE.toLowerCase();
  activeArea.value = params.insight ||  REPORT_AREA_PERFORMANCE;
}

function navClicked(data) {
  setArea(data.item.title);
  navigateToUrl(`${baseUrl}/${insightsScope}/${data.item.query}`);
  useHead({ title: capitalize(data.item.title) });
  clearEnquiryFilters();
}

function setActiveArea() {
  if (!activeArea.value) {
    const reportsViews = [REPORT_AREA_PEOPLE, REPORT_AREA_PERFORMANCE, REPORT_AREA_PRODUCT, REPORT_AREA_CUSTOMER];
    const routePath = vroute.path;
    let areaUrl = routePath.split('/');
    if (routePath === '/' || areaUrl.length <= 2 || areaUrl[1] !== 'intelligence') {
      setArea(REPORT_AREA_PERFORMANCE);
      return;
    }
    areaUrl = areaUrl[2];
    areaUrl = areaUrl.charAt(0).toUpperCase() + areaUrl.slice(1);
    setArea(
      reportsViews.includes(areaUrl) ? areaUrl : REPORT_AREA_PERFORMANCE
    );
  }
}
</script>

<style lang="scss" scoped>
  .tabs {
    border-bottom: none;
    display: grid;
    margin-bottom: 0;
    place-content: center;
  }
</style>
