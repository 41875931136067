import {
  // Products
  ADD_MANY_PRODUCTS_TO_QUERY,
  EDIT_PRODUCT_IN_FILTER,
  ADD_PRODUCT_TO_QUERY,
  CLEAR_PRODUCTS,
  CLEAR_BUYING_PRODUCTS,
  CLEAR_NOT_BUYING_PRODUCTS,
  REMOVE_PRODUCT_FROM_QUERY,
  REPLACE_PRODUCTS_IN_QUERY,
  CHANGE_PRODUCT_STATUS,
  UPDATE_PRODUCT_FILTER,
  UPDATE_PRODUCT_FILTER_IN_QUERY,

  REMOVE_COMMUNITY_FROM_QUERY,

  // Sales
  ADD_SALES_FILTER_TO_QUERY,
  REMOVE_SALES_FILTER_FROM_QUERY,
  UPDATE_SALES_FILTER_IN_QUERY,
  REMOVE_ALL_SALES_FILTER_FROM_QUERY,
  GET_SALES_FILTER_OPTIONS,

  // Customers
  ADD_CUSTOMER_FILTER_TO_QUERY,
  REMOVE_CUSTOMER_FILTER_FROM_QUERY,
  UPDATE_CUSTOMER_FILTER_IN_QUERY,
  REMOVE_ALL_CUSTOMER_FILTER_FROM_QUERY,

  UPDATE_CUSTOMER_FILTER_TYPE,
  REMOVE_ALL_FILTERS_FROM_QUERY,

  ADD_BUBBLE,
  REMOVE_BUBBLE,

  ADD_FILTER_ENTITY,
  UPDATE_FILTER_ENTITY,
  REMOVE_FILTER_ENTITY,
  QUERY_BUILDER_VARIANCE_OUTPUT,
} from '@/intelligence/store/actionType';

import { httpRequest, t } from '@sales-i/utils';
import setRequestParam from '@/intelligence/store/utils/setRequestParam';
import { productSearchScope } from '@/intelligence/router/urlBits';

// initial state
const state = () => ({
  bubbles: [],
  customerFilters: {
    id: 1,
    filter_type: 'CUSTOMER',
    filterClauses: [],
  },
  products: [],
  salesFilters: [],
  filterEntitiesArray: [],
  filters: {},
  varianceOutput: false,
});

// getters
const getters = {
  getBubbles: (state) => {
    return state.bubbles;
  },
  getProductsWithvalueFilter: (state) => {
    const valuedProducts = [];
    let n = 0;
    for (n; n < state.products.length; n++) {
      const _ref = state.products[n];
      if (
        _ref.status === 'include' &&
        'type' in _ref.valueFilter && 
        'operator' in _ref.valueFilter && 
        'value' in _ref.valueFilter && 
        _ref.valueFilter.type !== '' && 
        _ref.valueFilter.operator !== '' && 
        _ref.valueFilter.value !== ''
      ) {
        valuedProducts.push(_ref);
      }
    }

    return valuedProducts;
  },
  getCommunityProducts: (state) => (communityCode) => {
    return [...state.products]
      .filter(o => o.community_code === communityCode);
  },
  getSalesFilterOptions: (state) => {
    // Map the returned 'label' value to 'type' for each filter option:
    const generatedValues = Object.keys(state.filters).map(key => {
      return {
        text: state.filters[key].label,
        // Add 'csf_' to the start of the value otherwise the API will not recognise the field
        value: `csf_${state.filters[key].analysis_field}`,
        type: 'text'
      };
    });

    const staticValues = [
      {
        text: t('Sales value'),
        value: 'sales_value',
        type: 'number',
      },
      {
        text: t('Profit'),
        value: 'profit',
        type: 'number',
      },
      {
        text: t('Gross profit'),
        value: 'gross_profit',
        type: 'number',
      },
      {
        text: t('Quantity'),
        value: 'quantity',
        type: 'number',
      },
      {
        text: t('Avg. Price'),
        value: 'average_price',
        type: 'number',
      },
      {
        text: t('Cost'),
        value: 'cost',
        type: 'number',
      },
      {
        text: t('Count'),
        value: 'count',
        type: 'number',
      },
    ];
    
    return [
      ...staticValues,
      ...generatedValues,
    ];
  },
  getSalesFilters: (state) => {
    return [...state.salesFilters];
  },
  getCrmFilters: (state) => {
    return [...state.customerFilters.filterClauses];
  },
  getfilterEntitiesArray: (state) => {
    return [...state.filterEntitiesArray];
  },
  getSelectedCommunities: (state) => {
    let communityCodes = [];
    return [...state.products]
      .filter(o => {
        if (communityCodes.indexOf(o.community_code) !== -1) {
          return false;
        }

        communityCodes.push(o.community_code);
        return o.community_code !== productSearchScope;
      });
  },
  getAndProducts: (state) => {
    // Get all item in products that have a status of 'include' AND contain a product_code
    return [...state.products].filter(o => o.operator === 'and' && o.product_name !== undefined);
  },
  getOrProducts: (state) => {
    // Get all item in products that have a status of 'include' AND contain a product_code
    return [...state.products].filter(o => o.operator === 'or' && o.product_name !== undefined);
  },
  getBuyingProducts: (state) => {
    // Get all item in products that have a status of 'include' AND contain a product_code
    return [...state.products].filter(o => o.status === 'include' && o.product_name !== undefined);
  },
  getNotBuyingProducts: (state) => {
    return [...state.products].filter(o => o.status === 'exclude' && o.product_name !== undefined);
  },
  getBuyingProductGroups: (state) => {
    // Get all items in products that have a status of 'include' and contain a key including 'prod_group' and add them to the filter
    return [...state.products].filter(o => o.status === 'include' && Object.keys(o).some(key => key.includes('prod_group')));
  },
  getNotBuyingProductGroups: (state) => {
    return [...state.products].filter(o => o.status === 'exclude' && Object.keys(o).some(key => key.includes('prod_group')));
  },
  getAllProducts: (state) => {
    return [...state.products];
  },
  valueFilterOptions() {
    return [
      {
        value: '',
        text: t('Please Select')
      },
      {
        value: 'invoice_value',
        text: t('Invoice Value'),
      },
      {
        value: 'quantity',
        text: t('Quantity'),
      },
    ];
  },
};

// mutations
const mutations = {
  // Products
  [REPLACE_PRODUCTS_IN_QUERY]: (state, products) => {
    let n = 0;
    for (n; n < products.length; n++) {
      const product = products[n];
      const idx = state.products.findIndex(o => o.id === product.id);

      if (idx !== -1) {
        // Replace the current products with the new 'items' array
        state.products[idx].items = product.items;
        state.products[idx].status = product.status;
        state.products[idx].operator = product.operator;
        
        // Update the 'level' property of the product if the 'level' property is present
        if (Object.keys(product).some(key => key.includes('level'))) {
          state.products[idx].level = product.level;
        }
      }
    }
  },
  [ADD_MANY_PRODUCTS_TO_QUERY]: (state, products) => {
    const statusProducts = products.map(p => {
      return {
        ...p,
        status: 'include',
        valueFilter: {},
      };
    });
    state.products.push(...statusProducts);
  },
  [ADD_PRODUCT_TO_QUERY]: (state, product) => {
    const getProdGroupLevelIds = (item) => {
      return [
        item.prod_group_level_1 ? item.prod_group_level_1 : '',
        item.prod_group_level_2 ? `|~~|${item.prod_group_level_2}` : '',
        item.prod_group_level_3 ? `|~~|${item.prod_group_level_3}` : '',
        item.prod_group_level_4 ? `|~~|${item.prod_group_level_4}` : '',
        item.prod_group_level_5 ? `|~~|${item.prod_group_level_5}` : '',
      ].join('');
    };

    if (state.products.findIndex(o => o.id === product.id) === -1) {
      if ('status' in product === false) {
        product.status = 'include';
      }
      // add a 'product_code' property to the product group using getProdGroupLevelIds
      if (Object.keys(product).some(key => key.includes('prod_group'))) {
        product.product_code = getProdGroupLevelIds(product);
      }

      product.valueFilter = {};
      state.products.push(product);
    }
  },
  [EDIT_PRODUCT_IN_FILTER]: (state, products) => {
    state.products = [...products];
  },
  [REMOVE_PRODUCT_FROM_QUERY]: (state, product) => {
    let products = [];
    let n = 0;
    for (n; n < state.products.length; n++) {
      const _ref = state.products[n];
      if (_ref.id !== product.id) {
        products.push(_ref);
      }
    }
    state.products = products;
  },
  [CHANGE_PRODUCT_STATUS]: (state, parameters) => {
    const [product, status] = parameters;
    let n = 0;
    for (n; n < state.products.length; n++) {
      const _ref = state.products[n];
      if (_ref.product_code === product.product_code) {
        state.products[n].status = status;
        return;
      }
    }
  },
  [CLEAR_PRODUCTS]: (state) => {
    state.products = [];
    state.products.loaded = false;
    state.products.loading = true;
    state.products.errors = undefined;
  },
  [CLEAR_BUYING_PRODUCTS]: (state) => {
    state.products = state.products.filter(o => o.status !== 'include');
  },
  [CLEAR_NOT_BUYING_PRODUCTS]: (state) => {
    state.products = state.products.filter(o => o.status !== 'exclude');
  },
  [REMOVE_COMMUNITY_FROM_QUERY]: (state, community) => {
    let products = [];
    let n = 0;
    for (n; n < state.products.length; n++) {
      const _ref = state.products[n];
      if (_ref.community_code !== community.community_code) {
        products.push(_ref);
      }
    }
    state.products = products;
  },
  [UPDATE_PRODUCT_FILTER]: (state, parameters) => {
    const [product, field, value] = parameters;

    let n = 0;
    for (n; n < state.products.length; n++) {
      const _ref = state.products[n];
      if (_ref.product_code === product.product_code) {
        state.products[n].valueFilter[field] = value;
        return;
      }
    }
  },

  [UPDATE_PRODUCT_FILTER_IN_QUERY]: (state, filter) => {
    const idx = state.products.findIndex(o => o.id === filter.id);

    if (idx !== -1) {
      state.products[idx] = {
        ...state.products[idx],
        ...filter,
      };
    }
  },
  

  // Sales
  [ADD_SALES_FILTER_TO_QUERY]: (state, filter) => {
    state.salesFilters.push(filter);
  },
  [UPDATE_SALES_FILTER_IN_QUERY]: (state, filter) => {
    const idx = state.salesFilters.findIndex(o => o.id === filter.id);

    if (idx !== -1) {
      state.salesFilters[idx] = {
        ...state.salesFilters[idx],
        ...filter,
      };
    }
  },
  [REMOVE_SALES_FILTER_FROM_QUERY]: (state, filterToRemove) => {
    state.salesFilters = state.salesFilters.filter(filter => filter.id !== filterToRemove);
  },
  [REMOVE_ALL_SALES_FILTER_FROM_QUERY]: (state) => {
    state.salesFilters = [];
  },

  [REMOVE_ALL_FILTERS_FROM_QUERY]: (state) => {
    state.salesFilters = [];
    state.customerFilters.filterClauses = [];
    state.products = [];
  },
  [GET_SALES_FILTER_OPTIONS]: (state, options) => {
    state.filters = options;
  },

  // Filter entities
  [ADD_FILTER_ENTITY]: (state, filterEntity) => {
    state.filterEntitiesArray.push(filterEntity);
  },
  [UPDATE_FILTER_ENTITY]: (state, filterEntity) => {
    const idx = state.filterEntitiesArray.findIndex(o => o.id === filterEntity.id);

    if (idx !== -1) {
      state.filterEntitiesArray[idx] = {
        ...state.filterEntitiesArray[idx],
        ...filterEntity,
      };
    }
  },
  [REMOVE_FILTER_ENTITY]: (state, filterToRemove) => {
    // Remove the selected filter entity
    state.filterEntitiesArray = state.filterEntitiesArray.filter(filter => filter.id !== filterToRemove.id);
  },

  // Customers
  [ADD_CUSTOMER_FILTER_TO_QUERY]: (state, filter) => {
    state.customerFilters.filterClauses.push(filter);
  },
  [UPDATE_CUSTOMER_FILTER_IN_QUERY]: (state, filter) => {
    const idx = state.customerFilters.filterClauses.findIndex(o => o.id === filter.id);

    if (idx !== -1) {
      state.customerFilters.filterClauses[idx] = {
        ...state.customerFilters.filterClauses[idx],
        ...filter,
      };
    }
  },
  [REMOVE_CUSTOMER_FILTER_FROM_QUERY]: (state, filterToRemove) => {
    if (filterToRemove.entity === 'interaction' && filterToRemove.field !== 'date_end') {
      state.customerFilters.filterClauses = state.customerFilters.filterClauses.filter(filter => filter.entity !== 'interaction');
    } else {
      state.customerFilters.filterClauses = state.customerFilters.filterClauses.filter(filter => filter.id !== filterToRemove.id);
    }
  },
  [REMOVE_ALL_CUSTOMER_FILTER_FROM_QUERY]: (state) => {
    state.customerFilters.filterClauses = [];
  },

  [UPDATE_CUSTOMER_FILTER_TYPE]: (state, type) => {
    state.customerFilters.filter_type = type.toUpperCase();
  },

  [ADD_BUBBLE]: (state, bubble) => {
    if (state.bubbles.findIndex(o => o.id === bubble.id) === -1) {
      state.bubbles.push(bubble);
    }
  },
  [REMOVE_BUBBLE]: (state, bubble) => {
    let bubbles = [];
    let n = 0;
    for (n; n < state.bubbles.length; n++) {
      const _ref = state.bubbles[n];
      if (_ref.id !== bubble.id) {
        bubbles.push(_ref);
      }
    }
    state.bubbles = bubbles;
  },

  [QUERY_BUILDER_VARIANCE_OUTPUT]: (state, flag) => {
    state.varianceOutput = flag;
  },
};

// actions
const actions = {
  [REPLACE_PRODUCTS_IN_QUERY]: ({ commit }, products) => {
    commit(REPLACE_PRODUCTS_IN_QUERY, products);
  },
  [ADD_MANY_PRODUCTS_TO_QUERY]: ({ commit }, products) => {
    commit(ADD_MANY_PRODUCTS_TO_QUERY, products);
  },
  [ADD_PRODUCT_TO_QUERY]: ({ commit }, product) => {
    commit(ADD_PRODUCT_TO_QUERY, product);
  },
  [EDIT_PRODUCT_IN_FILTER]: ({ commit }, product) => {
    commit(EDIT_PRODUCT_IN_FILTER, product);
  },
  [REMOVE_PRODUCT_FROM_QUERY]: ({ commit }, product) => {
    commit(REMOVE_PRODUCT_FROM_QUERY, product);
  },
  [REMOVE_COMMUNITY_FROM_QUERY]: ({ commit }, community) => {
    commit(REMOVE_COMMUNITY_FROM_QUERY, community);
  },

  [CHANGE_PRODUCT_STATUS]: ({ commit }, parameters) => {
    commit(CHANGE_PRODUCT_STATUS, parameters);
  },
  [CLEAR_PRODUCTS]: ({ commit }) => {
    commit(CLEAR_PRODUCTS);
  },
  [CLEAR_BUYING_PRODUCTS]: ({ commit }) => {
    commit(CLEAR_BUYING_PRODUCTS);
  },
  [CLEAR_NOT_BUYING_PRODUCTS]: ({ commit }) => {
    commit(CLEAR_NOT_BUYING_PRODUCTS);
  },

  [ADD_SALES_FILTER_TO_QUERY]:  ({ commit }, filter) => {
    commit(ADD_SALES_FILTER_TO_QUERY, filter);
  },
  [REMOVE_SALES_FILTER_FROM_QUERY]:  ({ commit }, filter) => {
    commit(REMOVE_SALES_FILTER_FROM_QUERY, filter);
  },
  [UPDATE_SALES_FILTER_IN_QUERY]:  ({ commit }, filter) => {
    commit(UPDATE_SALES_FILTER_IN_QUERY, filter);
  },
  [REMOVE_ALL_SALES_FILTER_FROM_QUERY]: ({ commit }) => {
    commit(REMOVE_ALL_SALES_FILTER_FROM_QUERY);
  },
  [REMOVE_ALL_FILTERS_FROM_QUERY]: ({ commit }) => {
    commit(REMOVE_ALL_FILTERS_FROM_QUERY);
  },
  [GET_SALES_FILTER_OPTIONS]: async ({ commit }) => {
    try {
      const response = await httpRequest('get', 'analysis-fields', {}, {
        version: '2',
        area: 'enquiries',
      });

      commit(GET_SALES_FILTER_OPTIONS, response);
    } catch (error) {
      // Handle error
      console.error('Error fetching sales filter options:', error);
    }
  },

  [ADD_FILTER_ENTITY]: ({ commit }, filterEntity) => {
    commit(ADD_FILTER_ENTITY, filterEntity);
  },
  [UPDATE_FILTER_ENTITY]: ({ commit }, filterEntity) => {
    commit(UPDATE_FILTER_ENTITY, filterEntity);
  },
  [REMOVE_FILTER_ENTITY]: ({ commit }) => {
    commit(REMOVE_FILTER_ENTITY);
  },
  [ADD_CUSTOMER_FILTER_TO_QUERY]:  ({ commit }, filter) => {
    commit(ADD_CUSTOMER_FILTER_TO_QUERY, filter);
  },
  [REMOVE_CUSTOMER_FILTER_FROM_QUERY]:  ({ commit }, filter) => {
    try {
      commit(REMOVE_CUSTOMER_FILTER_FROM_QUERY, filter);
      return true;
    } catch (error) {
      console.error('Error removing customer filter:', error);
      return false;
    }
  },
  [UPDATE_CUSTOMER_FILTER_IN_QUERY]:  ({ state, commit }, filter) => {
    if (!filter) return; // ignore if no filter
    if (filter.id) {
      commit(UPDATE_CUSTOMER_FILTER_IN_QUERY, filter);
    } else {
      filter.id = state.customerFilters.filterClauses.length + 1;
      commit(ADD_CUSTOMER_FILTER_TO_QUERY, filter);
    }
    return filter.id;
  },
  [REMOVE_ALL_CUSTOMER_FILTER_FROM_QUERY]: ({ commit }) => {
    commit(REMOVE_ALL_CUSTOMER_FILTER_FROM_QUERY);
  },

  [UPDATE_CUSTOMER_FILTER_TYPE]: ({ commit }, type) => {
    commit(UPDATE_CUSTOMER_FILTER_TYPE, type);
  },

  [UPDATE_PRODUCT_FILTER]: ({ commit }, parameters) => {
    commit(UPDATE_PRODUCT_FILTER, parameters);
  },
  [UPDATE_PRODUCT_FILTER_IN_QUERY]:  ({ commit }, filter) => {
    commit(UPDATE_PRODUCT_FILTER_IN_QUERY, filter);
  },
  [ADD_BUBBLE]: ({ commit }, bubble) => {
    commit(ADD_BUBBLE, bubble);
  },
  [REMOVE_BUBBLE]: ({ commit }, bubble) => {
    commit(REMOVE_BUBBLE, bubble);
  },

  [QUERY_BUILDER_VARIANCE_OUTPUT]: ({ commit }, flag) => {
    commit(QUERY_BUILDER_VARIANCE_OUTPUT, flag);
    setRequestParam(commit, 'isAlertBuilderVariance', flag);
  },

  ['SET_LARGE_LIMIT']: ({ commit })  => {
    setRequestParam(commit, 'limit', 2000);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
