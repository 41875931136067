<template>
  <div class="opportunities-title-container bg-blue-light">
    <h2>{{ t('Opportunities') }}</h2>
    <h4>{{ t('Recommended products for your customer') }}</h4>
    <CustomButton
      small
      purpose="action"
      class="cta mt-1"
      @click="addOpportunityModal"
    >
      {{ t('Add Opportunity') }}
    </CustomButton>
  </div>
  <Tabs
    :items="tabs"
    :selected-index="tabSelectedIndex"
    @nav-clicked="navClicked"
  />
  <BufferImage
    v-if="opportunitiesLoading"
    class="buffer"
    color="var(--colour-utility-black)"
    float="center"
  />
  <div
    v-else
    class="table-wrapper"
  >
    <EntityListViewTable
      entity="opportunity"
      :items="opportunityItems"
      :headings="opportunityHeadingsCustomerView"
      :status="status"
      @edit="editOpportunity"
      @opportunity-deleted="handleOpportunityDeleted"
    />
    <Accordion
      v-if="status === 'open'"
      class="accordion-opportunities"
      :title="`${t('AI generated opportunities')} (${aiOpportunityItems.length})`"
      :is-opened="isAccordionOpened"
      accordion-size="small"
    >
      <template #content>
        <EntityListViewTable
          class="entity-list-view-table"
          entity="opportunity"
          status="potential"
          :items="aiOpportunityItems"
          :headings="aiOpportunityHeadingsCustomerView"
          @edit="editOpportunity"
          @opportunity-deleted="handleOpportunityDeleted"
        />
      </template>
    </Accordion>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { CustomButton, BufferImage } from '@sales-i/dsv3';
import { dates, t } from '@sales-i/utils';
import usePermissions from '@/shared/composables/usePermissions';
import { opportunities } from '@/shared/store/data/policies';
import Tabs from '@/shared/components/Tabs/Tabs.vue';
import EntityListViewTable from '@/shared/components/Tables/EntityListViewTable';
import { useRoute, } from 'vue-router';
import { baseUrl, opportunitiesArea } from '@/crm/router/urlBits';
import { navigateToUrl } from 'single-spa';
import { DateTime } from 'luxon';
import Accordion from '@/shared/components/Accordion/Accordion';
import { useOpportunity } from '@/crm/composables/useOpportunity';

const { getPermissions } = usePermissions();
const permissionsCRM = getPermissions(opportunities);
const vroute = useRoute();

const props = defineProps({
  entityType: {
    type: String,
    default: 'customer',
  },
});

const { 
  getOpportunities, 
  getOpportunitiesWithProducts, 
  clearOpportunities, 
  setRoute, 
  getSummary,
  editOpportunity,
  opportunitiesLoading,
  opportunitiesLoaded,
  opportunityItems,
  opportunitiesSummary,
  aiOpportunityItems,
  aiOpportunityHeadingsCustomerView, 
  opportunityHeadingsCustomerView,
} = useOpportunity();

const tabs = computed(() => [
  ...(permissionsCRM.read
    ? [
      {
        title: t('Open'),
        query: 'open',
        amount: opportunitiesSummary.value?.open,
      },
      {
        title: t('Won'),
        query: 'won',
        amount: opportunitiesSummary.value?.won,
      },
      {
        title: t('Lost'),
        query: 'lost',
        amount: opportunitiesSummary.value?.lost,
      },
    ]
    : []),
]);
const tabSelectedIndex = ref(0);
const isAccordionOpened = ref(true);
const status = ref('open');
const subjectId = computed(() => vroute.params?.id);

onMounted(() => { 
  loadData();
  getOpportunitiesSummary();
});

function navClicked(data) {
  status.value = data.item.query;
  tabSelectedIndex.value = data.index;
  loadData();
}

function addOpportunityModal() {
  setRoute({ cancel: vroute.fullPath, });
  const path = `${baseUrl}/${opportunitiesArea}/add`;
  const query = new URLSearchParams({ 
    status: 'open',
    entityId: subjectId.value,
    entityPreselected: true,
    entityType: props.entityType,
  });
  navigateToUrl(`${path}?${query.toString()}`);
}

async function handleOpportunityDeleted() {
  await loadData();
  await getOpportunitiesSummary();
}

async function loadData() {
  // cancel reload of opportunities if add/edit button pressed
  // only for main opportunities page
  if (['AddOpportunityModalRoute', 'EditOpportunityModalRoute'].includes(vroute.name) && (opportunitiesLoaded.value || opportunitiesLoading.value)) {
    return;
  }
  await clearOpportunities();
  await getOpportunities({
    type: 'manual',
    status: status.value,
    sort: 'decision_date:desc',
    limit: 50,
    offset: 0,
    entity_id: subjectId.value,
    date_from: dates.forApi(DateTime.local(), true),
    date_to: dates.forApi(DateTime.local().plus({ days: 90 }), true),
    days: 31,
  });
  if (status.value === 'open') {
    await getOpportunitiesWithProducts({
      type: ['MISSED', 'POTENTIAL'],
      sort: 'decision_date:desc',
      limit: 50,
      offset: 0,
      entity_id: subjectId.value,
    });
  }
}

async function getOpportunitiesSummary() {
  getSummary({
    customer_id: subjectId.value,
    date_from: dates.forApi(DateTime.local().minus({ years: 1 }), true),
    date_to: dates.forApi(DateTime.local(), true),
  });
}
</script>

<style scoped lang="scss">
@import '@/shared/assets/scss/_variables';

.table-wrapper {
  padding: 0 var(--spacing-4);
}

.entity-list-view-table :deep(.table-wrapper) {
  overflow: auto;
  height: 100%;
}

.opportunities-title-container {
  background-color: var(--colour-panel-alert-base);
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-bottom: var(--spacing-3);
  padding-top: var(--spacing-3);
  position: relative;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    padding-bottom: var(--spacing-3);
    margin: 0 0 var(--spacing-4) 0;
  }

  h4 {
    font-weight: var(--font-weight-regular);
  }

  .cta {
    position: absolute;
    right: var(--spacing-5);
  }
}

.accordion-opportunities {
  margin-top: var(--spacing-1);
  box-shadow: none;

  :deep(.heading) {
    justify-content: center;
  }

  :deep(.heading h3) {
    color: var(--colour-utility-black);
    text-decoration: none;
  }

  :deep(.svg-container svg) {
    color: var(--colour-utility-black) !important;
  }
}
</style>