<template>
  <div class="enquiries-baseview-container">
    <SignPost
      v-if="permissions.read"
      :heading="t('Enquiries')"
      :description="t('Manage your custom schema sections and fields')"
      color="var(--colour-utility-action)"
      background-color="var(--colour-panel-action)"
      :links="enquiriesLinks"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { t } from '@sales-i/utils';
import SignPost from '@/admin/views/SignPost.vue';
import usePermissions from '@/shared/composables/usePermissions';
import { intelligence_enquiries } from '@/shared/store/data/policies';
import { baseUrl, enquiryScope } from '@/intelligence/router/urlBits';
import { FULL_PICTURE, MATRIX, SALES_VS_GAPS, TRENDS, VARIANCE } from '@/intelligence/store/data/reportTypes';

const { getPermissions } = usePermissions();
const permissions = getPermissions(intelligence_enquiries);

const enquiriesLinks = computed(() => {
  const arr = [];
  arr.push({
    title: t('Full Picture'),
    description: t('See the full picture'),
    href: `${baseUrl}/${enquiryScope}/${FULL_PICTURE}`,
  });
  arr.push({
    title: t('Variance'),
    description: t('Gauge variance between two periods in up to four KPIs at once'),
    href: `${baseUrl}/${enquiryScope}/${VARIANCE}`,
  });
  arr.push({
    title: t('Matrix'),
    description: t('Two dimensions are better than one'),
    href: `${baseUrl}/${enquiryScope}/${MATRIX}`,
  });
  arr.push({
    title: t('Trends'),
    description: t('Compare year on year trends'),
    href: `${baseUrl}/${enquiryScope}/${TRENDS}`,
  });
  arr.push({
    title: t('Sales vs Gaps'),
    description: t('Analyse gaps in your sales over time'),
    href: `${baseUrl}/${enquiryScope}/${SALES_VS_GAPS}`,
  });
  return arr;
});
</script>

<style lang="scss" scoped>
.enquiries-baseview-container {
  padding: var(--spacing-4);
  > div {
    border-radius: var(--border-radius-1);
  }
}
</style>
