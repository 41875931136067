import { computed } from 'vue';
import { useStore } from 'vuex';

import { GET_BY_ID, CLEAR_BY_ID, DELETE_BY_ID, GET_CONTACTS, CLEAR_CONTACTS } from '@/shared/store/actionType';

export const useProspect = ({ store = useStore() } = {}) => {
  const selectedProspect = computed(() => store.state.crm.prospects.selected.data);
  const selectedProspectLoading = computed(() => store.state.crm.prospects.selected.loading);
  const selectedProspectLoaded = computed(() => store.state.crm.prospects.selected.loaded);
  
  const getProspectById = params => store.dispatch(`crm/prospects/${GET_BY_ID}`, params);
  const clearProspectById = () => store.dispatch(`crm/prospects/${CLEAR_BY_ID}`);
  const deleteProspect = params => store.dispatch(`crm/prospects/${DELETE_BY_ID}`, params);
  const getProspectContacts = params => store.dispatch(`crm/prospects/${GET_CONTACTS}`, params);
  const clearProspectContacts = params => store.dispatch(`crm/prospects/${CLEAR_CONTACTS}`, params);

  return {
    selectedProspect,
    selectedProspectLoading,
    selectedProspectLoaded,
    getProspectById,
    clearProspectById,
    deleteProspect,
    getProspectContacts,
    clearProspectContacts,
  };
};

