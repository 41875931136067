<template>
  <TableWrapper
    v-if="!dataLoading && dataRows.length"
    :class="{ 'is-snapshot': isSnapshot }"
    table-height="510px"
    :more-results-available="moreResultsAvailable"
    :offset-x="offsetX"
    :offset-y="offsetY"
    :limit="requestParameters.limit"
    :limit-x="requestParameters.limitX"
    :enable-pagination="!isSnapshot"
    :x-headings="xHeadings"
    :y-headings="yHeadings"
    @set-offset="loadData"
  >
    <thead>
      <th class="search-cell">
        <div class="field">
          <input
            v-show="isSearchVisible"
            v-model="searchRowsQuery"
            class="input"
            type="text"
            :aria-label="t('Search')"
            @input="debounceSearch"
          >
          <CustomButton
            class="search-button"
            :class="{ active: isSearchVisible }"
            purpose="transparent"
            icon-name="search"
            icon-color="var(--colour-utility-action)"
            icon-only
            :icon-width="24"
            :icon-height="24"
            @click="toggleSearch"
          />
        </div>
        <CustomButton
          v-if="!isSnapshot"
          class="sort-button"
          :class="ySortDirection"
          purpose="transparent"
          icon-name="arrow-down"
          icon-color="var(--colour-utility-black)"
          icon-only
          :icon-width="16"
          :icon-height="16"
          @click="$emit('sortOnYAxis')"
        />
      </th>
      <th
        v-for="(heading, index) in xHeadings"
        :key="`${heading}-${index}`"
        :title="heading"
        :class="{ 'drill-active': isDrillActive }"
      >
        <div 
          v-if="isMatrix"
          class="heading"
        >
          <span class="order-number">#{{ Number(offsetX) + index + 1 }}</span> {{ heading }}
        </div>
        <div 
          v-else
          class="heading"
        >
          {{ heading }}
        </div>
        <div
          class="chip"
          :title="getChipValue(index)"
        >
          {{ isPriceTypeData ? cPrice(getChipValue(index)) : cNumber(Number(getChipValue(index))) }}
        </div>
      </th>
    </thead>
    <tbody>
      <tr
        v-for="(row, ind) in rowsToShow"
        :key="row.id"
      >
        <th
          :title="row.headings[0]"
          :class="{ 'drill-active': isDrillActive, 'chip-hidden': !rowsChipsVisible }"
        >
          <div
            v-if="isDrillActive && !isSnapshot"
            class="drill"
          >
            <CustomCheckbox
              :id="getRowId(row.customer)"
              class="checkbox"
              :value="row.checked"
              @input="handleEnquiryDrill(row, $event)"
            />
          </div>
          <div class="title">
            <div class="grid-container">
              <span
                class="row-total"
                :class="[row.total < 0 ? 'negative' : 'positive']"
              >
                {{ isPriceTypeData ? cPrice(row.total || row.row_total) : cNumber(Number(row.total || row.row_total)) }}
              </span>

              <span :class="['order-number', row.customer ? 'order-number-link' : '']">#{{ Number(offsetY) + ind + 1 }}&nbsp;</span>
              <p
                v-if="row.customer && !iframe" 
                class="title-content order-left-aligned"
              >
                <a
                  class="bubble-title link"
                  :href="getCustomerLink(row.customer)"
                  target="_blank"
                >
                  {{ row.headings[0] }}
                </a>
              </p>
              <p
                v-else
                class="title-content order-left-aligned"
              >
                {{ row.headings[0] }}
              </p>
              <p class="bubble-subtitle order-left-aligned">
                {{ row.headings[1] }}
              </p>
            </div>
          </div>
          <IconButton
            v-if="isSnapshot && isSmallScreen"
            class="chip-toggler"
            :icon-size="32"
            icon-name="caret-right"
            icon-color="var(--colour-utility-action)"
            purpose="transparent"
            icon-only
            @click="toggleChips"
          />
          <div
            v-if="isSnapshot"
            v-show="rowsChipsVisible"
            class="chip"
            :title="row.total"
          >
            {{ isPriceTypeData ? cPrice(Number(row.total)) : cNumber(Number(row.total)) }}
          </div>
        </th>
        <td
          v-for="(cell, i) in row.values"
          :key="`cell-${row.id}-${i}`"
          :title="parseInt(cell, 10).toLocaleString('en-US')"
          :class="{
            zero: parseInt(cell, 10) === 0,
            negative: parseInt(cell, 10) < 0,
          }"
        >
          <div>
            {{ cell ? (isPriceTypeData ? cPrice(cell) : cNumber(Number(cell))) : '-' }}
          </div>
        </td>
      </tr>
    </tbody>
  </TableWrapper>
</template>

<script setup>
import { computed, ref, watch, onMounted, defineProps, defineEmits } from 'vue';
import { useStore } from 'vuex';
import { useMq } from 'vue3-mq';
import { debounce, t } from '@sales-i/utils';
import { CustomCheckbox, CustomButton, IconButton } from '@sales-i/dsv3';
import breakpoints from '@/shared/utils/breakpoints';
import TableWrapper from '@/shared/components/Tables/TableWrapper';
import { REPORTS_SORT_DESCENDING_KEY } from '@/intelligence/store/data/apiInput';
import { baseUrl as crmBaseUrl, customersArea } from '@/crm/router/urlBits';
import {
  REPORTS_KPI_COST,
  REPORTS_KPI_PROFIT,
  REPORTS_KPI_SALES_VALUE,
  REPORTS_KPI_VALUE,
} from '@/intelligence/store/data/kpis';
import { MATRIX } from '@/intelligence/store/data/reportTypes';
import useEnquiries from '@/intelligence/composables/useEnquiries';
import useShared from '@/intelligence/composables/useShared';
import useSystem from '@/shared/composables/useSystem';
import useCurrency from '@/shared/composables/useCurrency';

const props = defineProps({
  ySortDirection: {
    type: String,
    default: REPORTS_SORT_DESCENDING_KEY,
  },
  isSnapshot: {
    type: Boolean,
    default: false,
  },
  reportId: {
    type: Number,
    default: null,
  },
  isQuantityType: {
    type: Boolean,
    default: false,
  },
  reportType: {
    type: String,
    default: '',
  },
});

const mq = useMq();
const emit = defineEmits(['sortOnYAxis', 'handleEnquiryDrill', 'setEnquiryData']);
const store = useStore();

// Lifecycle hooks
onMounted(() => {
  initializeValueRows();
  if (!isSmallScreen.value) toggleDrillOption(true);
  else rowsChipsVisible.value = false;
});

const { iframe } = useSystem({ store });
const { applyOffsetX } = useEnquiries({ store });
const { dataLoading, requestParameters, drillActiveRows, isDrillActive, getReportData, 
  applyOffset: applyOffsetY, toggleDrillOption } = useShared({ store });
const { cPrice, cNumber } = useCurrency({ store });

// Data
const searchRowsQuery = ref('');
const showSearch = ref(false);
const moreResultsAvailable = ref(true);
const offsetX = ref(0);
const offsetY = ref(0);
const dataRows = ref([]);
const filteredRows = ref([]);
const rowsChipsVisible = ref(true);

// Computed 
const rowsToShow = computed(() => searchRowsQuery.value.length ? filteredRows.value : dataRows.value);
const enquiryData = computed(() => getReportData(props.reportId));
const isSmallScreen = computed(() => breakpoints.smAndDown.includes(mq.current));
const isSearchVisible = computed(() => isSmallScreen.value ? showSearch.value : true);
const xHeadings = computed(() => enquiryData.value?.axis?.x?.headings || []);
const yHeadings = computed(() => {
  if (!enquiryData.value || !enquiryData.value.axis || !enquiryData.value.axis.y) {
    console.error('Data structure error: y-axis data is missing');
    return [];
  }
  if (props.isSnapshot) {
    return Array.isArray(enquiryData.value?.axis?.y) &&
      enquiryData.value?.axis?.y?.length > 0 &&
      enquiryData.value?.axis?.y[0]?.headings
      ? enquiryData.value?.axis?.y[0]?.headings
      : [];
  }
  return enquiryData.value?.axis?.y?.headings || [];
});
const summary = computed(() => enquiryData.value.summary || []);
const isPriceTypeData = computed(() => 
  [
    REPORTS_KPI_COST, REPORTS_KPI_PROFIT, 
    REPORTS_KPI_SALES_VALUE, REPORTS_KPI_VALUE
  ].includes(requestParameters.value.measure) 
  && !props.isQuantityType
);
const debounceSearch = computed(() => debounce(filterRows, 500));
const isMatrix = computed(() => props.reportType === MATRIX);

// Watchers
watch(enquiryData, (newVal) => {
  searchRowsQuery.value = '';
  if (newVal?.rows) {
    initializeValueRows();
  }
});

watch(drillActiveRows, (newRows, oldRows) => {
  if (!newRows.length) {
    oldRows.forEach((oldRow) => {
      handleEnquiryDrill(findRowByDrillValue(oldRow), false);
    });
  }
});

watch(isSmallScreen, (newValue) => {
  rowsChipsVisible.value = !newValue;
});

// Methods    
function initializeValueRows() {
  if (!enquiryData.value?.rows) return;
  
  const rows = [...enquiryData.value.rows];
  dataRows.value = rows.map((row, index) => {
    const headings = yHeadings.value[index];
    const { values, id, total, row_total } = { ...row };
    return {
      values,
      customer: id,
      checked: false,
      headings,
      id: headings.toString(),
      total,
      row_total,
    };
  });
  drillActiveRows.value.forEach((activeRow) => {
    const row = findRowByDrillValue(activeRow);
    if (row) row.checked = true;
  });
}

function findRowByDrillValue(drillObject) {
  return dataRows.value.find((row) => row.id === drillObject.id);
}

function getChipValue(index) {
  return summary.value[index];
}

function getRowId(rowHeading) {
  return rowHeading ? rowHeading.replace(/\s+/g, '-') : null;
}

function handleEnquiryDrill(dataRow, checked) {
  if (!dataRow) return;
  dataRow.checked = checked;
  const [head1, head2] = dataRow.headings;
  emit('handleEnquiryDrill', {
    checked,
    id: dataRow.id,
    item: { value: head1, pair: head2 },
  });
}

function toggleSearch() {
  showSearch.value = !showSearch.value;
}

function toggleChips() {
  rowsChipsVisible.value = !rowsChipsVisible.value;
}

function loadData(paginationDirection) {
  const { limit, limitX } = requestParameters.value;
  switch (paginationDirection) {
  case 'right':
    offsetX.value += limitX;
    break;
  case 'bottom':
    offsetY.value += limit;
    break;
  case 'left':
    offsetX.value -= limitX;
    break;
  case 'top':
    offsetY.value -= limit;
    break;
  default:
    break;
  }
  applyOffsetX(offsetX.value);
  applyOffsetY(offsetY.value);
  emit('setEnquiryData');
}

function getCustomerLink(id) {
  return `${crmBaseUrl}/${customersArea}/${id}`;
}

function filterRows() {
  filteredRows.value = searchRowsQuery.value
    ? dataRows.value.filter((row) =>
      row.id.toLowerCase().includes(searchRowsQuery.value.toLowerCase())
    )
    : [];
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
table {
  td,
  th {
    vertical-align: middle;
    font-size: var(--font-size-body);
    color: var(--colour-utility-black);
    text-align: center;
    border: 0;
  }

  td[title],
  th[title] {
    cursor: help;
  }

  td {
    padding: 0 var(--spacing-2);

    &.zero {
      color: var(--colour-utility-error);
    }

    div {
      padding: var(--spacing-2);
    }
  }

  thead {
    th {
      min-width: 120px;
      padding: var(--spacing-2) var(--spacing-2) var(--spacing-4);
      background: var(--colour-panel-g-4);
      color: var(--colour-utility-black);
      border-bottom: 1px solid var(--colour-panel-g-16);
      position: sticky;
      top: 0;
      z-index: 1;
      text-decoration: none;
      font-weight: normal;

      @media #{map-get($display-breakpoints, 'sm-and-up')} {
        min-width: 195px;
      }

      .heading {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        height: 50px;
      }
    }
  }
  tbody th {
    font-weight: inherit;
  }

  tr {
    &:first-of-type {
      th,
      td {
        padding-top: var(--spacing-3);
      }

      .drill {
        align-items: center;
        padding-top: var(--spacing-5);
      }
    }

    th {
      text-align: left;
      padding: 0;
      position: sticky;
      left: 0;
      z-index: 2;
      background: var(--colour-panel-g-2);

      &.drill-active {
        padding-left: var(--spacing-5);
      }

      .title {
        padding: var(--spacing-2);
        width: 140px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        @media #{map-get($display-breakpoints, 'sm-and-up')} {
          width: auto;
          max-width: 195px;
        }

        @media #{map-get($display-breakpoints, 'md-and-up')} {
          max-width: 225px;
        }
      }
    }
  }
}

.drill {
  background: var(--colour-panel-base);
  padding: var(--spacing-2) var(--spacing-1);
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;

  .checkbox {
    background: var(--colour-utility-white);
    border-radius: var(--border-radius-half);
    margin-bottom: 0;
    width: 1.8em;
    height: 1.8em;
  }
}

.search-cell {
  background: var(--colour-utility-white);
  border-bottom: 0;
  z-index: 3;
  left: 0;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    min-width: 265px;
  }

  .field {
    position: relative;
  }

  input {
    border-radius: var(--spacing-4);
    font-weight: var(--font-weight-regular);
    padding: var(--spacing-1) var(--spacing-2) var(--spacing-1) var(--spacing-4);
  }

  .search-button {
    background: var(--colour-panel-action);
    border-radius: 50%;

    &.active {
      background: transparent;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: var(--spacing-1);
    }
  }
}

.chip {
  position: absolute;
  width: 100px;
  color: var(--colour-utility-black);
  background: var(--colour-panel-g-0);
  box-shadow: 0 0 var(--border-radius-half) var(--shadow-spread) var(--shadow-colour);
  font-size: var(--font-size-5);
  font-weight: var(--font-weight-medium);
  letter-spacing: 0;
  line-height: var(--spacing-3);
  padding: var(--spacing-1);
  border-radius: var(--spacing-6);
  margin: auto;
  margin-top: var(--spacing-1);
  left: 0;
  right: 0;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    width: 120px;
  }
}

.is-snapshot table {
  tr:first-of-type th {
    .chip-toggler {
      top: calc(50% + 12px);
    }
    .chip {
      margin-top: 12px;
    }
  }
  tr th {
    padding-right: 120px;
    padding-left: var(--spacing-2);

    &.chip-hidden {
      padding-right: var(--spacing-2);
    }

    .chip {
      left: unset;
      right: 0;
      top: 50%;
      transform: translate(-8px, -50%);
      text-align: center;
      margin-top: 0;
    }
  }
  .search-cell {
    padding: var(--spacing-2);
  }

  .chip-toggler {
    position: absolute;
    left: 132px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.sort-button {
  position: absolute;
  right: var(--spacing-half);
  top: calc(100% + var(--spacing-half));

  &.asc {
    transform: rotate(180deg);
  }
}

.bubble-title.link {
  font-size: var(--font-size-5);
  font-weight: var(--font-weight-semibold);
  display: initial;

  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    width: 100px;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      width: 165px;
    }

    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      width: 195px;
    }
  }
}

.bubble-subtitle,
p:not(.bubble-subtitle) {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.bubble-subtitle {
  color: var(--colour-utility-base);
  font-size: var(--font-size-small);
}

p:not(.bubble-subtitle) {
  font-weight: var(--font-weight-semibold);
}
.grid-container {
  display: grid;
  grid-template-columns: auto 1fr;

  .row-total {
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-semibold);
    grid-column: 2;
    text-align: start;
  }

  .order-number {
    grid-column: 1;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
    color: var(--colour-utility-base);
    &-link {
      font-size: var(--font-size-5);
    }
  }

  .title-content {
    grid-column: 2;
    align-self: start;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
  }

  .bubble-subtitle {
    grid-column: 2;
    align-self: start;
    margin-left: 0;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
  }
}

.positive {
  color: var(--colour-data-de-york-label);
}

.negative {
  color: var(--colour-data-mandy-label);
}
.order-number {
  color: var(--colour-utility-base);
}
</style>
