import {
  CLEAR_REPORT_DATA,
  CLEAR_REQUEST_PARAMETERS,
  FETCH_REPORT_DATA,
  REFRESH_REPORT,
  SAVE_REPORT_DATA,
  SET_REQUEST_PARAM,
  SET_LOADING,
  SET_EXPANDING_REPORT,
  APPLY_DATE_RANGE,
  GENERATE_NEW_REPORT_ID,
  APPLY_OFFSET,
  APPLY_LIMIT,
  SET_ACTIVE_COLUMNS,
  TOGGLE_VARIANCE_PLUS
} from '@/intelligence/store/actionType';
import { inputSets } from '@/intelligence/store/data/reportsInput';
import { REPORT_TYPES } from '@/intelligence/store/data/reportTypes';
import { 
  RESPONSES_SET,
  REQUEST_ENTITY_INTERACTION_SUMMARY,
  REQUEST_ENTITY_INTERACTION_SUMMARY_DRILL, 
  REQUEST_ENTITY_INTERACTION_SPEND_SUMMARY,
  REQUEST_ENTITY_CUSTOMER_ACTIVITY_LIST
} from '@/intelligence/store/data/apiInput';
import { gqlRequest } from '@sales-i/utils';
import { gql } from 'graphql-request';
import { getRequestParameters } from '@/intelligence/store/modules/shared/getRequestParameters';
import { drillActions, drillGetters, drillMutations } from '@/intelligence/store/modules/shared/drill';
import { REPORTS_KPI_VALUE } from '@/intelligence/store/data/kpis';
import { DATE_RANGE_MONTHLY_OPTIONS } from '@/intelligence/store/data/dateFilters';

// initial state
const state = () => ({
  fetchedData: {},
  reportId: 1,
  reportRefreshCounter: 0,
  loading: true,
  paginationData: '',
  paginationLoading: false,
  isDrillActive: false,
  isSelectBubblesPopupActiveOnMobile: false,
  isReportExpanded: false,
  drillActiveRows: [],
  activeColumns: [],
  isVariancePlus: false,
  requestParameters: {
    xAxis: '',
    yAxis: '',
    dim1: '',
    dim2: '',
    date_from: '',
    date_to: '',
    date_from2: '',
    date_to2: '',
    periods: 12,
    percent_from: 40,
    percent_to: 100,
    prev_year_from: 0,
    prev_year_to: 2000,
    products: 20,
    offset: 0,
    offsetX: 0,
    limit: 100,
    limitX: 100,
    currentSortOption: '',
    currentSortHeader: '',
    filters: '',
    drills: {},
    user: [],
    interaction_type: [],
    interaction_outcome: [],
    next_action: [],
    snapshotBubbleValue: '',
    snapshotBubbleValueId: '',
    snapshotBubbleId: '',
    productBubbleId: '',
    transactionBubbleId: '',
    salesRepBubbleId: '',
    report_type: '',
    analysis_field_id: '',
    analysis_field_value_id: [],
    measure: REPORTS_KPI_VALUE,
    drill_type: '',
    crmFilter: '',
    generatingReportIdQueue: [],
    isAlertBuilderVariance: false,
    showGaps: false,
  }
});

// getters
const getters = {
  ...drillGetters,
  getRequestParameters,
  getResponseParameters: () => requestEntity => {
    return RESPONSES_SET[requestEntity];
  },
  getInputData: () => reportType => {
    return inputSets[reportType];
  },
  getInputArea: () => inputArea => {
    return REPORT_TYPES.reduce((reportsByArea, reportType) => {
      const report = inputSets[reportType];
      if (report && report.area === inputArea) {
        reportsByArea.push(inputSets[reportType]);
      }
      return reportsByArea;
    }, []);
  },
  getReportData: state => reportId => {
    const id = reportId || state.reportId;
    const fetchedData = state.fetchedData;
    return fetchedData[id]?.data;
  },
  // TODO: Move to composable
  getMonth: () => value => {
    return isNaN(value) ? 
      DATE_RANGE_MONTHLY_OPTIONS.find(month => month.value === value || month.text === value) : 
      DATE_RANGE_MONTHLY_OPTIONS[value - 1];
  }
};

// mutations
const mutations = {
  ...drillMutations,
  [REFRESH_REPORT]: state => {
    state.reportRefreshCounter++;
  },
  [SET_LOADING]: (state, isLoading) => {
    state.loading = isLoading;
  },
  [SET_EXPANDING_REPORT]: (state, isExpanded) => {
    state.isReportExpanded = isExpanded;
  },
  [CLEAR_REQUEST_PARAMETERS]: state => {
    state.requestParameters.periods = 12,
    state.requestParameters.products = 20,
    state.requestParameters.percent_from = 40,
    state.requestParameters.percent_to = 100,
    state.requestParameters.prev_year_from = 0,
    state.requestParameters.prev_year_to = 2000,
    state.requestParameters.date_from = '';
    state.requestParameters.date_to = '';
    state.requestParameters.date_from2 = '';
    state.requestParameters.date_to2 = '';
    state.requestParameters.currentSortHeader = '';
    state.requestParameters.currentSortOption = '';
    state.requestParameters.xAxis = '';
    state.requestParameters.yAxis = '';
    state.requestParameters.dim1 = '';
    state.requestParameters.dim2 = '';
    state.requestParameters.offset = 0;
    state.requestParameters.offsetX = 0;
    state.requestParameters.filters = '';
    state.requestParameters.advancedFilters = '';
    state.requestParameters.drills = {};
    state.requestParameters.user = [];
    state.requestParameters.snapshotBubbleValue = '';
    state.requestParameters.limit = 100;
    state.requestParameters.limitX = 100;
    state.requestParameters.interaction_type = [];
    state.requestParameters.interaction_outcome = [];
    state.requestParameters.next_action = [];
    state.requestParameters.report_type = '';
    state.requestParameters.analysis_field_id = '';
    state.requestParameters.analysis_field_value_id = [];
    state.requestParameters.drill_type = '';
    state.requestParameters.crmFilter = '';
    state.requestParameters.isAlertBuilderVariance = false;
    state.requestParameters.showGaps = false;
  },
  [CLEAR_REPORT_DATA]: state => {
    state.isDrillActive = false;
    state.isSelectBubblesPopupActiveOnMobile = false;
    // TODO: why it is true???
    state.loading = true;
    state.drillActiveRows = [];
    state.isVariancePlus = false;
    state.activeColumns = [];
  },
  [SET_REQUEST_PARAM]: (state, [key, value]) => {
    state.requestParameters[key] = value;
  },
  [SAVE_REPORT_DATA]: (state, [reportData, reportId, reportType, completeRefresh]) => {
    if (completeRefresh) {
      let data = {};
      Object.keys(state.fetchedData).forEach(id => {
        if (state.fetchedData[id].reportType !== reportType) {
          data[id] = state.fetchedData[id];
        }
      });
      state.fetchedData = data;
    }
    state.fetchedData[reportId] = reportData;
  },
  [GENERATE_NEW_REPORT_ID]: (state, reportId) => {
    state.reportId = reportId;
  },
  [SET_ACTIVE_COLUMNS] : (state, payload) => {
    state.activeColumns = payload;
  },
  [TOGGLE_VARIANCE_PLUS] : (state, payload) => {
    state.isVariancePlus = payload;
  }
};

// actions
const actions = {
  ...drillActions,
  [FETCH_REPORT_DATA]: async ({ commit, getters, dispatch }, { reportType, completeRefresh = false, customParams = {}, customResponse = null } = {}) => {
    if (!reportType) return;
    const crmEndpointReports = [
      REQUEST_ENTITY_INTERACTION_SUMMARY, 
      REQUEST_ENTITY_INTERACTION_SUMMARY_DRILL, 
      REQUEST_ENTITY_INTERACTION_SPEND_SUMMARY, 
      REQUEST_ENTITY_CUSTOMER_ACTIVITY_LIST
    ];
    const crmEndpoint = `${process.env.VUE_APP_API_V2_ENDPOINT}/crm-reports/v2/query`;
    const reportId = await dispatch(GENERATE_NEW_REPORT_ID);
    const inputData = getters.getInputData(reportType);
    try {
      commit(SET_LOADING, true);
      // Use requestEntity from customResponse if available
      const 
        requestEntity = inputData.requestEntity,
        { getRequestParameters, getResponseParameters } = getters,
        responseEntity = customResponse ? customResponse : getResponseParameters(requestEntity),
        query = gql`{ ${requestEntity}(${getRequestParameters([reportType, customParams])}) { ${responseEntity} }}`,
        endpoint = crmEndpointReports.includes(requestEntity) ? crmEndpoint : undefined,
        response = await gqlRequest(query, {}, { debug: true }, endpoint);
      
      const reportData = {
        data: response[requestEntity],
        reportType,
      };
      commit(SAVE_REPORT_DATA, [reportData, reportId, reportType, completeRefresh]);
    } catch (error) {
      console.error(error);
    } finally {
      commit(SET_LOADING, false);
    }
    return reportId;
  },
  [APPLY_DATE_RANGE]: ({ commit }, dates) => {
    commit(SET_REQUEST_PARAM, ['date_from', dates.date_from]);
    commit(SET_REQUEST_PARAM, ['date_to', dates.date_to]);
    commit(SET_REQUEST_PARAM, ['date_from2', dates.date_from2]);
    commit(SET_REQUEST_PARAM, ['date_to2', dates.date_to2]);
  },
  [APPLY_OFFSET]: ({ commit }, offset) => {
    commit(SET_REQUEST_PARAM, ['offset', offset]);
  },
  [CLEAR_REPORT_DATA]: ({ commit }) => {
    commit(CLEAR_REPORT_DATA);
    commit(CLEAR_REQUEST_PARAMETERS);
  },
  [REFRESH_REPORT]: ({ commit }) => {
    commit(REFRESH_REPORT);
  },
  [SET_LOADING]: ({ commit }, isLoading) => {
    commit(SET_LOADING, isLoading);
  },
  [SET_EXPANDING_REPORT]: ({ commit }, isExpanded) => {
    commit(SET_EXPANDING_REPORT, isExpanded);
  },
  [APPLY_LIMIT]: ({ commit }, limit) => {
    commit(SET_REQUEST_PARAM, ['limit', limit]);
  },
  [GENERATE_NEW_REPORT_ID]: ({ commit, state }) => {
    const reportId = state.reportId + 1;
    commit(GENERATE_NEW_REPORT_ID, reportId);
    return reportId;
  },
  [SET_ACTIVE_COLUMNS]: ({ commit }, payload) => {
    commit(SET_ACTIVE_COLUMNS, payload);
  },
  [TOGGLE_VARIANCE_PLUS]: ({ commit}, payload) => {
    commit(TOGGLE_VARIANCE_PLUS, payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
