<template>
  <CustomModal
    show-modal
    :max-width="750"
    @close-modal="closeModal"
  >
    <template #title>
      <div class="header">
        <h1
          ref="pageTitle"
          class="fw-bld text-3"
        >
          <slot name="title">
            {{ title }}
          </slot>
        </h1>
        <div class="interaction-summary">
          <span v-if="currentlyEditing.dateTime">
            {{
              t(`${dates.format(currentlyEditing.dateTime, 'DD-MM-YY')} at ${startTime}`, 'date_at_time', {
                interpolations: {
                  date: dates.format(currentlyEditing.dateTime, 'DD-MM-YY'),
                  time: startTime,
                },
              })
            }}<span>&nbsp;</span>
          </span>
          <span v-if="currentlyEditing.contact.full_name">
            {{
              t(`with ${currentlyEditing.contact.full_name}`, 'with_name', {
                interpolations: {
                  name: currentlyEditing.contact.full_name,
                },
              })
            }}
          </span>
          <span>&nbsp;</span>
          <span v-if="currentlyEditing.entity.name">
            {{
              t(`at ${currentlyEditing.entity.name}`, 'at_entity', {
                interpolations: {
                  entity: currentlyEditing.entity.name,
                },
              })
            }}
          </span>
        </div>

        <div>
          <Stage
            :items="stages"
            :selected-index="stage"
            :disabled="stageName === 'Schedule follow up'"
            @stage="changeStage"
          />
        </div>
      </div>
    </template>

    <div class="container">
      <div class="form-wrapper flow">
        <router-view
          v-if="!isEditInteraction || currentlyEditing.dateTime"
          :status="getStatus()"
          :interaction-id="vroute.params.id"
          :is-personal="isPersonal"
          :is-edit-interaction="isEditInteraction"
          :is-backdated="isBackdated"
          :is-open-follow-up="isOpenFollowUp"
          :duration="{
            hours: readDurationHours,
            minutes: readDurationMinutes,
          }"
          :start-hours="startHours"
          :start-minutes="startMinutes"
          :start-time="startTime"
          :date-time="currentlyEditing.dateTime"
          @filter-selected="changeStatus"
          @stage-update="stageUpdate"
        />
      </div>
    </div>
  </CustomModal>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { useRoute, } from 'vue-router';
import { CustomModal } from '@sales-i/dsv3';
import Stage from '@/shared/components/Stage/Stage.vue';
import { baseUrl as crmBaseUrl, interactionsArea } from '@/crm/router/urlBits';
import { CUSTOMER_FOR_INTERACTION } from '@/shared/constants/storageItems';
import { useHead } from '@unhead/vue';
import { useStore } from 'vuex';
import { dates, storage, t } from '@sales-i/utils';
import { useInteraction } from '@/crm/composables/useInteraction';
import useSystem from '@/shared/composables/useSystem';
import { navigateToUrl } from 'single-spa';

let stagesNames = {
  Timescale: '',
  'Customer/contact': 'details',
};

const pageTitle = ref(null);
const status = ref('open');
const stage = ref(0);
const stageName = ref('');

const vroute = useRoute();
const store = useStore();

const {
  clearInteractionsById,
  currentlyEditing,
  durationHours,
  durationMinutes,
  getInteraction,
  startHours,
  startMinutes,
  setEntity,
} = useInteraction();

const { pushRoute } = useSystem({ store });

onMounted(() => {
  useHead({
    title: pageTitle.value.textContent,
  });
  initFunc();
});
  
const readDurationHours = computed(() => durationHours.value || '');
const readDurationMinutes = computed(() => {
  // If there is no interactionID, then we are adding a new interaction, so we should default it to 15 minutes. Only do this if the route exactly matches /crm/interactions/add/. Otherwise, clicking back to the first page will reset the user's choice to 15 minutes
  if (!vroute.params.id && vroute.path === `${crmBaseUrl}/${interactionsArea}/add`) {
    return durationMinutes.value || 15;
  }
  return durationMinutes.value || '';
});
const title = computed(() => {
  if (!isEditInteraction.value && !isBackdated.value)
    return isPersonal.value ? t('Add a new personal appointment') : t('Add a new interaction');
  if (isBackdated.value) return t('Log an interaction');
  if (isEditInteraction.value) return t('Edit interaction');
  if (isGenerate.value) return t('Generate prospect interactions');
  return '';
});
const stages = computed(() => ['Timescale', 'Customer/Prospect']);
const startTime = computed(() => {
  let hrs = startHours.value;
  let mins = startMinutes.value;
  if (hrs && mins) {
    return `${hrs}:${mins}`;
  }
  return '';
});

const isPersonal = computed(() => vroute.name === 'AddPersonalInteractionMaster');
const isBackdated = computed(() => currentlyEditing.value.schedule === 'backdated');
const isGenerate = computed(() => !!vroute.path.match(`${crmBaseUrl}/${interactionsArea}/generate-prospect-interaction`));
const isEditInteraction = computed(() => !!vroute.params.id);
const isOpenFollowUp = computed(() => vroute.query.isOpenFollowUp);
    
const initFunc = () => {
  const customerInStorage = storage.get(CUSTOMER_FOR_INTERACTION);
  if (customerInStorage) {
    customerInStorage.header = customerInStorage.name;
    customerInStorage.subHeader = customerInStorage.account_number;
    setEntity({
      entityType: 'customer',
      entity: customerInStorage,
    });
    storage.remove(CUSTOMER_FOR_INTERACTION);
  }

  let { path, params, query } = vroute;

  let clearOverride = false;
  if (Object.keys(query).length > 0 && query.clean === 'false') {
    clearOverride = true;
  }
  if (clearOverride === false && params.addedit === 'add') {
    clearInteractionsById();
  }
  const addPath = `${crmBaseUrl}/${interactionsArea}/add`;
  if (path !== addPath && !params.id && !currentlyEditing.value.dateTime) {
    navigateToUrl(addPath);
  }

  if (params.addedit === 'edit' && params.id) {
    getInteraction({ id: params.id });
  }
};
const getStatus = () => {
  if (currentlyEditing.value?.opportunities?.length > 0) {
    status.value = currentlyEditing.value.opportunities[0].type.toLowerCase();
  }
  return status.value;
};
const changeStatus = (newStatus) => {
  status.value = newStatus;
};
const changeStage = (newStage) => {
  let chngStg = stages.value[newStage];
  let newIndex = stages.value.findIndex(stg => stg === newStage);
  if (!chngStg || newIndex === stage.value) return;
  let {
    params: { id },
  } = vroute;

  if (id) {
    navigateToUrl(`${crmBaseUrl}/${interactionsArea}/${id}/edit/${stagesNames[chngStg]}`);
  } else if (vroute.params.addedit === 'add') {
    navigateToUrl(`${crmBaseUrl}/${interactionsArea}/add/${stagesNames[chngStg]}`);
  }
};
const stageUpdate = (newStage) => {
  stageName.value = newStage;
  let newIndex = stages.value.findIndex(stg => stg === newStage);
  stage.value = newIndex;
};
const closeModal = () => {
  pushRoute({
    defaultUrl: `${crmBaseUrl}/${interactionsArea}`,
    success: false,
  });
  clearInteractionsById();
};
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
.title {
  text-align: center;
}

.interaction-summary {
  font-size: var(--font-size-small);
}

.stages {
  margin-top: var(--spacing-3);
}

.form-wrapper {
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
  height: calc(100vh - var(--spacing-12) * 2.1);

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    height: calc(100vh - var(--spacing-12) * 2.4);
  }
}

:deep(.content-wrapper) {
  padding-bottom: 0;
}
:deep(.modal .container) {
  padding: 0;
}
</style>